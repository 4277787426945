import React from 'react'
import Index from '../../../Assets/images/Index'
import { Fade } from 'react-reveal'

export default function EndToEnd({ title, subHeading, list, blueBox, parentClass }) {
    return (
        <section className={`endto_end commonSection ${parentClass ? parentClass : ""}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 endto_endOutr">
                        <div className="endto_end_txtbox wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                            <Fade up>   <h3 className="common_heading" dangerouslySetInnerHTML={{ __html: title }}></h3> </Fade>
                            <div className="endto_end_txtboxFirst">
                                <Fade up> <p>{subHeading}</p></Fade>
                            </div>
                        </div>
                    </div>
                    {list.map((data, index) => {
                        return (
                            <Fade up key={index}>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 endto_endOutr">
                                    <div className={`endto_end_imgwith  border-radius-8 text-center  ${blueBox ? "migration_boxes" : "entto_boxshdow"}`}>
                                        {blueBox ?
                                            <div className="migration_crcleImg">
                                                <div className="blue-dashedbrdr"></div>
                                                <div className="migration_crcleImg--inner">
                                                    <img src={data.icon} alt={data.heading.split("<br>")} />
                                                </div>
                                                <div className="migration_crcleImg--inner">
                                                    <img src={Index.shopifyBag} alt="shopify" />
                                                </div>
                                            </div>
                                            :
                                            <div className="endto_end_imgbox">
                                                <img src={data.icon} alt={data.heading.split("<br>")}  />
                                            </div>}
                                        <h4 className="common_heading" dangerouslySetInnerHTML={{ __html: data.heading }}></h4>

                                    </div>
                                </div>
                            </Fade>
                        )
                    })}

                </div>
            </div>
        </section>

    )
}
