import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import Index from '../../../Assets/images/Index'
import StepBox from '../../Common/StepBox/StepBox'
import SixBox from '../../Common/SixBox/SixBox'
import { Link } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import BlueBox from '../../Common/BlueBox/BlueBox';
import Faq from '../../Common/Faq/Faq';
import Projects from '../../../Assets/images/portfolio/Projects';
import Projectlogos from '../../../Assets/images/portfolio/logo/Projectlogos';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

export default function Home({ blueBoxRef, executeScroll, seo }) {

    const featuredClient = React.useRef(null);
    const featuredWorkSldr = React.useRef(null);
    const clientNameSliderRef = React.useRef(null);

    const vidRef0 = useRef(null)
    const vidRef1 = useRef(null)
    const vidRef2 = useRef(null)
    const vidRef3 = useRef(null)

    const developmentApproach = [
        { title: "Ideation to Planning", desc: "Discuss your idea with our team of Shopify experts and, then we put all our efforts into making your dream project a successful one." },
        { title: "Design and Development", desc: "We offer you custom-made Shopify store design/development and integrate 3rd-party applications as per your business requirements." },
        { title: "Testing and Launch", desc: "Your project goes under rigorous testing to detect/ resolve the glitches. And now is the time to set up your store online." },
        { title: "Digital Marketing", desc: "Setting up the store does not finish the job. Marketing is the next and final step to drive sales and generate high ROI. " },
    ]
    const whyhire = [
        { color: "#D4E9F3", img: Index.designIcon, title: "Full Customization", desc: "Whether it's a Shopify website, custom theme development, or advanced integrations, we got you covered. We provide personalized solutions matching your expectations. " },
        { color: "#FDEDAC", img: Index.messageIcon, title: "Around the Clock Support ", desc: "We provide ongoing support, maintenance, and updates to ensure that your store runs smoothly and stays ahead of the competition." },
        { color: "#EAD3FF", img: Index.desktopIcon, title: "Transparent Pricing ", desc: "We don’t include any hidden charges in our pricing. The clients get every minute detail about the costing factors." },
        { color: "#CFF6A1", img: Index.userIcon, title: "High Expertise ", desc: "Our Shopify experts are certified professionals and have gone through robust training making them well-qualified to meet your expectations." },
        { color: "#FFCFDE", img: Index.badgeIcon, title: "Extensive Experience", desc: "We have been serving our clients with high-class Shopify development services for years. We know how to win the race. " },
        { color: "#e2e7ff", img: Index.tagIcon, title: "Reasonable Cost", desc: "Our top-rated Shopify development services are pocket friendly. We can offer you customization and features depending on your budget." },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "What does make us stand out from the other Shopify development companies?", desc: "<p>We listen to our client's needs and concerns patiently. Finally, deliver what they exactly expect from us. We build a transparent relationship with you to make the whole process smooth.</p>" },
                { title: "How long does it take to launch a Shopify store?", desc: "<p>The Shopify store development time depends on the project size and the functionalities you want to include. On average, if you want a store developed from scratch, it may take 2-3 weeks.</p>" },
                { title: "Can you modify an existing Shopify store?", desc: "<p>Yes, discuss your expectations with us and we deliver you an enhanced and optimized Shopify store for you to drive a high number of sales.</p> " },
                { title: "What does the Shopify store development cost? ", desc: "<p>The cost may rise with increasing store complexities, business requirements, and customization degrees. We offer Shopify enterprise solutions at competitive prices.</p>" },
            ]
        },
        {
            id: 2, list: [
                {
                    title: "What are your different Shopify store development services?", desc: "<p>We cover everything from development to marketing and maintenance of your store. </p><ul><li>Shopify custom development</li><li>Shopify store optimization</li><li>Shopify app development</li><li>Shopify store marketing</li><li>Shopify store migration</li><li>Shopify third-party integration</li></ul>"
                }
            ]
        },
    ]
    const [testimonial, setTestimonial] = useState(
        [
            { active: true, id: 1, playing: false, name: "James Wall", profilepic: Index.thumb1, designation: "GrabYourDeal", rating: "5", logo: Index.chicLogo, desc: "I am pretty impressed with their dedication to offering the best across the market. I praise their professionalism and friendly behavior with my team. They follow a systematic development approach which makes the whole process hassle-free and quick. Above all, I got the best results with their marketing solutions to drive the maximum traffic to my Shopify eCommerce store. " },
            { active: false, id: 2, playing: false, name: "Mary Vanwingerden", profilepic: Index.thumb2, designation: "Trendify ", rating: "5", logo: Index.chicLogo, desc: " I love their Shopify store development services. I got exactly what I asked from them. My eCommerce store is packed with all the advanced and required features. They were quick to respond to my queries and concerns. We achieve our milestones with each passing day in terms of generating high traffic and leads. All thanks to their Shopify experts." },
            { active: false, id: 3, playing: false, name: "Joe Williams", profilepic: Index.thumb5, designation: "FitmeShoes", rating: "5", logo: Index.chicLogo, desc: "I always dreamed about a fascinating e-commerce store for my footwear brand. I looked for various Shopify store development companies and finally, they got my attention with their robust portfolio. Their professionals answered my queries at the right time and delivered the product at the decided time. I recommend you hire their Shopify experts to get an unparallel experience." },
            { active: false, id: 4, playing: false, name: "Jeremy I. Wiest", profilepic: Index.thumb3, designation: "FancyDecor", rating: "5", logo: Index.chicLogo, desc: "My Shopify eCommerce website for home decor products designed by their experts is highly responsive and user-friendly. It's been months, and I didn't face any issues with the website performance. They listened to all my concerns and crafted an aesthetically pleasing Shopify website focusing on my business needs. You can rely on them for a seamless development process. The reasonable pricing for the services was like a cherry on the cake." },
        ]
    )
    const weOffferCntnt = [
        {
            name: "Shopify Store Development ",
            content: "Enrich your brand presence online with our personalized and exciting store designs and grab the attention of the audience just in seconds.",
            img: Index.storeImg,
            color: "#006cd9",
            subList: [
                { name: "Maximize your brand’s visibility", icon: "❤️" },
                { name: "Visually appealing designs", icon: "🖥️" },
                { name: "Hassle-free navigation", icon: "🐱" },
                { name: "Frictionless user experience ", icon: "🙄" },
                { name: "Quick page loading", icon: "⏰" },
            ]
        },
        {
            name: "Shopify <br> Store Customization",
            content: "Our Shopify development services include customization of the already-built eCommerce store at affordable prices.",
            img: Index.migrationImg,
            color: "#14a800",
            subList: [
                { name: "Meet your business needs", icon: "🤝" },
                { name: "Empower e-commerce business growth", icon: "📊" },
                { name: "End-to-end customized solutions", icon: "⚙️" },
                { name: "Highly responsive store designs", icon: "📏" },
                { name: "Advanced features and functionalities", icon: "🚀" },
            ]
        },
        {
            name: "Shopify <br> Store Migration",
            content: "Our best Shopify development company ensures a safe store migration experience to Shopify without affecting its performance.",
            img: Index.optmImg,
            color: "#bb627d",
            subList: [
                { name: "Without loss of mandatory files and data ", icon: "📁" },
                { name: "Enhanced performance and functionalities ", icon: "🔥" },
                { name: "No loss of customer and product data", icon: "🔒" },
                { name: "Safe Web store products migration ", icon: "🛡️" },
                { name: "Effortless Shopify store migration ", icon: "🏪" },
            ]
        },
        {
            name: "Shopify <br> App Development",
            content: "To offer an enticing user experience, avail of our Shopify app development services and rapidly get an excellent customer base.",
            img: Index.customImg,
            color: "#d86f11",
            subList: [
                { name: "Feature-rich Shopify application", icon: "👉" },
                { name: "Easy-to-use interface", icon: "✅" },
                { name: "Advanced functionalities", icon: "⚙️" },
                { name: "Smart inventory system", icon: "📦" },
                { name: "Quick response time", icon: "⏱️" },
            ]
        },
        {
            name: "Shopify Internet Marketing",
            content: "Hire our Shopify experts to kick-start your e-commerce business digitally. We can help your business to be easily visible in top searches.",
            img: Index.marketingImg,
            color: "#715fee",
            subList: [
                { name: "Run online marketing campaigns ", icon: "📣" },
                { name: "Advanced marketing tools ", icon: "🛠️" },
                { name: "Optimized eCommerce store ", icon: "🛍️" },
                { name: "High rate of conversions ", icon: "🎯" },
                { name: "Easy global audience reach ", icon: "👬" },
            ]
        },
    ]
    const [activeData, setactiveData] = useState(testimonial[0])
    const testimonialTab = (data) => {
        let updatedtab = []
        // activeData.refrence.current.pause();
        testimonial.map((opt) => {
            if (data?.id == opt?.id) {
                updatedtab.push({ ...opt, 'active': true, 'playing': false })
                setactiveData({ ...opt, 'active': true, 'playing': false })
            } else {
                updatedtab.push({ ...opt, 'active': false, 'playing': true })
            }
            setTestimonial(updatedtab)
        })
    }
    const featuredWork = {
        arrows: false,
        // dots: true,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 20000,
        infinite: true,
        // cssEase: 'linear',
        // centerMode: false,
        speed: 9000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: false,
        // variableWidth: true,
        // prevArrow: $(".featureHeadingHolder__arows>.slickPreviousBtn"),
        // nextArrow: $(".featureHeadingHolder__arows>.slickNextBtn"),
        dots: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            }

        ]
    }
    const clientSlider = {
        arrows: false,
        dots: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        centerMode: true,
        pauseOnHover: false,
        focusOnSelect: false,
        // prevArrow: $(".featuredClients__arows>.slickPreviousBtn"),
        // nextArrow: $(".featuredClients__arows>.slickNextBtn"),
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }
    // let thumbnailSlider = {
    //     arrows: false,
    //     dots: false,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     centerMode: true,
    //     centerPadding: "0px",
    //     focusOnSelect: true,
    //     responsive: [
    //         {
    //             breakpoint: 767,
    //         },
    //         {
    //             breakpoint: 10000,
    //             settings: 'unslick'
    //         }
    //     ]
    // }
    let clientNameSlider = {
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        767: 1,
        500: 1
    };
    useEffect(() => {
        // activeData.refrence.current.load();
    }, [activeData.id])
    const playVideo = (dta) => {

        setactiveData({ ...activeData, playing: true })
        dta.refrence.current.play();
    };
    const pauseVideo = (dta) => {
        dta.refrence.current.pause();
        setactiveData({ ...activeData, playing: false })
    };
    useEffect(() => {
    }, [testimonial])


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/" />
                <title>{seo.title} </title>
                <meta name="keywords" content={seo.keywords} />
                <meta name="description" content={seo.description} />
            </Helmet>
            <section className="banner_main homeBanner banner_spacing">
                <div className="container">
                    <div className="banner_content_wrap row d-flex justify-content-between align-items-center">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <div className="banner_content__boxLeft wow fadeInLeft" data-wow-delay="1s" data-wow-duration="1000ms">
                                <div className="banner_content">
                                    <h1 className="banner_title">  <span className="highlight_text">Shopify </span> Development <span className="home_icon">🏪</span> Services Company
                                    </h1>
                                    <h5 className="banner_sub-title">At LiquidWeb Developers, We offer a wide range of custom Shopify website development services at affordable prices. </h5>
                                    <div className="customer_count d-flex align-items-center">
                                        <span className="h5">🥇 Tried and Trusted by Our Global Clients! </span>
                                        <img src={Index.curveArrow1} className="curve_arrow curve_arrow_float" alt="curve arrow" />
                                    </div>
                                    <button onClick={executeScroll} className="starting_price h5 regular">Get Started at Reasonable Prices</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-5 mobileHide">

                            <div className="banner_content__boxRight position-relative wow fadeInRight">
                                <div className="banner_mockup">
                                    <div className="banner_image-box circle">
                                        <img src={Index.bannerImg1} alt="shopify developer" />
                                    </div>
                                    <div className="banner_image-box radius">
                                        <img src={Index.bannerImg2} alt="shopify development services" />
                                    </div>
                                    <div className="banner_image-box radius">
                                        <img src={Index.bannerImg3} alt="shopify developers" />
                                    </div>
                                    <div className="banner_image-box radius_right">
                                        <img src={Index.bannerImg4} alt="best shopify development services" />
                                    </div>
                                </div>
                                <div className="buble_box circle lightYellow_circle"></div>
                                <div className="buble_box circle yellow_circle"></div>
                                <div className="buble_box circle green_circle"></div>
                                <div className="buble_box circle red_circle"></div>
                                <div className="buble_box circle blue_circle"></div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            {/* Banner End */}
            <section className="enterprise_main commonSection">
                <div className="container">
                    <div className="enterprise_heading text-center">
                        <Fade up>
                            <h3 className="common_heading white_clr"><span className="green_clr">Trusted </span>
                                <br /> Shopify Development Agency
                            </h3>
                        </Fade>
                        <Fade up>
                            <p>We are a reliable Shopify development company in Silicon Valley, USA. The valuable assistance of Shopify experts at LiquidWeb Developers has helped various businesses to achieve their goals. The numbers speak louder about our professional expertise!

                            </p>
                        </Fade>
                    </div>
                    <Fade up>
                        <div className="counter_wrap d-flex justify-content-between align-items-center">
                            <div className="shopify_logo plus tablet_none">
                                <img src={Index.shopifyPlusIcon} alt="Shopify Development Agency" />
                            </div>
                            <div className="counter_inner d-flex justify-content-between align-items-center">
                                <div className="counter_box">
                                    <div className="counter">5K+</div>
                                    <p>Project Delivered</p>
                                </div>
                                <div className="counter_box">
                                    <div className="counter">47</div>
                                    <p>Team Members</p>
                                </div>
                                <div className="counter_box">
                                    <div className="counter">345</div>
                                    <p>Store Migration</p>
                                </div>
                            </div>
                            <div className="shopify_logo certified tablet_none">
                                <img src={Index.shopifyPlus} alt="Shopify Plus" />
                            </div>
                        </div>
                    </Fade>
                    <Fade up>
                        <div className="enterprise_wide-img wow fadeInUp" >
                            <img src={Index.enterpriseImg} alt="shopify development company" />
                        </div>

                        <div className="enterprise_tablet desktop_none">
                            <div className="shopify_logo plus">
                                <img src={Index.shopifyPlusIcon} alt="shopify plus" />
                            </div>
                            <div className="shopify_logo certified">
                                <img src={Index.shopifyPlus} alt="shopify plus" />
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            {/* Enterprise End */}
            <section className="store_main common_space commonSection">
                <div className="container">
                    <div className="card_grid mesonary_grid d-flex justify-content-between">
                        {/* <div className="grid-sizer"></div>
                        <div className="gutter-sizer"></div> */}
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            <div className="card_grid-item wow fadeInUp" >
                                <div className="common_content">
                                    <Fade up>
                                        <h3 className="common_heading">Shopify Development &nbsp;
                                            <span className="borderBtm" style={{ backgroundImage: ` url(${Index.homeOffer})` }}>Services</span>&nbsp;Company in USA
                                        </h3>
                                    </Fade>
                                    <Fade up>
                                        <p>Are you interested in building a cutting-edge Shopify eCommerce store to expand your business digitally? Then, we are the right partners for you. You can rely on our leading Shopify website design company, Silicon Valley, USA, from ideation to the final launch.</p>
                                        <p>We at LiquidWeb Developers commit to delivering you timely services while maintaining the work quality as promised. Collaborate with our sharp-minded Shopify developers for the best Shopify website development . We deliver to you what makes you stand out in the market.</p>
                                        <p>Don’t delay further to reach our experts. Hire our Shopify developers in USA now to get started!</p>
                                    </Fade>
                                </div>
                            </div>
                            {weOffferCntnt.map((data, indx) => {
                                let count = indx + 1
                                return (
                                    <Fade up key={indx}>
                                        <div className="card_grid-item wow fadeInUp"  >
                                            <div className="card_box-innner white_bg outer_glow">
                                                <div className="card_type pb-3">
                                                    <div className="card_type-content">
                                                        <h6 className="number">{count > 9 ? count : "0" + count}</h6>
                                                        <h3 className="title " style={{ color: data?.color }}><span dangerouslySetInnerHTML={{ __html: data.name }}></span>    </h3>
                                                    </div>
                                                    <div className="card_type-image">
                                                        <img src={data.img} alt={`${data.name.split('<br>')} icon`} />
                                                    </div>
                                                </div>
                                                <p className="font18">{data.content}</p>
                                                <div className="card_listing">
                                                    <ul>
                                                        {data.subList.map((subdata, ind) => {
                                                            return (
                                                                <li key={ind}>
                                                                    <span className="cardlisting__img">
                                                                        {subdata.icon}
                                                                    </span>
                                                                    <span className="cardlisting__cntnt"> {subdata.name}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })}
                            <Fade up>
                                <div className="card_grid-item text-center wow fadeInUp" >
                                    <Link to="/services" className="common_btn">View All Services</Link>
                                    <button onClick={executeScroll} className="common_btn">Get Your Quote</button>

                                </div>
                            </Fade>
                        </Masonry>
                    </div>
                </div>
            </section>
            <section className="new_store_main commonSection pt-0">
                <div className="container">
                    <div className="new_store row">
                        <div className="col-md-6">
                            <div className="new_store_content common_content wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                <Fade up>
                                    <h3 className="common_heading">
                                        Leverage the <span className="borderBtm" style={{ backgroundImage: ` url(${Index.homePotential})` }} >Potential</span> of Our Shopify App Development Services</h3>
                                </Fade>
                                <Fade up>
                                    <div className="newStoreContent__text">
                                        <p>Want to achieve truckload targeted traffic and shoot up your sales? Don’t worry! We are here to help you. Launch your own eCommerce Shopify store loaded with must-have features to explore the golden business opportunities in the digital landscape.</p>
                                        <p>We are acknowledged as one of the reputed and experienced Shopify development companies, USA. So, you can rest assured when our dedicated Shopify plus developers work for you.</p>
                                    </div>
                                </Fade>
                                <Fade up>
                                    <button onClick={executeScroll} className="common_btn">Hire Shopify Experts</button>
                                </Fade>
                            </div>
                        </div>
                        <div className="col-md-6 mobileHide">
                            <img src={Index.curveArrow1} className="curve_arrow curve_arrow_float" alt="curve arrow" />
                            <Fade up>
                                <div className="new_store_image wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1300ms">
                                    <img src={Index.laptopMen} alt="hire shopify developers" />
                                </div>
                            </Fade>
                            <div className="buble_box circle lightYellow_circle lightYellow_circle_wild"></div>
                            <div className="buble_box circle blue_circle blue_circle_wild"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="process_main commonSection process_mainHome ">
                <div className="container">
                    <Fade up>
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading">Our Shopify <br /> Development <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeApproach})` }}>Approach</span></h2>
                        </div>
                    </Fade>
                    <StepBox StepBoxCntnt={developmentApproach} />
                </div>
            </section>
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"
                            >Why Should You Hire Us as <br /> Your <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}>Shopify
                                Development </span> Company?
                            </h2>
                            <p>Results Beyond Your Expectations</p>
                        </div>
                        <SixBox listdata={whyhire} icon />
                    </div>
                </div>
            </section >
            <section className="featured_work_main commonSection pt-0">
                <div className="container">
                    <Fade up>
                        <div className="title_wrapper featureHeadingHolder">
                            <h3 className="common_heading">Featured Works</h3>
                            {/* <div className="featureHeadingHolder__arows">
                                <button onClick={() => featuredWorkSldr?.current?.slickPrev()} className="slickPreviousBtn slick-prev " aria-label="Previous" type="button">Previous</button>
                                <button onClick={() => featuredWorkSldr?.current?.slickNext()} className="slickNextBtn slick-next " aria-label="Next" type="button">Next</button>
                            </div> */}
                        </div>
                    </Fade>
                </div>
                <Fade up>
                    <Slider ref={featuredWorkSldr} {...featuredWork} className="featured_work_slider">
                        {Projects.map((data, indx) => {
                            return (
                                <div key={indx} className="slider_item">
                                    <Link to={`portfolio/${data.slug}`} className="d-block">
                                        <img src={data.thumbnail} alt={data.name} />
                                    </Link>
                                </div>
                            )
                        })}


                    </Slider>
                </Fade>
            </section>
            {/* <section className="testimonials_main commonSection  pt-0 ">
                <div className="container">
                    <div className="title_wrapper text-center">

                        <h2 className="common_heading" > 
                            <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeOffer})` }}>Words</span> from Our Clients:</h2>
                        <p>We strive to offer customer satisfaction and value their feedback. 👇👇👇</p>
                    </div>
                </div>
                </section> */}
            <section className="testimonials_main commonSection  pt-0 ">
                <div className="container">
                    <div className="title_wrapper text-center">

                        <h2 className="common_heading" >
                            {/* <img src={Index.testimonialLogo} alt=""  width="80"/> */}
                            <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeOffer})` }}>Words</span> from Our Clients:</h2>
                        <p>We strive to offer customer satisfaction and value their feedback. 👇👇👇</p>
                    </div>
                    {/*  <div className="row">
                        <div className="col-md-12 col-lg-3">
                            <Fade up>
                                <div className="testimonials_content wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <div className="testimonials_contentImg mb-4">
                                        <img src={Index.testimonialLogo} alt="" />
                                    </div>
                                    <h3 className="common_heading"> <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeOffer})` }}>Words</span><br /> from Our Clients: <br />Let’s Watch </h3>
                                    <p>We strive to offer customer satisfaction and value their feedback. 👇👇👇
                                    </p>
                                </div>
                            </Fade>
                        </div> 
                        <div className="col-md-12 col-lg-9">

                              <div className="testimonials_wrapper d-flex justify-content-between wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                 <div className="slider_wrapper">
                                    <div className={`testimonials_item current`}>
                                        <div className="video_content">
                                            <div className="video_wrapper">
                                                <video ref={activeData.refrence} poster={activeData.videoposter} loop className="bannervideoslide" muted={activeData?.playing} playsInline >
                                                    <source src={activeData.video} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div className="video_controls">
                                                {activeData.playing ?
                                                    <button onClick={() => pauseVideo(activeData)} className="videoBtn pauseBtn" style={{ backgroundImage: `url(${Index.pauseBtn})` }}></button> :
                                                    <button onClick={() => playVideo(activeData)} className="videoBtn playBtn" style={{ backgroundImage: `url(${Index.playBtn})` }}></button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                                 <Slider {...thumbnailSlider} className="thumbnail_slider">
                                    {testimonial.map((data, indx) => {
                                        return (
                                            <div onClick={() => testimonialTab(data)} key={indx} className={`thumbnail_item d-flex align-items-center ${data.active === true ? "current" : null}`} data-tab="vid-1">
                                                <div className="client_img">
                                                    <img src={data.profilepic} alt="" />
                                                </div>
                                                <div className="client_name">
                                                    <h4>{data.name}</h4> 
                                                </div>
                                            </div>
                                        )
                                    })}

                                </Slider> 

                            </div> 

                        </div>
                    </div>*/}
                    <div className="testimonialSlider">
                        <Fade up>
                            <Slider ref={clientNameSliderRef} {...clientNameSlider} className="clientNameSlider">
                                {testimonial.map((data, indx) => {
                                    return (
                                        <div key={indx}>
                                            {/* <div className={`testimonialSlider__box 
                                    ${data.active === true ? "current" : null}
                                    `}> */}
                                            <div className="testimonialSlider__box current">

                                                <div className="testimonialSlider__cntnt">
                                                    <p className="h5 medium">{data.desc}</p>
                                                </div>
                                                <ul className="starRating d-flex justify-content-center align-items-center">
                                                    {/* {data.rating/ */}
                                                    <li><img src={Index.star} alt="*" /></li>
                                                    <li><img src={Index.star} alt="*" /></li>
                                                    <li><img src={Index.star} alt="*" /></li>
                                                    <li><img src={Index.star} alt="*" /></li>
                                                    <li><img src={Index.star} alt="*" /></li>
                                                </ul>
                                                <div className="client_name">
                                                    <h4>{data.name}</h4>

                                                </div>

                                                {/* <div className="text-center">
                                            <img src={data.logo} className="mx-auto" alt="" width="93" />
                                        </div> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </Fade>
                        <Fade up>
                            <div className="featuredClients__arows col-auto">
                                <button onClick={() => clientNameSliderRef?.current?.slickPrev()} className="slickPreviousBtn slick-prev " aria-label="Previous" type="button">Previous</button>
                                <button onClick={() => clientNameSliderRef?.current?.slickNext()} className="slickNextBtn slick-next " aria-label="Next" type="button">Next</button>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <Faq faqData={faqData} />
            <section className="featured_clients">
                <div className="container">
                    <div className="featuredClientHeading title_wrapper d-inline-flex">
                        <h3 className="common_heading col-auto ps-0">Featured Clients</h3>
                        <div className="featuredClients__arows col-auto">
                            <button onClick={() => featuredClient?.current?.slickPrev()} className="slickPreviousBtn slick-prev " aria-label="Previous" type="button">Previous</button>
                            <button onClick={() => featuredClient?.current?.slickNext()} className="slickNextBtn slick-next " aria-label="Next" type="button">Next</button>
                        </div>
                    </div>
                </div>
                <Slider ref={featuredClient} {...clientSlider} className="clients_slider">
                    {Projectlogos.map((data, indx) => {
                        return (
                            <div key={indx} className="clients_slider-item">
                                <img src={data.logo} alt={data.name} style={{ height: data.height + 'px' }} />
                            </div>
                        )
                    })}
                </Slider>
            </section>
            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br /> Shopify store" />
        </>
    )
}
