import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import ThreePortfolioBox from '../../../Common/ThreePortfolioBox/ThreePortfolioBox'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import EndToEnd from '../../../Common/EndToEnd/EndToEnd'
import { Fade } from 'react-reveal'
import { Helmet } from "react-helmet";
export default function Store({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Analyzing", content: "We examine your requirements before designing feasible store themes for you. We add/remove certain sections in the theme designs to make them the best fit for your expectations. " },
        { title: "Designing", content: "After analyzing, we put our best efforts into creating innovative and appealing store designs through Shopify's custom development to make you look unique to the customers." },
        { title: "Integrating", content: "To offer an enhanced user experience, we integrate third-party APIs as per your needs to comfort your customers. For instance, multiple payment options. " },
        { title: "Implementation", content: "Our Shopify development company finally implements the selected design including pictures, descriptions, etc, and is always up for enhancements directed by you. " },
        { title: "Testing", content: "It is essential to conduct an iterative testing phase to eliminate the technical issues if any occur in the designed product before its launch. " },
        { title: "Launching", content: "After the deployment of the error-free project in the market, the Shopify store development process is completed by our expert Shopify developers. " },
    ]
    const topFeature = [
        { img: Index.feture1, title: "Advanced Filters & Search Options", content: "Your customers can set the sorting & filter options to easily find the products they are searching for. They can make purchasing decisions without taking much time. " },
        { img: Index.feture2, title: "Push Notifications", content: "Your customers will get notifications like shipping updates, reminders, new offers & deals on their smart devices without making extra efforts to know what is new. " },
        { img: Index.feture3, title: "Multiple Payment Gateways ", content: "To incline customer retention, we can offer you demanding features like multiple payment options for seamless payment processing. " },
        { img: Index.feture4, title: "Easy to Navigate User Interface ", content: "The users can smoothly move from one screen to the other without any flaws. Our top-class Shopify stores offer simplified navigation to your users. " },
        { img: Index.feture5, title: "Order Tracking Feature", content: "Our Shopify developers enable your customers to get the order tracking facility to check when they can receive the ordered product at their doorstep." },
        { img: Index.feture6, title: "Reviews & Rating ", content: "After the deployment of the error-free project in the market, the Shopify store development process is completed by our expert Shopify developers." },
    ]
    const whyhire = [
        { color: "#D4E9F3", title: "Best Prices", desc: "Our Shopify developers strive to provide world-class Shopify development services at competitive prices without stretching your budget. " },
        { color: "#FDEDAC", title: "Team of Experts", desc: "We have hand-picked top Shopify developers associated with us to offer you what your business needs the most to satisfy the customers. " },
        { color: "#EAD3FF", title: "Proven Project Methodology", desc: "Our Shopify development company has the best and the most advanced plug-ins to offer you an exceptional experience in the digital landscape. " },
        { color: "#CFF6A1", title: "Agile Development Approach ", desc: "We follow a systematic project development approach by dividing the whole project into sections to timely achieve the set milestones. " },
        { color: "#FFCFDE", title: "On-time <br> Delivery", desc: "We aim to deliver on-time Shopify development services to our clients. We help them to enter the market in a short time with complete preparation. " },
        { color: "#e2e7ff", title: "Best Marketing Solutions", desc: "Not only Shopify store development, but we also enable you to drive high traffic and increase the conversion rate to stand at the top using advanced marketing strategies. " },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "Why Shopify is the best choice for eCommerce business? ", desc: "<p>Shopify is a plugin-style platform to create future-ready eCommerce stores packed with the latest features and integrated with third-party applications as per expectations. </p>" },
                { title: "Why choose Shopify custom theme development? ", desc: "<p>If you are looking for an online store tailored to your needs, then Shopify custom development is the right choice for you. Limitless customization is possible with Shopify custom development. </p>" },
                { title: "How can you hire our Shopify experts?  ", desc: "<p>We have provided a form below, you need to fill in the details and submit it. Our team will get back to you quickly. And we are ready to begin the development process! </p> " },
            ]
        },
        {
            id: 2, list: [
                {
                    title: "What are the roles and responsibilities of Shopify developers? ", desc: "<ul><li>Designing Shopify themes.</li><li>Integrating third-party applications.</li><li>Customizing the already built store.</li><li>Taking care of the regular updates.</li><li>Migrating the store from other platforms to Shopify.</li></ul>"
                }
            ]
        },
    ]
    const launchApp = [
        {
            title: `Opt For <span class="borderBtm" style="background-image: url(${Index.optShopify})">Shopify</span> Custom <br>Store Development Services `,
            paragraph: "Shopify custom store development is one of the most prominent trends in the market to generate better ROI in a short time. Our Shopify development services have the potential to build fully functional Shopify stores at the lowest possible cost. ",
            list: [
                { content: "Personalized Store Designs" },
                { content: "Experience Unique Brand Identity " },
                { content: "Automated Business Operations  " },
                { content: "Advanced SEO & Marketing " },
                { content: "Exceptional Business Growth " },
                { content: "Safe & Secure Payment Options " },
            ]
        },
    ]
    const appdevServce = [
        {
            heading: "Theme <br>Selection &amp; Installation",
            icon: Index.one,
        },
        {
            heading: "Basic DNS <br> Setup ",
            icon: Index.end2,
        },
        {
            heading: "Product Information Setup",
            icon: Index.end3,
        },
        {
            heading: "Sales Channel Integration",
            icon: Index.four,
        },
        {
            heading: "Payment Gateway Setup",
            icon: Index.end5,
        },
        {
            heading: "SSL <br> Certificate Installation",
            icon: Index.end6,
        },
        {
            heading: "Third-Party Plugin Integration",
            icon: Index.seven,
        },
        {
            heading: "Customized Shopify Checkout",
            icon: Index.devCartIcn,
        },
        {
            heading: "Google Analytics Setup",
            icon: Index.end9,
        },
        {
            heading: "Shopify <br> Blog Setup",
            icon: Index.end10,
        },
        {
            heading: "Shipping <br> & Delivery Settings",
            icon: Index.end11,
        },
    ]
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-store-development" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>

            <InnerPageBanner
                bannerClass="very-light-pink-bg servicestore_banner"
                heading={`Trusted <span class="highlight_text">Partner </span> for Shopify Store Designs <span class="home_icon"> 🏪 </span>`}
                subtitle="Our eye-catching store designs deliver excellent brand visibility over the internet. Recognized as one of the Top Shopify Development Companies in the USA, we offer you the best in the digital world."
                bannerImg={Index.servicesBnrImg}
                arrow
                leftCol="col-md-7 col-lg-7"
                rightCol="col-md-5 col-lg-5"
            />
            <GetInTouch bgColor="var(--green)" />
            <TwoColList
                largeImg={Index.serviceStrore1}
                smallImg={Index.devCartIcn}
                alt="shopify store development"
                // commonClass="app-development-listing"
                heading={`<span class="borderBtm" style="background-image: url(${Index.homePotential})"> Shopify </span> Store <br>Development Services: <br> Bring Your Business Online`}            >
                <div className="newStoreContent__text">
                    <p>Shopify Store Development Services: Bring Your Business Online
                        Are you looking to start your online Shopify store? What is stopping you? Our top-seeded Shopify experts deliver globally trusted Shopify development services to transform your business. Solve all your merchant problems with our industry-specific custom Shopify theme development solutions.</p>
                </div>
                <div className="card_listing">
                    <ul>
                        <li> <span className="cardlisting__img">
                            🖼️
                        </span>
                            <span className="cardlisting__cntnt">Loaded with high-quality images </span>
                        </li>
                        <li> <span className="cardlisting__img">
                            🙆
                        </span>
                            <span className="cardlisting__cntnt">Optimized product titles and descriptions </span>
                        </li>
                        <li> <span className="cardlisting__img">
                            ⌛

                        </span>
                            <span className="cardlisting__cntnt">Extraordinary page loading time </span>
                        </li>
                        <li> <span className="cardlisting__img">
                            🍭
                        </span>
                            <span className="cardlisting__cntnt">Exceptional colour  schemes suitable for
                                your brand
                            </span>
                        </li>
                        <li> <span className="cardlisting__img">
                            🛍️
                        </span>
                            <span className="cardlisting__cntnt">Well-structured menu for different product
                                categories
                            </span>
                        </li>
                    </ul>
                </div>
            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Our Step by Step <br> <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Shopify Store </span> Designing Process </h2>`}

                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                layoutType
                list={perksData}
            />


            <OptStore
                launchApp={launchApp}
                alt="shopify custom store development services"
                leftImg={Index.serviceStrore2}
                btn
                btnText="Get A Quote"
                sectionClass="blackpearl_bg"
                executeScroll={executeScroll}

            />
            <EndToEnd
                title={`End-to-End Store <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Development </span> steps `}
                subHeading="Unveil the emerging opportunities in the advanced digital era with our reliable Shopify Store development services."
                list={appdevServce}
            />
            <ThreePortfolioBox heading={`We're One of The Few <br/> Official Shopify Theme <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Developers</span>`} />

            <CountContentBox
                heading={`<h2 class="common_heading">Top Features of Our <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Shopify Store </span><br/> Development Services </h2>`}
                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                icon
                layoutType
                list={topFeature}
                bottomBtn
                executeScroll={executeScroll}
            />


            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <Fade up>     <h2 className="common_heading"> Why Should <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> You Count </span> on Us For Shopify
                                eCommerce Development services? </h2></Fade>
                            <Fade up><p>Prominently Leading Website</p></Fade>
                        </div>
                        <SixBox listdata={whyhire} />

                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Earned the <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Trust </span > of Top Brands `} />
            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br> Shopify store" subtitle="Consult with Our Experts" />
        </>
    )
}
