import React from 'react'
import BlueBox from '../../Common/BlueBox/BlueBox'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy({ blueBoxRef }) {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/privacy-policy" />
            </Helmet>
            <section className="greenBg commonSection portFolio__bnr position-relative">
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="extrabold white_clr text-center">Privacy Policy </h1>
                        </div>
                    </div>

                    <div className="buble_box circle white_buble small_buble "></div>
                </div>
            </section>
            <section className="commonSection">
                <div className="container">
                    <div className="inner_container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-addon__content">
                                    <p>This privacy statement outlines <strong>Our</strong> practices for gathering, using, and disclosing <strong> Your</strong> information. By using the service, you accept that the information will be gathered and used in
                                        accordance with this privacy policy.</p>
                                    <p>We value your privacy and are committed to respecting and protecting it. If you have any questions or concerns about this privacy statement or your personal information, kindly get in touch with us at <a href="mailto:legal@liquidwebdevelopers.com">legal@liquidwebdevelopers.com</a></p>
                                    <h4>The following are related to this policy:</h4>
                                    <ul>
                                        <li>Our website users and visitors.</li>
                                        <li>Candidates of our career page.</li>
                                        <li>Our business partners, vendors, and associates.</li>
                                        <li>Third parties associated with us.</li>
                                        <li>People who are interested in availing of our services and business relationships.</li>
                                    </ul>
                                    <h4>Data We Collect and Use</h4>
                                    <h4>Types of Data We Collect</h4>
                                    <h4>Personal Information</h4>
                                    <p>We may ask for personal information to identify you when you use or visit our website. This data will help us get in touch with you.</p>
                                    <ul>
                                        <li>Email address</li>
                                        <li>First name and last name</li>
                                        <li>Phone number</li>
                                        <li>Address, State, Province, ZIP/Postal code, City</li>
                                    </ul>
                                    <h4>Usage Data</h4>
                                    <p>As soon as a user uses the service, usage data is automatically collected. It includes:</p>
                                    <ul>
                                        <li>IP address</li>
                                        <li>Browser version</li>
                                        <li>Browser type</li>
                                        <li>Pages visited</li>
                                        <li>Time/date of visit</li>
                                        <li>Unique device identifiers</li>
                                    </ul>
                                    <p>We might also collect data from your browser when you access our service through a mobile device or visit it on a computer.</p>
                                    <h4>Use of the Data We Collect</h4>
                                    <p>In order to offer you our services, we use the data we have obtained from you. Additionally, we might employ the data for a single or combination of the following goals:</p>
                                    <ul>
                                        <li>To swiftly deliver to you the information you requested about our products or services from us. </li>
                                        <li>To inform you of any updates to our website, services, products, or other offerings.</li>
                                        <li>To respond to any inquiries or demands you might have and to use it for the objectives for which it was given to us.</li>
                                        <li>To assist you, we will provide information about other products that might be of interest to you.</li>
                                        <li>Confirming and authenticating individual identities.</li>
                                        <li>All other objectives are connected to our business.</li>
                                    </ul>
                                    <h4>Sharing of Personal Data</h4>
                                    <ul>
                                        <li>Your data, including Personal Information, is processed at the Company's operating offices and any other locations where the parties involved in the processing are located.</li>
                                        <li>It implies that this data may be moved to and kept on systems situated outside of <strong>Your</strong> state, province, country, or another governmental jurisdiction where data protection laws may be different from
                                            <strong>Your</strong> jurisdiction.</li>
                                        <li>Your agreement to that transfer is indicated by your acceptance of this <strong>Privacy Policy</strong> and your submission of the requested information. </li>
                                        <li>The company will make every effort to protect Your data and treat it securely and in accordance with this <strong>Privacy Policy</strong> and will do so by taking any reasonable measures that are required.</li>
                                        <li>There will be no transfer of <strong>Your</strong> Personal Data to a company or a nation unless sufficient safeguards are in place to ensure the security of <strong>Your</strong> data and other personal information.</li>
                                    </ul>
                                    <h4>Your Rights</h4>
                                    <ul>
                                        <li>Submit a request to access your personal data and information about the processing activities carried out by us.</li>
                                        <li>Rectifying your personal information if it is incorrect or incomplete.</li>
                                        <li>If your Personal Information is incorrect or incomplete, you have the right to request rectifying or completing it.</li>
                                        <li>You have the right to request that we delete or remove Your private information.</li>
                                        <li>You could use your rights by sending emails to our given email address:<a href="mailto:legal@liquidwebdevelopers.com">legal@liquidwebdevelopers.com</a>.</li>
                                    </ul>
                                    <h4>Changes to the Current Privacy Policy</h4>
                                    <ul>
                                        <li>We reserve the right to change Our Privacy Policy at any time. Any changes will be communicated to You by posting the new Privacy Policy on this same page.</li>
                                        <li>It is recommended that you review this Privacy Policy on a regular basis for any changes. When changes to this <strong>Privacy Policy</strong> are published on this page, they become efficacious from that moment.</li>
                                    </ul>
                                    <h4>Contact Us</h4>
                                    <p>If you have any questions about how we process your personal data, please contact us at the following email address: <a href="mailto:legal@liquidwebdevelopers.com">legal@liquidwebdevelopers.com</a></p>
                                    <p>Or</p>
                                    <p>Visit our <Link to="/contact">contact</Link> page.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br/> Shopify store" />
        </>
    )
}
