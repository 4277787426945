export default {
    home: {
        title: "Reach Expert Shopify Developers | Best Shopify Website Design Company",
        keywords: "shopify website development, shopify website design company, shopify development services, shopify developers",
        description: "Launch a feature-rich custom Shopify website. Get the best Shopify development services from expert Shopify developers.",
    },

    about: {
        title: "Consult Best Shopify Experts To Build Shopify Website",
        keywords: "build website with shopify, shopify experts",
        description: "Trusted Shopify experts providing tailored solutions for your e-commerce needs. Elevate your online business with our proven strategies.",
    },
    services: {
        title: "Best Shopify Development Agency with Expert Web Builders",
        keywords: "shopify development agency, shopify web builders",
        description: "Hire our Shopify Web Builders for Personalized business e-commerce Solutions at affordable rates.Connect with us now to grab the best shopify development agency.",

    },
    servicesStore: {
        title: "  Get Expert Shopify Developerd for Top Shopify Store App",
        keywords: "shopify store development,expert shopify developers, shopify store app, shopify stores",
        description: "Maximize Your Sales With Advanced Shopify store  app development under the assistance of our expert Shopify developers.Explore the digital ecommerce landscape with us.",
    },
    servicesCustomization: {
        title: "Shopify Theme Customization with Skilled Shopify Developers",
        keywords: "shopify theme customization, shopify customer service, customize shopify website",
        description: "Customize Shopify website with our top-notch Shopify theme customization services. Connect with us to outperform your competitors.",
    },
    servicesMigration: {
        title: "Zero-downtime Shopify migration services for your digital success",
        keywords: "shopify migration services, partner with shopify, ecommerce stores",
        description: "Keep running your ecommerce stores seamlessly after Shopify migration services with  our Shppify experts.Partner with our Shopify developers to save your time.",
    },
    servicesAppDevelopment: {
        title: "Hire Our Expert Shopify App Development Services Company! Reach us now.",
        keywords: "shopify app development,app development",
        description: "Liquidweb Developers is a one stop destination for Shopify app development. Custom app development offers fully functional Shopify apps . Elevate your ecommerce game now.",
    },
    servicesOptimization: {
        title: "Shopify Speed Optimization Services  for better digital user-experince",
        keywords: "shopify speed optimization, shopify speed optimization service, optimize shopify speed",
        description: "Redfine your digital success with our Shopify speed optimization services.We offer you complete suite of Shopify store optimization services in reasonbale prices.",
    },
    servicesInternetMarketing: {
        title: "Dedicated Shopify Marketing Agency | LiquidWeb Developers",
        keywords: "shopify marketing, marketing with shopify, shopify marketing agency ",
        description: "Get Shopify marketing services to boost your digital presence.Better visibility means more conversions.Deep audits to effective strategies ,we got you covered. ",
    },
    servicesIntegration: {
        title: "Certified Shopify API Integration Services Company",
        keywords: "shopify api integration,api integration shopify",
        description: "Enhance your online store with seamless Shopify API integration for efficient operations and improved customer experience. Reach us soon.",
    },
    servicesMaintenance: {
        title: " Top Shopify Website Maintenance Services ! LiquidWeb Developers",
        keywords: "shopify website maintenance, shopify maintenance services, ecommerce web development",
        description: " Our Shopify maintenance services offers you uniteruppted store operations. Our ecommerce web development is not complete without the  Shopify maintenance .So,Look no Further!",
    },

    portfolio: {
        title: "Portfolio: Showcase of Our Shopify Success Stories",
        keywords: "",
        description: "Discover proven success with our portfolio of Shopify triumphs! Click to explore inspiring stories of growth and success by our expert team.",
    },
    contact: {
        title: "Shopify Maintenance | Shopify Website Designers",
        keywords: "",
        description: "Whether you need assistance with ongoing support and updates or want to create a stunning Shopify website, our team is here to help. Contact us today",
    },


}