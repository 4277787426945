import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import Projects from '../../../../Assets/images/portfolio/Projects'
import Index from '../../../../Assets/images/Index'

import { useLocation } from 'react-router-dom';
import _ from "underscore";
import { Helmet } from 'react-helmet'

export default function PortfolioDetail({ blueBoxRef }) {
    const myRef = useRef(null)
    const scrollDown = () => myRef.current.scrollIntoView({ behavior: 'smooth', })

    let location = useLocation();
    let param = useParams();
    const [webSite, setWebSite] = useState({})


    const viewProject = () => {

        let arr = Projects.filter(data => data.slug === param.id)

        if (arr) {
            setWebSite(arr[0])
        }

    }



    useEffect(() => {
        viewProject()
    }, [])
    // console.log("Projects", Projects)
    // console.log("myRef", myRef)

    return (
        <>
            {!_.isEmpty(webSite) &&
                <>
                    {/* metaTitle 
                metaDescription
                
                */}

                    <Helmet>
                        <link rel="canonical" href={`https://www.liquidwebdevelopers.com/portfolio/${webSite?.slug}`} />
                        <title>{webSite?.metaTitle} </title>
                        <meta name="description" content={webSite?.metaDescription} />

                    </Helmet>
                    <section className="portfolio_detail--head">
                        <div className="container">
                            <div className="client_logo">
                                <a href={webSite?.link} target="_blank">
                                    <img src={webSite?.logo} alt="" />
                                </a>
                            </div>
                            <div className="title_wrapper text-left">
                                <h2 className="common_heading">{webSite.title}</h2>
                            </div>

                            <div onClick={scrollDown} className="arrow_down d-flex align-items-center">
                                <img src={Index.arrowDown} alt="" />
                            </div>
                        </div>
                    </section>

                    <section className="client_info">
                        <div className="container">
                            <div className="client_info--listing d-flex flex-wrap align-items-center justify-content-between">
                                <div className="client_info--item">
                                    <h5 className="regular">Year</h5>
                                    <h5 className="extrabold">{webSite?.year}</h5>
                                </div>
                                <div className="client_info--item">
                                    <h5 className="regular">Clients</h5>
                                    <h5 className="extrabold">{webSite?.client}</h5>
                                </div>
                                <div className="client_info--item">
                                    <h5 className="regular">Business</h5>
                                    <h5 className="extrabold">{webSite?.business}</h5>
                                </div>
                                <div className="client_info--item">
                                    <h5 className="regular">Deliverable</h5>
                                    <h5 className="extrabold">{webSite?.deliverable}</h5>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="clientBanner">
                        <div className="clientBanner_img">
                            <img src={webSite?.banner} className="full_width" alt="" />
                        </div>
                    </section>

                    <section className="portfolioImgBox  aboutProject" ref={myRef}>
                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-md-12 col-lg-11">
                                    <div className="row justify-content-between aboutProject_outer">
                                        <div className="col-md-6 col-lg-4">
                                            <div className="aboutProject_info">
                                                <div className="aboutProject_info--item">
                                                    <p>Project Platform </p>
                                                    <h5 className="bold">{webSite?.platform}</h5>
                                                </div>
                                                <div className="aboutProject_info--item">
                                                    <p>Project Contract</p>
                                                    <h5 className="bold">{webSite?.contract}</h5>
                                                </div>

                                                <Link to={webSite?.link} className="common_btn common_btnBlack" target="_blank">Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="aboutProject_cntnt">
                                                <h3 dangerouslySetInnerHTML={{ __html: webSite.heading }}></h3>
                                                <p className="lightfont h6">{webSite?.detail}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="portfolioImgBox desktop_view">
                        <div className="container">
                            <div className="macStudio_wrap">
                                <div className="macStudio_screen">
                                    <div className="desktop_screen">
                                        <div className="screen_outer">
                                            <img src={Index.desktopFrame} className="frame" alt="" />
                                        </div>
                                        <div className="screen_inner">
                                            <img src={webSite?.pcBanner} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="macStudio_stand">
                                    <img src={Index.desktopstand} className="stand" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="portfolioImgBox  mobileView_section">
                        <div className="container">
                            <div className="mobileView_row d-flex justify-content-between">
                                {webSite?.mobileBanner.map((data, indx) => {
                                    return (
                                        <div className="mobileView_column" key={indx}>
                                            <div className="mobileView_box">
                                                <div className="mobileView_frame">
                                                    <img src={Index.mobileFrame} alt="" />
                                                </div>
                                                <div className="mobileView_screen">
                                                    <img src={data.img} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </section>
                    {webSite?.images && webSite?.images.top.map((data, indx) => {
                        let count = indx + 2
                        return (
                            <section className={`portfolioImgBox desktop_view-${count} `} key={indx}>
                                <div className="container">
                                    <div className={`desktop_view-${count}-inner`}>
                                        <img src={data.img} className="full_width" alt="" />
                                    </div>
                                </div>
                            </section>
                        )
                    })}

                    <section className="desktop_view-4 portfolioImgBox">
                        <div className="desktop_view-4-inner">
                            <img src={webSite?.images.big} className="full_width" alt="" />
                        </div>
                    </section>

                    {webSite?.images && webSite?.images.middle.map((data, indx) => {
                        return (
                            <section key={indx} className="portfolioImgBox desktop_view-5">
                                <div className="container">
                                    <div className="desktop_view-5-inner">
                                        <img src={data.img} className="full_width" alt="" />
                                    </div>
                                </div>
                            </section>
                        )
                    })}

                    <section className="portfolioImgBox desktop_view-7">
                        <div className="container">
                            <div className="row">
                                {webSite?.images && webSite?.images.column.map((data, indx) => {
                                    return (
                                        <div key={indx} className="col-12 col-sm-6 col-md-6">
                                            <div className="desktop_view-7-inner">
                                                <img src={data.img} className="full_width" alt="" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="portfoliobtn_wrap d-flex align-items-center justify-content-center">
                                <Link to="/portfolio" className="common_btn common_btnBlack">Back To Portfolio</Link>
                                <Link to={webSite?.link} className="common_btn common_btnBlack" target="_blank">Visit Website</Link>
                            </div>
                        </div>
                    </section>

                    <BlueBox blueBoxRef={blueBoxRef} title="Start your <br/> Shopify store" />

                </>
            }
        </>
    )
}
