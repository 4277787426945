import React from 'react'
import Index from '../../../Assets/images/Index'
import { Fade } from 'react-reveal'
export default function CountContentBox({ heading, subHeading, layoutType, list, Box4, icon, bottomBtn, executeScroll }) {
    return (
        <section className={`commonSection ${layoutType ? "step-six" : "choosing_services"}`}>
            <div className="container">
                <div className="heading_section text-center">
                    <Fade up> <div dangerouslySetInnerHTML={{ __html: heading }}></div> </Fade>
                    <Fade up> <p className="lightfont">{subHeading}</p> </Fade>
                </div>
                <div className="row no-gutters">
                    {list && list.length > 0 && list.map((data, indx) => {
                        return (
                            <Fade up key={indx} >
                                <div className={`col-sm-12 col-md-6 servicesBox col-lg-${Box4 ? 6 : 4}`}>
                                    <div className="servicesBox_inner d-flex wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                        <div className="servicesBox_number">{icon ? <img src={data?.img} alt={data?.title.split("<br>")} /> : indx + 1}</div>
                                        <div className="servicesBox_cntnt">
                                            <h5 className="extrabold pb-2" dangerouslySetInnerHTML={{ __html: data?.title }}></h5>
                                            <p className="font18 lightfont">{data?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        )
                    })}
                </div>
                {bottomBtn ?
                    <div className="text-center pt-2 pt-md-3 pt-lg-5">
                        <Fade up>    <button onClick={executeScroll} className="common_btn">Get Your Quotation</button></Fade>
                    </div> : null}
            </div>
        </section>
    )
}
