import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import { Helmet } from 'react-helmet'
export default function InternetMarketing({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Multiple Sales <br> Channel Set up", content: "With the help of our Shopify marketers, you can promote and sell your products through multiple sales channels. Reach millions of shoppers with our wellplanned Shopify marketing strategies." },
        { title: "Shopify Email Marketing", content: "Catch the right audience using our Shopify email marketing. From creating to automating, and tracking your email marketing campaigns we got you covered under cost-efficient pricing. " },
        { title: "Search Engine Marketing ", content: "We can run search engine paid ads to promote your products on different search engines like Google, Bing, etc. Based on your demands, consult our professionals to get expert assistance. " },
        { title: "Analytics and <br> Tracking", content: "Get real-time insights into your business with our Google analytics and tracking expertise with years of experience. we will implement the strategies accordingly to help you grow digitally. " },
        { title: "Social Media <br> Marketing", content: "Our Shopify marketers boost your sales by promoting your products through different popular social media platforms like Instagram, Facebook, Pinterest Twitter, etc through ad campaigns. " },
        { title: " Search Engine Optimization ", content: "We offer you proven SEO expertise to make your Shopify store visible on search engines with dedicated effort. We do Shopify store audits, product optimization, conversion rate optimization, etc. " },
    ]
    const whyhire = [
        { color: "#D4E9F3", title: "Proven Marketing Expertise ", desc: "We have helped many businesses grow and build their names in the digital world through our Shopify marketing services. We believe in building long-term relationships with clients by providing them with high-quality services. " },
        { color: "#FDEDAC", title: "Transparent Communication Process ", desc: "We update our clients about the ongoing process at every step regularly to track the project's progress. We are open to taking your feedback and working accordingly. " },
        { color: "#EAD3FF", title: "ROI <br> Focused <br>Approach ", desc: "Our Shopify marketing strategies are crafted keeping in mind to generate huge ROI for your business. We work following the result oriented approach. " },
        { color: "#CFF6A1", title: "Skilled Shopify Markets ", desc: "We have teams of experienced and well-trained Shopify marketers who completely understand your needs and have the potential to bring the desired outcome for you. " },
        { color: "#FFCFDE", title: "Competitive Marketing Prices ", desc: "You can avail of our different Shopify marketing services packages as per your budget regulation. We won't let your business suffer due to a stringent budget. We have feasible plans for budgets going low to high scale. " },
        { color: "#e2e7ff", title: "24*7 Tech Support ", desc: "Our Shopify marketing strategies are crafted keeping in mind to generate huge ROI for your business. We work following the result oriented approach. " },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "What is the best approach to get the maximum number of customers on my Shopify store? ", desc: "<p>Hiring an experienced Shopify marketing services agency is the right way to attract more audiences to your digital store in this competitive era. You can consult with our reliable Shopify marketers to get the right advice to make your business reach new heights. </p>" },
                { title: "When we can start seeing the visible results? ", desc: "<p>Different marketing services take different time frames to show real-time results. Yes, we can say that with paid search engine advertising strategy you can view the results within a few weeks. However, it can cost you more compared to other Shopify marketing services.</p>" },
                { title: "Which type of Shopify marketing services is best for my business? ", desc: "<p>After analyzing your business, and its requirements and considering your budget limits, you can reach the experienced Shopify marketing agency like us to achieve your goals in the decided time. Our marketing team can suggest you the right plan as per your budget.</p>" },
            ]
        },
        {
            id: 2, list: [
                { title: "What is the total cost of Shopify marketing services?", desc: "<p>Our Shopify marketing services agency will give you an accurate estimation of the cost after listening to your business needs. Digital marketing services involve the usage of various advanced technical tools and paid advertisements. Therefore, after consulting with our experts you will get to know about the exact cost as it varies depending on the client's needs </p>" },
            ]
        },
    ]
    const launchApp = [
        {
            title: "Get More Business <br> with Our Effective Shopify <br>Marketing Plans",
            paragraph: "Our Shopify marketing strategies are tailor-made considering your business requirements and concerns. Whether it's a small-scale or big-scale business, you can consider us for your store marketing with Shopify experts.",
            list: [
                { content: "Enhance brand awareness like never before " },
                { content: "Increase your customers and get high ROI " },
                { content: "Attract the right shoppers at your store " },
                { content: " Regular performance monitoring of your store " },
            ]
        },
    ]


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-digital-marketing" />                
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <InnerPageBanner
                bannerClass="bluechalhBg  imageBgSize internet-marketing-shop"
                heading={`<span class="highlight_text">Full-scale Shopify</span> Marketing Services Agency <span class="home_icon"> 🏪 </span>`}
                subtitle="Get a diligently crafted Shopify marketing services plan from our Shopify Marketers."
                bannerImg={Index.internetMarketingBanner}
                arrow
                leftCol="col-md-6 col-lg-6"
                rightCol="col-md-6 col-lg-6"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.internetShop1}
                smallImg={Index.seo}
                alt="shopify marketing agency"
                // commonClass="app-development-listing"

                heading={`Full-service Shopify <br/> <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Marketing </span> Agency`}>
                <div className="newStoreContent__text">
                    <p>Are you looking for Shopify Marketers to escalate your business growth ? We at Liquidweb Developers offer you full-scale Shopify digital marketing services at reasonable prices.
                        You can get the best attention from your target audience in the promised time by our Shopify marketers.
                    </p>
                    <p>Our Shopify digital marketing agency is among the top most Shopify development companies in the USA has helped top brands through the top marketing plan.</p>
                    <p>We invest the required time and effort into your brand to make it the fastest-growing online business! So, Hurry Up! Talk to our Shopify marketing experts.
                    </p>
                </div>

            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Our Wide Range of <br/> Shopify <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})"> Marketing </span> Services </h2>`}
                subHeading="Certified Shopify Marketing Agency"
                layoutType
                list={perksData}
            />


            <OptStore
                launchApp={launchApp}
                leftImg={Index.internetMrktn2}
                sectionClass="greenBg"
                btn
                btnText="Get Your Project Quotation"
                btnWhite
                alt="marketing with shopify"
                executeScroll={executeScroll}
            />
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> Reasons to <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Choose Us </span> As  <br /> Your Shopify Store SEO Services  </h2>
                        </div>
                        <SixBox listdata={whyhire} />
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Recognized by <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Top</span> Brands`} />
            <BlueBox blueBoxRef={blueBoxRef} title="Get a Befitting Shopify Marketing Solution" subtitle="Take Expert Advice from Us" />
        </>
    )
}
