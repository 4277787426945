import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import ThreePortfolioBox from '../../../Common/ThreePortfolioBox/ThreePortfolioBox'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import EndToEnd from '../../../Common/EndToEnd/EndToEnd'
import { Helmet } from "react-helmet";
export default function AppDevelopment({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Identifying Needs ", content: "Our Shopify experts first analyze your business needs and conduct extensive research about those needs. We also evaluate market trends for those specific needs. " },
        { title: "App Planning", content: "Based on our market research, we devise a final plan, including a list of milestones to achieve within a specific time period, ensuring the on-time delivery of the final product." },
        { title: "App Designing", content: "In this stage, our Shopify experts start designing the layouts, content pages, etc. to give a final look to the visuals of the Shopify app." },
        { title: "App Development", content: "In this step, after the designing phase, all the backend functionality, 3rd party integrations, etc. start. Once the development stage is over the Shopify app goes for testing " },
        { title: "App Testing ", content: "Testing is a crucial step where all the glitches and errors are removed by the Shopify testers before launching the product to make sure it will run smoothly once it enters the market." },
        { title: "Launch", content: "At this stage, after reviewing the guidelines, our experts launch your error-free Shopify app store into the market. Your rich-featured Shopify app will catch your customers' attention instantly." },
    ]

    const whyhire = [
        { color: "#D4E9F3", img: Index.designIcon, title: "Worldwide Clientele", desc: "Leading businesses across the globe have taken advantage of our scalable and reliable Shopify app development solutions. We have helped a variety of industries grow their businesses." },
        { color: "#FDEDAC", img: Index.messageIcon, title: "24/7 Tech Assistance", desc: "We provide our global clients with 24/7 Shopify technical support services, regardless of the different time zones. You can reach us via email or chat to resolve your issues." },
        { color: "#EAD3FF", img: Index.desktopIcon, title: "Quality <br> Assurance", desc: "We are committed to offering high-quality Shopify app development services to our client base. When taking on a project with our assistance, we left no room for error." },
        { color: "#CFF6A1", img: Index.userIcon, title: "Price Transparency", desc: "We don't have any extra fees included in our pricing. The cost factors are explained in great detail to the clients, ensuring a transparent pricing policy." },
        { color: "#FFCFDE", img: Index.badgeIcon, title: "Customized Apps", desc: "Our Shopify experts design an industry-specific solution that fully meets your business's unique needs. We incorporate the functions that help you achieve your business objectives." },
        { color: "#e2e7ff", img: Index.tagIcon, title: "Agile Development Strategy", desc: "By segmenting the entire project into manageable chunks, we develop projects in a methodical manner that allows us to meet deadlines." },
    ]

    const faqData = [
        {
            id: 1, list: [
                { title: "What is the Shopify app?", desc: "<p>It is the most feasible way to sell your products to customers, and you can conveniently manage the orders, products, and customers wherever you are directly from your smart device.</p>" },
                { title: "Which tools do you use for Shopify app development?", desc: "<p>There are various advanced tools for Shopify's app development process. The most popular ones are GitHub, Sublime Text, Trello, Chrome, Spotify, Sketch, Power Tools, etc.</p>" },
                { title: "Why should we hire you as a Shopify app development company?", desc: "<p>We have a vast portfolio built over the last 10 years of helping businesses looking for Shopify app development services. We have certified professionals with high expertise. You will never regret working with them.</p> " },
                { title: "Can I turn my Shopify store into a Shopify app?", desc: "<p>Yes, of course, you can turn your Shopify store into Shopify app with the assistance of experienced professionals like LiquidWeb Developers. You can take advice from our Shopify experts and get started.</p>" },


            ]
        },
        {
            id: 2, list: [
                {
                    title: "What is the estimated cost of developing the Shopify app? ", desc: "<p>The Shopify app development cost is not fixed; it varies based on the client's app specifications and business requirements. For this, you need to fill out the form given below and consult with our Shopify experts to know the exact cost of your project. </p>"
                }
            ]
        },
    ]
    const launchApp = [
        {
            title: "Launch Your Own Custom Shopify App ",
            paragraph: "Get a fully functional custom Shopify app for your online business. We take care of all the security measures to protect customer data and avoid thefts as well as no unauthorized user can access the application. ",
            list: [
                { content: "Smartly designed, rich-featured Shopify app under our assistance helps you attract more customers." },
                { content: "The scalable nature of Shopify apps allows you to add/remove features based on your business needs." },
                { content: "Third-party integrations are possible with your Shopify app to extend the functionalities." },
                { content: "With easy to navigate user interface, your customers get a smooth shopping experience." },
            ]
        },
    ]
    const appdevServce = [
        {
            heading: "Custom Theme Apps",
            icon: Index.end1,
        },
        {
            heading: "Custom Pricing Apps ",
            icon: Index.end5,
        },
        {
            heading: "Custom Marketing Apps",
            icon: Index.customMarketing,
        },
        {
            heading: "Order Management Apps",
            icon: Index.four,
        },
        {
            heading: "Customer Management Apps",
            icon: Index.customerManagement,
        },
        {
            heading: "ERP <br />Connectors",
            icon: Index.erp,
        },
        {
            heading: "3rd Party <br /> Integration <br /> Apps",
            icon: Index.seven,
        },
        {
            heading: "Customized Checkout Apps",
            icon: Index.devCartIcn,
        },
    ]

    const benifitsofApp = [
        { icon: "🧲", cntnt: "The Shopify apps are compatible to integrate social media channels seamlessly, which can help to empower your brand image among your customers." },
        { icon: "🛠️", cntnt: "The highly scalable nature of the Shopify app allows you to extend its features any time you want as per your requirements and concerns." },
        { icon: "📐", cntnt: "Customizing and optimizing the Shopify apps is convenient, so the designers can provide more personalized applications to businesses and users as well." },
        { icon: "📈", cntnt: "Merchants can get in detailed analytics of the purchase data stored on the Shopify app." },
        { icon: "📣", cntnt: "The Shopify apps include different marketing options to get better results in terms of conversion rates." },
    ]

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-app-development" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>

            <InnerPageBanner
                bannerClass="champagneBg imageBgSize app-development"
                heading={`<span class="highlight_text">Robust </span> Shopify App Development Services <span class="home_icon">🏪</span>`}
                subtitle="Transform your eCommerce business by launching a scalable Shopify app designed by our professionals. "
                bannerImg={Index.appDevelopmentBanner}
                leftCol="col-md-6 col-lg-7"
                rightCol="col-md-6 col-lg-5"
                arrow
                arrowCurve
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.appDevelopment1}
                smallImg={Index.Mobile}
                commonClass="app-development-listing"
                alt="shopify app development"
                heading={`Top-notch <span class="borderBtm" style="background-image: url(${Index.homeApproach})"> Benefits of a </span> Customized Shopify App`}
            >
                <div className="card_listing">
                    <ul>
                        {benifitsofApp.map((data, indx) => {
                            return (
                                <li key={indx}>
                                    <span className="cardlisting__img">{data.icon}</span>
                                    <span className="cardlisting__cntnt">{data.cntnt} </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </TwoColList>
            <CountContentBox
                heading={`<h2 class="common_heading">  Our Systematic <span class="borderBtm" style="background-image: url(${Index.appDevelopmentLineBg})">Shopify App Development </span> Process</h2>`}
                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                layoutType
                list={perksData}
            />
            <OptStore
                launchApp={launchApp}
                leftImg={Index.appDevelopment2}
                btn
                btnText="Request a Proposal"
                btnWhite
                sectionClass="greenBg"
                executeScroll={executeScroll}
                alt="app development services"
            />

            <EndToEnd
                title={`Our <span class="borderBtm" style="background-image: url(${Index.homePotential})"> End-to-End </span> <br />Shopify App Development Services`}
                subHeading="Here are our varied Shopify app development services available at competitive prices for global clients."
                list={appdevServce}
                parentClass="pb-0"
            />
            <ThreePortfolioBox heading={`Featured <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Shopify App</span>`} />

            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> Reasons to <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Choose Us </span> As <br />Your Shopify App Development
                            </h2>
                            <p>Prominently Leading Website</p>
                        </div>
                        <SixBox listdata={whyhire} />
                        <div className="text-center pt-3 pt-md-3">
                            <button onClick={executeScroll} className="common_btn">Get Started Now</button>
                        </div>
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />

            <TrustedByBrands commonClass title={`We've Established Unbreakable <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Trust </span > With Our Clients`} />
            <BlueBox blueBoxRef={blueBoxRef} title="Don't Wait Any <br> Longer" subtitle="Fill Out the Form" />
        </>
    )
}
