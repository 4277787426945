export default [
    {
        name: "Luna",
        logo: require("./luna.webp"),
        height: 25
    },

    {
        name: "Neuro",
        logo: require("./neuro.webp"),
        height: 25
    },

    {
        name: "Tiem athletic",
        logo: require("./tiemathletic.webp"),
        height: 25
    },

    {
        name: "Sandandsky",
        logo: require("./sandandsky.webp"),
        height: 40
    },
    {
        name: "Truly",
        logo: require("./truly.webp"),
        height: 25
    },
    {
        name: "Amino",
        logo: require("./amino.webp"),
        height: 40
    },
    {
        name: "Ritani",
        logo: require("./ritani.webp"),
        height: 25
    },

    {
        name: "Gym shark",
        logo: require("./gymshark.webp"),
        height: 40
    },
    {
        name: "Sukiso",
        logo: require("./sukiso.webp"),
        height: 20
    },

    {
        name: "Loungewear republic",
        logo: require("./loungewearrepublic.webp"),
        height: 30
    },
    {
        name: "beginning Boutique",
        logo: require("./beginningBoutique.webp"),
        height: 30
    },

]