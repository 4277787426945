import React from 'react'
import BlueBox from '../../Common/BlueBox/BlueBox'

export default function CookiePolicy() {
    return (
        <>
            <section className="greenBg commonSection portFolio__bnr position-relative">
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="extrabold white_clr text-center">Cookie Policies </h1>
                        </div>
                    </div>

                    <div className="buble_box circle white_buble small_buble "></div>
                </div>
            </section>
            <section className="commonSection">
                <div className="container">
                    <div className="inner_container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-addon__content">
                                    <p>Liquid Web Developers use Cookies to provide website users with an enhanced browsing experience. You can enable/disable the cookies options by changing your browser settings.</p>

                                    <h4>Cookies</h4>
                                    <p>Cookies are the small data files that are sent to the user's computer when browsing the website. It allows the websites to remember the users' behavior and details like logins, display preferences, etc.</p>
                                    <p>It saves you from entering the details again and again when you return back to the website and browse the different pages. Few of our cookies are required because of certain website functions. Without such cookies, the
                                        website cannot operate effectively.</p>
                                    <p>There are several types of cookies as well. Some include:</p>
                                    <ul>
                                        <li>First-Party Cookies</li>
                                        <li>Third-Party Cookies</li>
                                        <li>Persistent Cookies</li>
                                        <li>Secure Cookies</li>
                                        <li>Session Cookies</li>
                                        <li>HTTP Only Cookies</li>
                                        <li>And other</li>
                                    </ul>

                                    <h4>Third-party cookies</h4>
                                    <p>You may receive third-party cookies when you use the website. Cookies may be sent to you by our advertisers and service providers. They may utilize the information received from your use of cookies for the following purposes:</p>
                                    <ul>
                                        <li>To track your browser across multiple websites</li>
                                        <li>To create a profile of your web browsing</li>
                                        <li>To target ads that may be of specific interest to you</li>
                                        <li>To examine the usage of the website (analytics tools)</li>
                                    </ul>

                                    <h4>Advertising Cookies: Under Third-party Cookies</h4>
                                    <p>Advertising Cookies are used to demonstrate targeted promotions or advertisements on the website in accordance with your preferences or to manage our advertising. These Cookies track your activities on this Site and other
                                        websites in order to provide you with targeted advertising.</p>
                                    <p>We use ad cookies from <strong>Google and Facebook</strong> in particular. They enable us to serve you more personalized ads based on your interests and the pages you've visited. To understand it in a better way you can
                                        consider this example:</p>
                                    <p>Assume a consumer sees a product advertisement on Facebook. He clicks on it, visits the product's website, and adds an item to his shopping cart but does not decide to buy it. The user after which shuts down the website
                                        and keeps going to surf the web. The user will now begin to see advertisements for similar products on some other websites.</p>
                                    <p>This happens because the third-party cookies track a user's online activity and shows ads not only on one website but across various websites. Businesses use third-party cookies to increase engagement and to aim for a greater
                                        market across websites.</p>

                                    <h4>Among the most important business data that advertising cookies(Google, Facebook ad campaigns, etc) can obtain include: </h4>
                                    <ol>
                                        <li>Age, location, gender, and language are all examples of demographic information.</li>
                                        <li>Session length, bounce rate, pages visited in a session, and are all examples of behavior data.</li>
                                        <li>Information on user conversion.</li>
                                        <li>Search engine and device data, such as what device a visitor is using, operating system, and mobile screen.</li>
                                    </ol>
                                    <h4>How do we use cookies?</h4>
                                    <p>Cookies do not possess any information that helps to personally recognize you. However, we may link the personal information stored with us to the information obtained and stored in cookies. The details we acquire from
                                        your use of our cookies could be used for the specified objectives:</p>
                                    <ul>
                                        <li>To enhance the usability of the website</li>
                                        <li>To enhance the user experience on the website</li>
                                        <li>To examine how the website is used</li>
                                        <li>To tailor the website to your preferences, which includes targeting advertisements that might be of specific interest to you.</li>
                                    </ul>

                                    <h4>How Long Do Cookies Remain on a Device?</h4>
                                    <p>The duration of cookies on your device is determined by their type, i.e., whether they are persistent or session cookies. Persistent cookies are active until they stop working or are deleted, whereas session cookies are
                                        still only effective while browsing. Cookies can be governed for details or deleted by manual process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BlueBox title="Start your <br/> Shopify store" />
        </>
    )
}
