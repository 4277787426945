import React, { useEffect, useState } from 'react'
import Projects from '../../../Assets/images/portfolio/Projects'
import BlueBox from '../../Common/BlueBox/BlueBox'
import { Link } from 'react-router-dom'
import _ from "underscore"
import { Helmet } from "react-helmet";
export default function Portfolio({ blueBoxRef, executeScroll, seo }) {

    const [projectList, setProjectList] = useState([])
    const [tab, setTab] = useState([])
    const [activetab, setActiveTab] = useState("all")

    const changeTab = (e) => {
        if (e === "all") {
            setProjectList(Projects)


        } else {
            const updateProductList = Projects.filter(data => data.category === e)
            setProjectList(updateProductList)
        }
        setActiveTab(e)


    }

    useEffect(() => {
        let arr = []
        Projects.map((opt) => {
            arr.push(opt.category)
        })
        setTab(_.uniq(arr))

        setProjectList(Projects)

    }, []);


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/portfolio" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <section className="greenBg commonSection portFolio__bnr position-relative">
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="extrabold white_clr text-center">Featured Works </h1>
                        </div>
                    </div>

                    <div className="buble_box circle white_buble small_buble "></div>
                </div>
            </section>

            <section className="portfolio_section commonSection themedev_tab  portFolio__bnrBtm">
                <div className="container">

                    <div className="row heading_section">
                        <div className="col-md-12">
                            <div className="themedev_tabinner">
                                <ul id="filterOptions">
                                    <li className={activetab === "all" ? "active" : ""}>
                                        <button className="all" onClick={(e) => changeTab("all")}>All </button>
                                    </li>
                                    {tab.map((data, indx) => {
                                        return (
                                            <li key={indx} className={activetab === data ? "active" : ""}>
                                                <button className="beauty" onClick={(e) => changeTab(data)}> {data}</button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="themedevfilter_content" id="ourHolder">
                        {projectList.map((data, index) => {
                            return (
                                <div key={index} className="col-12 col-sm-12 col-md-12 col-lg-9 mx-auto beauty item">
                                    <Link to={`${data.slug}`}>
                                        <div className="themedevfilter_box outer_glow">
                                            <img src={data.pcBanner} alt={data.name} />
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}




                    </div>


                </div>


            </section>
            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br/> Shopify store" />
        </>
    )
}
