import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import '../Assets/css/slick.css';
import '../Assets/css/bootstrap-grid.css';
import '../Assets/css/main.css';
import '../Assets/css/responsive.css';

import Header from '../Components/Header/Header';
import ScrollToTop from '../Components/Common/ScrollToTop/ScrollToTop';
import Footer from '../Components/Footer/Footer';
import Home from '../Components/Pages/Home/Home';
import About from '../Components/Pages/About/About';
import Services from '../Components/Pages/Services/Services';
import Portfolio from '../Components/Pages/Portfolio/Portfolio';
import PortfolioDetail from '../Components/Pages/Portfolio/PortfolioDetail/PortfolioDetail';
import Contact from '../Components/Pages/Contact/Contact';
import AppDevelopment from '../Components/Pages/Services/AppDevelopment/AppDevelopment';
import Customization from '../Components/Pages/Services/Customization/Customization';
import Migration from '../Components/Pages/Services/Migration/Migration';
import Store from '../Components/Pages/Services/Store/Store';
import Integration from '../Components/Pages/Services/Integration/Integration';
import Maintenance from '../Components/Pages/Services/Maintenance/Maintenance';
import InternetMarketing from '../Components/Pages/Services/InternetMarketing/InternetMarketing';
import Optimization from '../Components/Pages/Services/Optimization/Optimization';
import Career from '../Components/Pages/Career/Career';
import PrivacyPolicy from '../Components/Pages/PrivacyPolicy/PrivacyPolicy';
import Terms from '../Components/Pages/Terms/Terms';
import CookiePolicy from '../Components/Pages/CookiePolicy/CookiePolicy';
import PageNotFound from '../Components/Pages/PageNotFound/PageNotFound';
// import Blog from "../Components/Pages/Blogs/Index"
// import BlogDetail from '../Components/Pages/Blogs/Detail/BlogDetail';

import Seo from "./Seo"

 
import { useRef } from 'react'; 

function App() {
  const blueBoxRef = useRef(null)
  const executeScroll = () => { 
    if(blueBoxRef.current){
      blueBoxRef?.current.scrollIntoView({ behavior: 'smooth', })
    }
  } 

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header executeScroll={executeScroll} />
        <main className="middle">   
          <Routes>
            <Route exact path="/" element={<Home blueBoxRef={blueBoxRef} seo={Seo.home}  executeScroll={executeScroll}  />} />
            <Route exact path="/about" element={<About blueBoxRef={blueBoxRef} seo={Seo.about}  executeScroll={executeScroll} />} />
            <Route exact path="/portfolio" element={<Portfolio blueBoxRef={blueBoxRef} seo={Seo.portfolio} executeScroll={executeScroll} />} />
            <Route exact path="/portfolio/:id" element={<PortfolioDetail blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/contact" element={<Contact blueBoxRef={blueBoxRef} seo={Seo.contact} executeScroll={executeScroll} />} />
           
            <Route exact path="/services" element={<Services seo={Seo.services} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-app-development" element={<AppDevelopment seo={Seo.servicesAppDevelopment} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-theme-customization" element={<Customization seo={Seo.servicesCustomization} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-store-migration" element={<Migration seo={Seo.servicesMigration} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-store-development" element={<Store seo={Seo.servicesStore} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-api-integration" element={<Integration seo={Seo.servicesIntegration} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-store-maintenance" element={<Maintenance seo={Seo.servicesMaintenance}  blueBoxRef={blueBoxRef} executeScroll={executeScroll}/>} />
            <Route exact path="/services/shopify-digital-marketing" element={<InternetMarketing seo={Seo.servicesInternetMarketing} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/services/shopify-store-optimization" element={<Optimization seo={Seo.servicesOptimization} blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            {/* <Route exact path="/blog" element={<Blog blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/blog/:id" element={<BlogDetail blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} /> */}
           
            <Route exact path="/career" element={<Career blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="/terms" element={<Terms  blueBoxRef={blueBoxRef} executeScroll={executeScroll}/>} />
            <Route exact path="/cookie-policies" element={<CookiePolicy blueBoxRef={blueBoxRef} executeScroll={executeScroll} />} />
            <Route exact path="*" element={<PageNotFound  blueBoxRef={blueBoxRef} executeScroll={executeScroll}/>} />
            {/* <Route path="*" element={<PageNotFound />} />
              <Route exact path="/manage" element={<Manage />} />
              <Route exact path="/sell" element={<Sell />} />
              <Route exact path="/sell/facebook" element={<Facebook />} />
              <Route exact path="/sell/instagram" element={<Instagram />} />
              <Route exact path="/pricing" element={<Pricing />} />
              <Route exact path="/learn" element={<Learn />} />
              <Route exact path="/learn/detail" element={<LearnDetail />} /> */}
          </Routes>         
        </main>
        <Footer />
      </Router>
    </div>

  );
}

export default App;
