import React  from 'react'
import Index from '../../../Assets/images/Index'
import InnerPageBanner from '../../Common/InnerPageBanner/InnerPageBanner'

export default function Career() {
    const openJobs = [
        { title: "Project Manager", type: "Full Time", exp: "5+ Years Experince", location: "Remote" },
        { title: "Backend Developer", type: "Full Time", exp: "5+ Years Experince", location: "Remote" },
        { title: "UI/UX Designer", type: "Full Time", exp: "5+ Years Experince", location: "Remote" },
    ]

    return (
        <>
            <InnerPageBanner
                bannerClass="bluechalhBg imageBgSize career_banner"
                heading={`Join The <span class="highlight_text"> Team </span> <span class="home_icon">👨‍👦‍👦</span>`}
                subtitle="We innovate, build cool stuff and have fun. Want to join us? See what jobs are available below "
                bannerImg={Index.careerBanner}
                arrow
                leftCol="col-md-6 col-lg-6"
                rightCol="col-md-6 col-lg-6"
            />

            <section className="job_listing_section commonSection">
                <div className="container">
                    <div className="career_wrapper">
                        <div className="title_wrapper text-center">
                            <h3 className="common_heading">Open Positions {openJobs.length > 9 ? `(${openJobs.length})` : `(0${openJobs.length})`}</h3>
                            <p>Search Jobs At Shopify+ Developers</p>
                        </div>

                        <div className="recent_job_listing">
                            {openJobs.map((data, index) => {
                                return (
                                    <div className="recent_job_card" key={index}>
                                        <div className="content row align-items-center justify-content-between">
                                            <div className="col-md-8 col-lg-9">
                                                <h4 className="extrabold pb-2">{data.title}</h4>
                                                <ul className="job_list list-tyle-none">
                                                    <li>{data.type}</li>
                                                    <li>{data.exp}</li>
                                                    <li>{data.location}</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <a href="#" className="common_btn">Apply Now</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </section>

            <section className="liquidWeb_life-section commonSection pt-0">
                <div className="container">
                    <div className="title_wrapper text-center">
                        <h3 className="common_heading"><span className="borderBtm" style={{ backgroundImage: `url(${Index.life})` }}>Life @</span> Liquidweb Developers</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ullamcorper, risus <br /> ac convallis suscipit, sapien erat faucibus purus, et euismod.</p>
                    </div>

                    <div className="row liquidWeb_life-row">
                        <div className="col-sm-6 col-md-6">
                            <div className="liquidWeb_life-img">
                                <img src={Index.galleryImg1} alt="" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="liquidWeb_life-img">
                                        <img src={Index.galleryImg2} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="liquidWeb_life-img scndImg">
                                                <img src={Index.galleryImg3} alt="" />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="liquidWeb_life-img scndImg">
                                                <img src={Index.galleryImg4} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qualities_section commonSection">
                <div className="container">
                    <div className="career_wrapper">
                        <div className="title_wrapper text-center">
                            <h3 className="extrabold">Qualities We <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeOffer})` }}> Look</span> As Team Member</h3>
                        </div>

                        <div className="qualities_content">
                            <img src={Index.teamMember} alt="" />

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ullamcorper, risus ac convallis suscipit, sapien erat faucibus purus, et euismod.Donec in lacus metus. Sed quis dolor dignissim, molestie lacus eu, volutpat est.
                            </p>
                            <p>Vestibulum scelerisque eros vitae blandit viverra. Maecenas a nunc tempus, euismod purus et, tincidunt felis.Ut sed iaculis nibh. Pellentesque sed dolor urna. Suspendisse a facilisis erat. Vestibulumipsum velit, congue in augue
                                ut, blandit malesuada augu.Vestibulum scelerisque eros vitae. Vestibulum scelerisque eros.</p>
                        </div>
                        <div className="row quilities_listing">
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.handIcon} alt="" />
                                    <h5>Work Ethic</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.adaptabilityIcon} alt="" />
                                    <h5>Adaptability</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.communicationIcon} alt="" />
                                    <h5>Communication <br /> Skills</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.technicalOptimization} alt="" />
                                    <h5>Technical Competency</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.growthIcon} alt="" />
                                    <h5>Growth <br /> Mindset</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="quility_column">
                                    <img src={Index.commnityIcon} alt="" />
                                    <h5>Community <br /> Engagement</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
