import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export default function BreadCrums(props) {
    let currentPath1 = window.location.pathname.split("/") 
    return (
        <>
            {window.location.pathname !== "/" ?
                <nav className="breadcrumb" aria-label="breadcrumb">
                    <ol className="list-tyle-none">
                        <li className="breadcrumb_item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb_item">{props.currentPath.length > 2 ?
                            <Link to={props.currentPath[1]}>{props.currentPath[1]}</Link> :
                            props.currentPath[1]}</li>

                        {props.currentPath[2] ? <li className="breadcrumb_item active">{props.currentPath[2]}</li> : null}
                    </ol>
                </nav> : null

            }
        </>
    )
}
