import React from 'react'
import Index from '../../../Assets/images/Index'
import Projects from '../../../Assets/images/portfolio/Projects'
import _ from "underscore"
import { Fade, Slide } from 'react-reveal';
import Projectlogos from '../../../Assets/images/portfolio/logo/Projectlogos';
export default function TrustedByBrands({ title, commonClass }) {
    return (
        <section className={`ourClients_section ${commonClass ? "commonSection pb-0" : ""}`}>
            <div className="container">
                <h3 className="extrabold text-center" > <span dangerouslySetInnerHTML={{ __html: title }}></span> </h3>
                <div className="our_clients commonSection">
                    {Projectlogos.slice(0, 10).map((data, indx) => {
                        return (
                            <Slide key={indx} bottom>
                                <div className="our_clintsinner"  >
                                    <img src={data.logo} alt={data.name} style={{ height: data.height + 'px' }} />
                                </div>
                            </Slide>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
