import React from 'react'
import Index from '../../../Assets/images/Index'
import { Fade } from 'react-reveal'
export default function OptStore({ launchApp, leftImg, btn, btnText, btnWhite, sectionClass, executeScroll, alt }) {
    return (
        <section className={`opt_store commonSection dream_relity ${sectionClass ? sectionClass : ""}`}>
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 col-lg-6">
                        <div className="store_devService store_devServiceOutr wow fadeInUp " data-wow-delay="0.2s " data-wow-duration="1000ms ">
                            <Fade up>     <img src={leftImg} alt={alt} /> </Fade>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="new_store_content common_content wow fadeInUp " data-wow-delay="0.2s " data-wow-duration="1000ms ">
                            {launchApp.map((data, indx) => {
                                return (
                                    <React.Fragment key={indx}>
                                        <Fade up>
                                            <h3 className="common_heading white_clr" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
                                        </Fade>
                                        <div className="newStoreContent__text">
                                            <Fade up>
                                                <p className="white_clr">{data.paragraph}</p>
                                            </Fade>
                                        </div>
                                        <div className="cardarrow_listing">
                                            <ul>
                                                {data.list.map((subd, index) => {
                                                    return (
                                                        <Fade key={index} up>
                                                            <li >
                                                                <span className="cardlisting__img">
                                                                    <img src={Index.whiteArrow} />
                                                                </span>
                                                                <span className="cardlisting__cntnt">{subd.content}</span>
                                                            </li>
                                                        </Fade>
                                                    )
                                                })}
                                            </ul>
                                            {btn ?
                                                < div className="text-left">
                                                    <Fade up>
                                                    <button onClick={executeScroll} className={`common_btn ${btnWhite ? "common_btnWhite" : ""}`}> {btnText}</button>
                                                    </Fade>
                                                </div> : null
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
