import React from 'react'
import { Link } from 'react-router-dom'
import "./PageNotFound.css"

export default function PageNotFound() {
    return (
        <>
            <div className='pagenotfound'>


                <div className="pagenotfound__top">
                    <div className='pagenotfound__svg'>
                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#D0E2FF" d="M41.7,-73.7C54.6,-64.7,66.2,-54.8,73.2,-42.4C80.2,-29.9,82.5,-15,82.5,0C82.6,15,80.3,30,73.8,43.3C67.4,56.6,56.7,68.3,43.7,77.1C30.7,86,15.4,92.2,0.9,90.6C-13.5,88.9,-27,79.5,-39.5,70.3C-52,61.2,-63.6,52.3,-70.9,40.6C-78.2,28.9,-81.2,14.5,-81.4,-0.1C-81.5,-14.6,-78.8,-29.3,-71.8,-41.5C-64.9,-53.8,-53.6,-63.6,-40.9,-72.8C-28.3,-81.9,-14.1,-90.4,0.1,-90.6C14.4,-90.8,28.7,-82.7,41.7,-73.7Z" transform="translate(100 100)" />
                        </svg>
                    </div>
                    <h1 className='pagenotfound__title'>404</h1>
                    <h3 className='pagenotfound__subtitle'>Oops!! page not found</h3>
                </div>
                <Link to="/" className='common_btn'>Go to Home</Link>
            </div>
        </>
    )
}
