import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import ThreePortfolioBox from '../../../Common/ThreePortfolioBox/ThreePortfolioBox'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import EndToEnd from '../../../Common/EndToEnd/EndToEnd'
import ShopifyThemesInclude from '../../../Common/ShopifyThemesInclude/ShopifyThemesInclude'
import { Helmet } from "react-helmet";
export default function Customization({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Visually Pleasing Store ", content: "Beautifully designed themes draw customers to your brand because they convey the brand's high standards for quality. It builds a long- lasting relationship between both parties." },
        { title: "Encourage More Sales", content: "When your products are perfectly represented through customization, it leads to customers being convinced to buy from your store, which consequently generates more leads." },
        { title: "Build Brand Credibility", content: "Customers are more likely to believe in your brand when the storefront's presentation, layout, and product descriptions live up to their expectations, which boosts your brand's credibility. " },
    ]
    const whyhire = [
        { color: "#D4E9F3", img: Index.designIcon, title: "Affordable Prices", desc: "Without exceeding your budget, our Shopify developers work hard to offer top-notch services for creating custom Shopify themes at affordable rates." },
        { color: "#FDEDAC", img: Index.messageIcon, title: "Complete Customization ", desc: "We provide enterprise Shopify solutions that are fully customized for you. Whenever you need us, we are here to make the necessary changes." },
        { color: "#EAD3FF", img: Index.desktopIcon, title: "Transparent Communication", desc: "We update our clients about the ongoing process at every step to track the project's progress. We are open to taking your feedback into account and working accordingly." },
        { color: "#CFF6A1", img: Index.userIcon, title: "Customer Centered Methodology ", desc: "Our team members work diligently to meet deadlines in order to save our clients' time. Results aren't postponed by us." },
        { color: "#FFCFDE", img: Index.badgeIcon, title: "Detailed Store Analysis ", desc: "Our Shopify specialists examine your store's layout to identify areas that require customization before strategically devising a plan to achieve the best outcomes." },
        { color: "#e2e7ff", img: Index.tagIcon, title: "Certified Experts", desc: "We have qualified professionals with years of experience who have flawlessly customized more than 80 stores on Shopify. So, you can rest assured that everything is in our hands." },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "Do you sign an NDA?", desc: "<p>Yes, our Shopify development company signs an NDA with every client we work with. We value your privacy and are committed to keeping all project details confidential. </p>" },
                { title: "How long does it take to design a custom Shopify theme from scratch?", desc: "<p>The custom theme development can take about 2-4 weeks and even more based on the design requirements and project complexities. Crafting Shopify themes that are unique and meet expectations takes a significant time investment.</p>" },
                { title: "What is the cost of custom theme development for Shopify? ", desc: "<p>The cost may vary from project to project. So, the wise move is to fill out the form on our website and get an accurate estimate of the cost of your project. We aim to offer cost-effective custom theme development services to our clients. </p> " },
                { title: "Why should you hire LiquidWeb Developers to create a custom Shopify theme? ", desc: "<p>We have proven experience in the concerned field and have served various clients across the globe. </p><ul><li>High expertise </li><li>Transparent cost</li><li> Easy communication</li><li> Round-the-clock support</li></ul>" },


            ]
        },
        {
            id: 2, list: [
                {
                    title: "Why is Shopify theme development the right option?", desc: "<p>Custom theme development allows you to build a store that has the potential to represent your brand visually and perfectly convey your ideas and products to the target audience. And this is impossible with the use of default themes in Shopify. </p>"
                }
            ]
        },
    ]
    const launchApp = [
        {
            title: "Make Your Dream Reality <br>With Our Shopify Experts",
            paragraph: "Get fascinating Shopify theme customization for your business domain. We cater to the needs of businesses of all sizes, from startups to established enterprises. Leave a strong impression on your audience's minds and build a unique brand identity to stay way ahead of your competition.",
            list: [
                { content: "Responsive Shopify Themes with Unique Designs " },
                { content: "Understand the Industry-specific Requirements " },
                { content: "On-time Custom Theme Design Services " },
                { content: "Assistance via Chat, Email, or Calls " },
            ]
        },
    ]
    const appdevServce = [
        {
            heading: "Customized <br> Theme <br> Elements",
            icon: Index.one,
        },
        {
            heading: "Custom Images/Icons",
            icon: Index.two,
        },
        {
            heading: "Customized Typography",
            icon: Index.three,
        },
        {
            heading: "Style Customization",
            icon: Index.four,
        },
        {
            heading: "Top Headline Customization",
            icon: Index.five,
        },
        {
            heading: " Hero Slider Management",
            icon: Index.six,
        },
        {
            heading: "Custom <br> Section Development",
            icon: Index.seven,
        },
        {
            heading: "Semantic <br> Customized <br>CSS",
            icon: Index.eight,
        },
        {
            heading: "Custom JS Development",
            icon: Index.nine,
        },
        {
            heading: "Customized <br> Catalog Page",
            icon: Index.ten,
        },
        {
            heading: "Customized Product Descriptions",
            icon: Index.eleven,
        },
    ]


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-theme-customization" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>

            <InnerPageBanner
                bannerClass="pattens-blueBg shopify-customization"
                heading={`<span class="highlight_text">Shopify </span> Custom <span class="home_icon">🏪</span> Theme Development`}
                subtitle="Collaborate with our Shopify experts for Eye-catching Shopify theme designs build with extra care."
                bannerImg={Index.shopifyCustomTree}
                leftCol="col-md-7 col-lg-6"
                rightCol="col-md-5 col-lg-5"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.shopifyCustomizeImg1}
                smallImg={Index.shopifyCutomizeCart}
                // commonClass="app-development-listing"
                alt="shopify theme development company                "
                heading={`<span class="borderBtm" style="background-image: url(${Index.serviceShopify})"> Reliable Shopify </span> Theme <br>Development Company`}            >
                <div className="newStoreContent__text">
                    <p>Are you excited to launch a Shopify store packed with pixel-perfect Shopify themes to revamp your digital presence? Our extensive expertise in creating unique themes for Shopify stores has successfully met the needs of
                        our clients and established enduring relationships with them. </p>
                    <p>If you are looking for Custom Shopify Development Services, you've come to the right place. Look no further, as we provide you with exquisitely crafted themes at the most competitive prices.</p>
                    <p>The budget is not a concern to customize shopify website in perfect way! We strive to offer you the best designs meeting your needs under your budget.</p>
                </div>
            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Capture Your <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Users Attention </span> <br/> with Aesthetic Shopify Theme Designs
                </h2>`}
                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                layoutType
                list={perksData}
            />
            <OptStore
                launchApp={launchApp}
                leftImg={Index.butyTheme}
                btn
                btnText="Get Estimation"
                btnWhite
                sectionClass="greenBg"
                executeScroll={executeScroll}
                alt="customize shopify website "
            />
            <EndToEnd
                title={`End-to-End Store <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Custom </span> Theme <br/> Development Services `}
                subHeading="Our Shopify custom theme development services primarily consist of these elements."
                list={appdevServce}
            />
            <ShopifyThemesInclude />
            <ThreePortfolioBox heading={`Featured <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Shopify App</span>`} />
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> Reasons to <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Choose Us </span> As <br />Your Shopify App Development
                            </h2>
                            <p>Prominently Leading Website</p>
                        </div>
                        <SixBox listdata={whyhire} />
                        <div className="text-center pt-3 pt-md-3">
                            <button onClick={executeScroll} className="common_btn">Get Started Now</button>
                        </div>
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`We've Established Unbreakable <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Trust </span > With Our Clients`} />
            <BlueBox blueBoxRef={blueBoxRef} title="Want Captivating Shopify Store Themes?" subtitle="Talk to Our Visionary Professionals" />
        </>
    )
}
