import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import StepBox from '../../../Common/StepBox/StepBox'
import { Helmet } from 'react-helmet'
export default function Integration({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Sales Channels Integration", content: "Enhance your sales and order value with our sales channels integration services that cover integrating wishlists, product bundling, reviews, recommendations, etc. " },
        { title: "Loyalty Program Integration", content: "We are experts in integrating loyalty and referring programs in your Shopify stores that have the potential to increase sales and brand loyalty." },
        { title: "Marketing Channel Integration", content: "You can increase traffic to your online store and improve user engagement with the help of our marketing channel integration." },
        { title: "Social Media Integration", content: "Level up your eCommerce business with Shopify social media integration to reach more audiences. A broader social presence adds value to your unique brand identity." },
        { title: "Payment Integration", content: "To make the payment process flexible and convenient for your users, we offer safe payment integration through a variety of payment gateways." },
        { title: "Shipping Integration ", content: "Better order tracking options and easy warehouse shipping connectivity are part of our Shopify shipping integration." },
        { title: "Reporting Integration", content: "To analyze the shopping behavior of customers, traffic details, and conversion trends, and to make quick business decisions; go for our Shopify reporting integration services." },
        { title: "Accounting Integration", content: "Turn hectic operations like managing orders, calculating tax reports, issuing invoices, etc. into stress-free processes with our best Shopify accounting integration services." },
        { title: "ERP Integration ", content: "ERP integrations allow businesses to handle various activities through one central point, like the shopping patterns of customers, etc., and make your business more efficient. " },
        { title: "CRM Integration", content: "You can manage your clients in an efficient way with ERP system integration. Offer your customers an enhanced shopping experience as well. It facilitates a live chat option, too. " },
        { title: "PIM Integration", content: "With PIM integration the product managers can breathe a sigh of relief with better product management options and can avail the unified product data for varied channels. " },
        { title: "POS Integration ", content: "Get faster business activities with POS integration services. You can increase sales through retail and social platforms by collecting information about sales, inventory, customers, " },
    ]
    const whyhire = [
        { color: "#D4E9F3", title: "Tailor-made Solutions", desc: "Because you are unique, we make sure that your needs are catered to specifically with tailored solutions." },
        { color: "#FDEDAC", title: "All Kinds of Integration", desc: "Whatever kind of integration you need for your Shopify store, we are capable of handling it all." },
        { color: "#EAD3FF", title: "Fastest time-to-market", desc: "We provide quick and high-quality services, allowing you to stay ahead of trends and not waste time on unnecessary things." },
        { color: "#CFF6A1", title: "Dedicated Project Manager", desc: "A dedicated specialist leads your project from start to finish, gathering requirements, managing developers, and taking responsibility for project delivery." },
        { color: "#FFCFDE", title: "Competitive Marketing Prices", desc: "You can avail of our different Shopify integration service packages as per your budget regulations." },
        { color: "#e2e7ff", title: "Around The Clock Availability", desc: "Irrespective of the time zones, we are available for you to handle your queries whenever you need our help." },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "Why should you choose Liquidweb Developers for Shopify integration?", desc: "<p>We have over 10 years of experience offering Shopify development and integration services to clients worldwide. Up until this point, we have successfully integrated more than 80 Shopify stores with useful 3rd party APIs that are successfully operating in the market and producing high revenue. So, your business can be the next success! </p>" },
                { title: "What is Shopify's integration service?", desc: "<p>Shopify integration offers automation of the various business processes in the eCommerce business, like tracking orders, managing data, processing payments, etc., through 3rd party applications. It helps reduce the effort and time required to perform those operations manually.</p>" },
                { title: "What is the cost of Shopify integration services?", desc: "<p>The cost is not constant for all types of Shopify integrations. It varies based on the integration needs of individual businesses. If you are interested to know  the cost for api integration with Shopify , then provide your details in the form given, and we will get back to you as soon as possible.</p>" },
            ]
        },
        {
            id: 2, list: [
                {
                    title: "What are the different types of Shopify integrations?", desc: "<ul><li>Sales Channels Integration</li><li>Loyalty Program Integration</li><li>Marketing Channel Integration</li><li>Social Media Integration</li><li>Shipping Integration</li><li>Reporting Integration</li><li>Accounting Integration</li><li>ERP Integration</li><li>CRM Integration</li><li>PIM Integration</li><li>POS Integration</li><li>Shipping Integration</li></ul>"
                }
            ]
        },
    ]
    const launchApp = [
        {
            title: "Benefits of Our Shopify Integration Services",
            paragraph: "With Shopify's third-party system integration, you can expand your business and enhance productivity for all business procedures. You can avoid wasting time on repetitive tasks by opting for Shopify integration services. So, let's explore what it brings to your table.",
            list: [
                { content: "No Chance For Human-made Errors" },
                { content: "Improved Marketing Planning Strategies" },
                { content: "Efficient Use of The Resources" },
                { content: "Better Productivity Through All Operations" },
            ]
        },
    ]

    const developmentApproach = [
        { title: "Analysis", desc: "We gain insights into your Shopify project and understand the technical requirements as well." },
        { title: "Integration", desc: "It is the time to turn your idea into reality by integrating the 3rd party applications for better management." },
        { title: "Testing", desc: "It is a crucial stage to rectify the errors and glitches in the store's performance and make it run smoothly." },
        { title: "Launch", desc: "Your fully integrated Shopify store is ready to enter the market and make the business you always wished for." },
    ]

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-api-integration" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <InnerPageBanner
                bannerClass="integration_banner"
                heading={`<span class="highlight_text">Top-notch </span> Shopify Integration <span class="home_icon"> 🏪 </span> Services`}
                subtitle="Connect with our world's best Shopify development company, USA, to streamline your business processes with our best Shopify api integration services."
                bannerImg={Index.integrationImg}
                arrow
                leftCol="col-md-6 col-lg-5"
                rightCol="col-md-6 col-lg-7"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.integrationServiceImg}
                smallImg={Index.integIcon}
                alt="Shopify integration services"
                // commonClass="app-development-listing"
                heading={`Leading <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Shopify </span> Integration Service Provider `}>
                <div className="newStoreContent__text">
                    <p>Recognized as one of the best Shopify development services agencies in the USA, you can rely on us for exceptional 3rd party API integrations, including Shopify payment gateway integration.</p>
                    <p>Our integration services enable your customers to complete complex business operations with less effort. </p>
                    <p>From sales channels to social media and shipping integration, we are all set to help you take your business ahead! Connect with our Shopify experts now!</p>
                </div>

            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Our End-to-End <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})"> Shopify Integration </span> Services </h2>`}

                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                layoutType
                list={perksData}
            />


            <OptStore
                launchApp={launchApp}
                leftImg={Index.integrationServiceImg1}
                sectionClass="greenBg"
                executeScroll={executeScroll}
                alt="api integration shopify"
            />
            <section className="process_main commonSection">
                <div className="container">
                    <div className="title_wrapper text-center">
                        <h2 className="common_heading">Empower Your Shopify <br /> Store with  <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}>Shopify Integration</span></h2>
                    </div>
                    <StepBox StepBoxCntnt={developmentApproach} />
                    <div className="text-center pt-lg-5">
                        <button onClick={executeScroll} className="common_btn">Get Free Consultation </button>
                    </div>
                </div>
            </section>
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> What <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Makes Us </span> the Best <br /> Shopify Development Agency, USA for You?</h2>
                        </div>
                        <SixBox listdata={whyhire} />
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Valued by Global <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Brands</span>`} />
            <BlueBox blueBoxRef={blueBoxRef} title="Get Advice from <br> Our Shopify Experts" subtitle="Leading Shopify Development Company" />
        </>
    )
}
