import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import ThreePortfolioBox from '../../../Common/ThreePortfolioBox/ThreePortfolioBox'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import EndToEnd from '../../../Common/EndToEnd/EndToEnd'
import { Helmet } from 'react-helmet'

export default function Migration({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Products &amp; its <br> Variants", content: "It includes titles, descriptions, images, tax, prices, inventory, etc., including subcategories of the products also. " },
        { title: "Product Attributes ", content: "All product attributes, like size, measurement units, and dimensions, are migrated under our Shopify migration services. " },
        { title: "Inventory/Pricing Rules ", content: "Updation of inventory and pricing rules within Shopify migration services without any hassle. " },
        { title: "Customer Database ", content: "Whole customer database migration along with the associated data from the existing platform to Shopify. " },
        { title: "Custom UI Design", content: "Get custom-made storefront, cart, checkout, and collection pages for your Shopify store, etc., meeting your specific needs." },
        { title: "Content Pages", content: "Lossless migration of all the pages along with the pertinent content from the old store to your brand-new Shopify store. " },
        { title: "Customer Groups", content: "Move your customers' tailor-made groups to the new Shopify store with no loss and the least effort with our Shopify experts. " },
        { title: "Downloadable Products", content: "Our migration services to Shopify include all the trending file formats for downloadable products. " },
        { title: "Product Reviews", content: "All the product reviews with relevant details embedded on the product page seamlessly moved to the newly built Shopify store. " },
        { title: "Migrate SKUs", content: "We have you covered with our Shopify migration solutions by shifting your product SKUs to your Shopify store." },
        { title: "Strip HTML Styling", content: "Removing HTML tags from each product, its categories, and descriptions to display clean text on the Shopify store." },
        { title: "Features & Functionalities", content: "Offer an enhanced user experience with customized features and functionalities on your new store migrated to Shopify under our guidance" },
    ]
    const whyhire = [
        { color: "#D4E9F3", img: Index.designIcon, title: "Certified Professionals", desc: "We have experienced and certified professionals who have successfully migrated more than 80 digital stores to Shopify without fail. So, you can rest assured that everything is in our hands." },
        { color: "#FDEDAC", img: Index.messageIcon, title: "Zero  <br/> Downtime", desc: "We ensure that your store keeps running like before on the current platform throughout the migration process. And you face zero downtime when migrating to Shopify with our help." },
        { color: "#EAD3FF", img: Index.desktopIcon, title: "Protected <br> Migration", desc: "We promise you high security during Shopify migration using the 128-bit SSL encryption, HTTPS protocol, and other robust security mechanisms to protect your data." },
        { color: "#CFF6A1", img: Index.userIcon, title: "Transparent Costing ", desc: "We follow a transparent costing approach for our full-scale Shopify migration services, our clients know the minute details behind why they pay a certain amount to us." },
        { color: "#FFCFDE", img: Index.badgeIcon, title: "On-time <br>Delivery", desc: "We focus on delivering you timely services as promised by our experts. Our Shopify migration experts meet the deadlines; never make you wait." },
        { color: "#e2e7ff", img: Index.tagIcon, title: "High Conversion Rate", desc: "Our Shopify experts after migrating your store to Shopify help you with in-built marketing tools to get high traffic and a better conversion rate." },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "Can I migrate my existing store to Shopify? ", desc: "<p>Yes, you can migrate your old store to Shopify without any data loss and with full security. All you need to do is consult an experienced Shopify migration partner like LiquidWeb Developers. </p>" },
                { title: "How long does the Shopify migration process take? ", desc: "<p>Based on the data size and products in the current store, Shopify migration time can vary. More the size of the data and high the complexities, the more time it will take during Shopify migration. For example, inventory size, CMS pages, picture size, etc.</p>" },
                { title: "Why Shopify migration is the right decision? ", desc: "<p>In the current technological era, Shopify is the most widely used sales-focused platform for operating online stores. It is packed with superb features and functionalities to deliver the outcomes you want. So, among all the platforms on the market, Shopify is the most versatile.</p> " },
                { title: "During the Shopify migration process, will my data be protected? ", desc: "<p>Our experienced team of developers performs all the tasks with zero errors and maximum security. Our Shopify migration company uses 128-bit SSL encryption and other advanced security protocols to ensure the best data safety. </p>" },


            ]
        },
        {
            id: 2, list: [
                {
                    title: "How much does it generally cost to switch a store from its current platform to Shopify? ", desc: "<p>Giving a precise cost estimate is actually challenging. The cost will be decided by your Shopify migration company after analyzing your business requirements and size. In other words, we can give you an idea that the cost will depend on the data size, APIs integration, reviews, coupons, customer base, etc. </p>"
                }
            ]
        },
    ]

    const launchApp = [
        {
            title: `<span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Benefits</span> of Top Shopify Store Migration Services in USA`,
            paragraph: "Reputable online store owners show interest in investing in Shopify store migration services and exploring the evident growth opportunities for their businesses. Undoubtedly, moving a fully established store to another platform can be overwhelming for you. However, we guarantee secure and error-free Shopify migration services in USA.",
            list: [
                { content: "Variety of fast-loading and personalized theme designs " },
                { content: "Advanced and robust security protocols for maximum protection" },
                { content: "In-built marketing tools to expand your business" },
                { content: "Facilitates multiple sales channel setups" },
            ]
        },
    ]
    const appdevServce = [
        {
            heading: "Magento to Shopify Migration ",
            icon: Index.mgntSmall,
        },
        {
            heading: "CubeCart to Shopify Migration ",
            icon: Index.cc,
        },
        {
            heading: "CubeCart to Shopify Migration ",
            icon: Index.opencart,
        },
        {
            heading: "PrestaShop to Shopify Migration ",
            icon: Index.presta,
        },
        {
            heading: "WooCommerce to <br>Shopify Migration",
            icon: Index.woo,
        },
        {
            heading: "Squarespace to Shopify Migration ",
            icon: Index.sqaure,
        },
        {
            heading: "BigCommerce to Shopify Migration ",
            icon: Index.big,
        },
        {
            heading: "Wix to Shopify Migration",
            icon: Index.wix,
        },

    ]
    // const bannerContentList = [
    //     { name: "No Downtime" },
    //     { name: "No Data Loss" },
    //     { name: "Safe Migration" },
    // ]
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-store-migration
" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <InnerPageBanner
                bannerClass="twilightBg shopify_migration imageBgSize"
                heading={`<span class="highlight_text">Are You </span>  Ready to Migrate to Shopify?  <span class="home_icon">🏪</span>`}
                subtitle="No Downtime and No Data Loss with our safe Shopify migration services. Hire our Shopify developers  now to migrate your store."
                bannerImg={Index.migrationImgBnr}
                arrow
                leftCol="col-md-6 col-lg-6"
                rightCol="col-md-6 col-lg-6"
            // listing={bannerContentList}
            />
            <GetInTouch bgColor="var(--green)" />
            <TwoColList
                largeImg={Index.migration2}
                alt="shopify migration services"
                smallImg={Index.maintanceSetting}
                // commonClass="app-development-listing"
                heading={`Shopify <span class="borderBtm" style="background-image: url(${Index.homeApproach})"> Migration </span> <br/> Services  in the USA`}>
                <div className="newStoreContent__text">
                    <p>Our experts at LiquidWeb Developers strive to serve clients worldwide looking to migrate their online stores from other platforms to Shopify. If you are also interested in the same, then you are at the right place!</p>
                    <p>We are the perfect Shopify development services agency with years of experience in Shopify development and migration services. We take pride in safely and accurately migrating the online stores of 100+ global clients.</p>
                    <h6 className="medium">Let us take care of your Shopify migration journey & stay worry-free! </h6>
                    <p></p>
                </div>
                <div className="new_store_btmimg d-flex ">
                    <img src={Index.shopifyCerPrtn} alt="" />
                    <img src={Index.plusShopify} alt="" />
                </div>
            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Our End-to-End <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Shopify Migration </span> Services</h2>`}
                // subHeading="We Go the Extra Mile to Make Your Business a Success"
                layoutType
                list={perksData}
            />
            <OptStore
                launchApp={launchApp}
                leftImg={Index.migrationServiceImg}
                btn
                btnText="Get Your Quote"
                btnWhite
                sectionClass="blueBg"
                executeScroll={executeScroll}
                alt="top shopify store migration services"
            />
            <EndToEnd
                title={`The Shopify <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Migration </span> Pairs We Support`}
                subHeading="We are committed to meeting all of the Shopify migration needs of our clients, regardless of the current platform."
                list={appdevServce}
                blueBox
            />
            <ThreePortfolioBox heading={`Hurry Up to Start Your Shopify <br> <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})">Migration Project</span> At the Right Time With Us! `} />
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> What <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Makes Us </span> the Best <br />Shopify Migration Partner for You?
                            </h2>
                        </div>
                        <SixBox listdata={whyhire} />

                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Valued by Global <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Brands </span > `} />
            <BlueBox blueBoxRef={blueBoxRef} title="Get Advice from <br> Our Shopify Experts" subtitle="Leading Shopify Development Company" />
        </>
    )
}
