import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import Slider from "react-slick";
import { Helmet } from "react-helmet";
export default function Optimization({ blueBoxRef, executeScroll, seo }) {
    const featuredClient = React.useRef(null);
    const perksData = [
        { title: "Better User <br> Experience", content: "An optimized Shopify store provides a better user experience to your customers, which reduces the bounce rate on your Shopify store." },
        { title: "Quick Loading <br>Time", content: "Shopify optimization services speed up the loading of your store, which in turn leads to increased user engagement." },
        { title: "Easy Navigation", content: "Shopify SEO saves your customers from struggling with poor store navigation. Simple navigation makes it easier to find products." },
        { title: "Uplift Brand <br> Visibility", content: "Vast visibility across different search engines leads to better brand recognition. It increases your brand's credibility among users." },
        { title: "Long term <br> Success", content: "Shopify SEO enables your brand to easily appear at the top of search results, providing you with long-term business profits." },
        { title: "Optimized <br> Product Pages", content: "Product pages with relevant keywords, HD images, and relevant descriptions boost buyers' confidence in selecting high-quality and right products. " },
    ]
    const weOfferOptim = [
        { img: Index.four, title: "Shopify Store <br> Audit", content: "We examine your digital store to know in detail about its shortcomings and craft a well-suited Shopify optimization plan to improve that area with our expertise. " },
        { img: Index.speedOptimization, title: "Speed Optimization ", content: "If you want to optimize Shopify store speed, we are qualified to provide you with a quick-loading store. Get rid of poor store loading and let your customers shop conveniently." },
        { img: Index.two, title: "Image Optimization ", content: "Shopify SEO saves your customers from struggling with poor store navigation. Simple navigation makes it easier to find products. " },
        { img: Index.keywordResearch, title: "Keyword Research ", content: "Our experts deeply analyze the high-performing keywords for your Shopify store and embed them in the content to help you rank in the top searches and attract more traffic. " },
        { img: Index.videoOptimization, title: "Video Optimization ", content: "We resolve the delayed video loading issue by swapping out the video for an image play button and loading the video when the image is clicked. " },
        { img: Index.technicalOptimization, title: "Technical Optimization", content: "We adhere to Google's top practices to drive more traffic to your digital store. We do the store audits, fix the crawling issues, and set canonical tags to make you visible over the web. " },
    ]
    const whyhire = [
        { color: "#D4E9F3", title: "Revenue-Driven Approach", desc: "We create our Shopify store optimization plans in such a way that they are focused on generating a high ROI for our clients' businesses. " },
        { color: "#FDEDAC", title: "Full-Scale Services ", desc: "By providing an effective website structure, quick loading times, optimized products, etc., we provide an unmatched Shopify optimization experience" },
        { color: "#EAD3FF", title: "In-depth Knowledge ", desc: "Deep expertise in Shopify optimization services is among our team's strengths. Over 100 clients have been served by them in the past. " },
        { color: "#CFF6A1", title: "Around the Clock Availability ", desc: "Irrespective of the time zones, we are available for you to handle your queries whenever you need our help. You can email, or chat with us." },
        { color: "#FFCFDE", title: "Customer Centric Approach ", desc: "To save our clients' time, our team members strive to deliver what they need from us by the deadline. We don't delay the outcomes. " },
        { color: "#e2e7ff", title: "Deep Store Analysis ", desc: "Our Shopify experts look over the layout of your store, determine which areas need improvement, and then strategically create a plan to get the best results. " },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "Do you conduct an analysis of the Shopify store before starting the optimization process? ", desc: "<p>Yes, our Shopify optimization experts thoroughly analyze your store and, after the valuable evaluations, prepare an effective Shopify optimization plan. </p>" },
                { title: "What do you optimize in a Shopify store? ", desc: "<p>We optimize the images, texts, URLs, user forms, buttons, features, plugins, etc. to offer you the best quality outcomes. Also, we track the record of your store's performance to further make the required enhancements. </p>" },
                { title: "What is the cost of hiring a Shopify store optimization agency? ", desc: "<p>You can get in touch with us directly and tell us about your project; based on these details and your needs, we can give you a precise estimate of how much the Shopify migration services will cost. </p>" },
                { title: "How long does the optimization process take to complete? ", desc: "<p>Typically, the entire process takes about 2 to 3 days. However, it could take up to a week, depending on the scope and complexity of the project. </p>" },
            ]
        },
        {
            id: 2, list: [
                { title: "Do you offer customer support after the optimization service? ", desc: "<p>Yes, after the completion of the project, you can contact us if you encounter any issues with the store's performance. However, in our past experience, we have never heard of such cases, as everything goes smoothly after the completion of the project as well. </p>" },
                { title: "Do project design and functionality need to be changed for Shopify optimization?", desc: "<p>Absolutely not; during the store optimization process, we don't change the design or functionality. We only work to enhance the site's navigation, performance, and content.</p>" },
            ]
        },
    ]
    const launchApp = [
        {
            title: "Shopify Store Performance Optimization ",
            paragraph: "It takes a committed effort and effective time investment from your Shopify optimization partner to retain customers in your digital Shopify store. And at LiquidWeb Developers, we have what it takes to help you outperform your rivals, from optimizing the storefront to tracking real-time statistics.",
            list: [
                { content: "Improve SEO Ranking" },
                { content: "Amplify Lead Generation" },
                { content: "More Traffic & Sales" },
                { content: "Make More Money" },
            ]
        },
    ]
    const clientSlider = {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        // centerMode: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                    autoplay: true,
                }
            }

        ]
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-store-optimization" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>

            <InnerPageBanner
                bannerClass="hintyelowBg imageBgSize shopify_optimization"
                heading={`<span class="highlight_text">Shopify </span> <span class="home_icon"> 🏪 </span> Site optimization Agency in USA`}
                subtitle="With our extraordinary Shopify store speed optimization services, you can speed up your Shopify store & reach your next revenue goals."
                bannerImg={Index.optimizationBnrryt}
                arrow
                leftCol="col-md-6 col-lg-6"
                rightCol="col-md-6 col-lg-6"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.optimait2}
                smallImg={Index.speedoMeter}
                alt="Shopify Store Optimization Services"
                // commonClass="app-development-listing"

                heading={`Shopify Store <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Optimization </span> Services`}>
                <div className="newStoreContent__text">
                    <p>Our Shopify experts examine the areas that need improvements and start optimizing your store, including product descriptions, images, store speed, setting up conversion tracking, etc., to help you appear on the top pages of search engines. </p>
                    <p>With more than 10 years of combined expertise in Shopify development services USA, we are constantly working to support our clients in achieving their business goals, such as growing their user base and increasing their revenue. </p>
                    <p>Our unconventional Shopify optimization site speed techniques holds the potential to broaden your digital reach globally. What are you waiting for? We take pride in catering to your needs as a reputed Shopify development services agency Silicon Valley, USA.</p>

                </div>
                <div className="new_store_btmimg d-flex  justify-content-between align-items-center">
                    <img src={Index.gtmtrx} alt="" />
                    <img src={Index.gpagespeed} alt="" />
                    <img src={Index.googleAnalatics} alt="" />
                </div>

            </TwoColList>

            <CountContentBox

                heading={`<h2 class="common_heading">Why <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})"> shopify site optimization </span> <br/> Services Are  Essential? </h2>`}
                subHeading="Large Traffic - More Sales - Higher ROI "
                layoutType
                list={perksData}
            />


            <OptStore
                launchApp={launchApp}
                leftImg={Index.seoMtr}
                sectionClass="greenBg"
                btn
                btnText="Get Your Quote Now"
                btnWhite
                alt="Shopify Store Performance Optimization"
                executeScroll={executeScroll}
            />
            <CountContentBox

                heading={`<h2 class="common_heading"><span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})"> Services </span> We Offer <br/> For Shopify Optimization  </h2>`}
                // subHeading="Large Traffic - More Sales - Higher ROI "
                icon
                layoutType
                list={weOfferOptim}
            />


            <section className="services_main commonSection pt-0 developmentSquadSlider ">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> We're a development squad focused <br /> on
                                <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}>Shopify optimization</span>
                            </h2>
                        </div>
                        <div className="counter_inner d-flex justify-content-center align-items-center counter_optimz">
                            <div className="counter_box">
                                <div className="counter">92%</div>
                                <p>Performance</p>
                            </div>
                            <div className="counter_box">
                                <div className="counter">114ms</div>
                                <p>Blocking Time</p>
                            </div>
                            <div className="counter_box">
                                <div className="counter">O.05</div>
                                <p>Layout Shift </p>
                            </div>
                        </div>

                        {/* <!-- slider --> */}

                        <Slider ref={featuredClient} {...clientSlider} className="optimz_slider">
                            <div>
                                <div className="optimz_sliderHldr">
                                    <div className="optimz_sliderBox">
                                        <img src={Index.optimizedSliderBig} alt="" />
                                    </div>
                                    <div className="optimz_sliderBoxsmall">
                                        <img src={Index.optimizedSliderSmall} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="optimz_sliderHldr">
                                    <div className="optimz_sliderBox">
                                        <img src={Index.optimizedPro2} alt="" />
                                    </div>
                                    <div className="optimz_sliderBoxsmall">
                                        <img src={Index.optimizedPro3} alt="" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>


            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> Why  <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Choose Us </span> as Your <br /> Shopify Store Optimization Company? </h2>
                        </div>
                        <SixBox listdata={whyhire} />
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Valued by Global <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Brands</span> `} />
            <BlueBox blueBoxRef={blueBoxRef} title="Reach us for Shopify Optimization" subtitle="Grab Our Cost-effective Services " />
        </>
    )
}
