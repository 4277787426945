import React from 'react'
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
export default function StepBox({ StepBoxCntnt }) {
    return (
        <>
        <Reveal effect="fadeInUp">
            <ul className="progressbar">
                <li className="active"></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </Reveal>
            <div className="row">
                {StepBoxCntnt.map((data, indx) => {
                    let count = indx + 1
                    return (
                        <Fade up key={indx} delay={indx*100}>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3" >
                                <div className="process_item outer_glow text-center wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <div className="process_number outer_glow">{count > 9 ? count : "0" + count}</div>
                                    <h4 className="process_title">{data.title}</h4>
                                    <p>{data.desc}</p>
                                </div>
                            </div>
                        </Fade>
                    )
                })}
            </div>
        </>
    )
}
