export default [
    {
        slug: "truly",
        name: "Truly",
        title: "Truly is the unique combination of health, sustainability & performance.",
        heading: "The leader in transformative body care",
        detail: "Truly is the unique combination of health, sustainability & performance. We discover the top beauty trends, secrets, remedies and unfound ingredients from around the world to create the most complete hair, body & skincare products. ",
        year: "2020",
        client: "Maxx Appleman",
        category: "beauty",
        logo: require("./truly/logo.webp"),
        business: "Beauty Products",
        deliverable: "Design & Development",
        link: "https://www.trulybeauty.com/",
        platform: "Shopify + Ruby",
        contract: "Full Time",
        banner: require("./truly/1.webp"),
        pcBanner: require("./truly/2.webp"),
        thumbnail: require("./truly/thumbnail.webp"),
        metaTitle: "Portfolio | Truly",
        metaDescription: "Unlock e-commerce success with #1 Shopify Development Services by Liquidweb Developers! Boost your online store's potential today!",
        mobileBanner: [
            { img: require("./truly/3.webp") },
            { img: require("./truly/4.webp") },
            { img: require("./truly/5.webp") },
        ],
        images: {
            top: [
                { img: require("./truly/6.webp") },
                { img: require("./truly/7.webp") },
            ],
            big: require("./truly/8.webp"),
            middle: [
                { img: require("./truly/9.webp") },
                { img: require("./truly/10.webp") },
            ],
            column: [
                { img: require("./truly/11.webp") },
                { img: require("./truly/12.webp") },
            ],
        }

    },
    {   
        slug: "amino",
        name: "Amino",
        title: "Amino develop life-changing products that are safe and effective for everyone",
        heading: "Amino is the worldwide <br/> leader in researching amino acid technology and developing patented amino aci",
        detail: "Amino Company products are built on amino acid technology first funded by NASA and further refined through rigorous research and independent clinical trials.",
        year: "2021",
        client: "Steve",
        category: "health",
        logo: require("./aminoco/logo.webp"),
        business: "Healthcare Products",
        deliverable: "Design & Development",
        link: "https://aminoco.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Full Time",
        banner: require("./aminoco/1.webp"),
        pcBanner: require("./aminoco/2.webp"),
        thumbnail: require("./aminoco/thumbnail.webp"),
        metaTitle: "Portfolio | Amino",
        metaDescription: "Dive into our portfolio to discover the innovative Amino brand and its exceptional digital experiences.",
        mobileBanner: [
            { img: require("./aminoco/3.webp") },
            { img: require("./aminoco/4.webp") },
            { img: require("./aminoco/5.webp") },
        ],
        images: {
            top: [
                { img: require("./aminoco/6.webp") },
                { img: require("./aminoco/7.webp") },
            ],
            big: require("./aminoco/8.webp"),
            middle: [
                { img: require("./aminoco/9.webp") },
                { img: require("./aminoco/10.webp") },
            ],
            column: [
                { img: require("./aminoco/11.webp") },
                { img: require("./aminoco/12.webp") },
            ],
        }
    },
    {
        slug: "sandandsky",
        name: "Sand & Sky",
        title: "Aussie girls invented A-Beauty. Now let them reinvent your skin.",
        heading: "Bringing A-Beauty to <br/> the world",
        detail: "Embrace our uniquely Aussie approach to skincare. It's simple. Based on clean and highly effective formulas, we skip the 10-step routines and crazy claims. ‘Cause we’d rather be at the beach than stuck in the bathroom",
        year: "2022",
        client: "Rex",
        category: "beauty",
        logo: require("./sandandsky/logo.webp"),
        business: "Beauty Products",
        deliverable: "UI UX  & Development",
        link: "https://int.sandandsky.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Fixed Width",
        banner: require("./sandandsky/1.webp"),
        pcBanner: require("./sandandsky/2.webp"),
        thumbnail: require("./sandandsky/thumbnail.webp"),
        metaTitle: "Portfolio | Sandandsky",
        metaDescription: "Unveil our portfolio to witness the mesmerizing Sandandsky brand and its stunning online platform.",
        mobileBanner: [
            { img: require("./sandandsky/3.webp") },
            { img: require("./sandandsky/4.webp") },
            { img: require("./sandandsky/5.webp") },
        ],
        images: {
            top: [
                { img: require("./sandandsky/6.webp") },
                { img: require("./sandandsky/7.webp") },
            ],
            big: require("./sandandsky/8.webp"),
            middle: [
                { img: require("./sandandsky/9.webp") },
            ],
            column: [
                { img: require("./sandandsky/10.webp") },
                { img: require("./sandandsky/11.webp") },
            ],
        }
    },
    {
        slug: "ritani",
        name: "Ritani",
        title: "Our pieces are one-of-a-kind and made to order in New York by expert artisans.",
        heading: "The leader in <br/> Diamond jewellery",
        detail: "We love love itself. And we want you to find jewelry to fall in love with. Finding the right engagement ring or wedding band can be stressful and maybe even a little scary, especially online. We’re here to help.",
        year: "2021",
        client: "Sonam K",
        category: "beauty",
        logo: require("./ritani/logo.webp"),
        business: "Jewellery Products",
        deliverable: "UI UX  & Development",
        link: "https://www.ritani.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Fixed Width",
        banner: require("./ritani/1.webp"),
        pcBanner: require("./ritani/2.webp"),
        thumbnail: require("./ritani/thumbnail.webp"),
        metaTitle: "Portfolio | Ritani",
        metaDescription: "Discover our portfolio featuring Ritani's exquisite jewelry collections and seamless e-commerce journey.",
        mobileBanner: [
            { img: require("./ritani/3.webp") },
            { img: require("./ritani/4.webp") },
            { img: require("./ritani/5.webp") },
        ],
        images: {
            top: [
                { img: require("./ritani/6.webp") },
                { img: require("./ritani/7.webp") },
            ],
            big: require("./ritani/8.webp"),
            middle: [
                { img: require("./ritani/9.webp") },
            ],
            column: [
                { img: require("./ritani/10.webp") },
                { img: require("./ritani/11.webp") },
            ],
        }
    },
    {
        slug: "beginningboutique",
        name: "Beginning Boutique",
        title: "Our pieces are one-of-a-kind and made to order in New York by expert artisans.",
        heading: "The leader in <br/> Women Clothing",
        detail: "We love love itself. And we want you to find jewelry to fall in love with. Finding the right engagement ring or wedding band can be stressful and maybe even a little scary, especially online. We’re here to help.",
        year: "2019",
        client: "Dima K.",
        category: "textile",
        logo: require("./beginningboutique/logo.webp"),
        business: "Clothing Products",
        deliverable: "UI UX  & Development",
        link: "https://www.beginningboutique.com.au/",
        platform: "HTML/CSS + Shopify",
        contract: "Full Time",
        banner: require("./beginningboutique/1.webp"),
        pcBanner: require("./beginningboutique/2.webp"),
        thumbnail: require("./beginningboutique/thumbnail.webp"),
        metaTitle: "Portfolio | Beginning Boutique",
        metaDescription: "Step into our portfolio to explore Beginning Boutique's dynamic fashion brand and engaging online storefront.",
        mobileBanner: [
            { img: require("./beginningboutique/3.webp") },
            { img: require("./beginningboutique/4.webp") },
            { img: require("./beginningboutique/5.webp") },
        ],
        images: {
            top: [
                { img: require("./beginningboutique/6.webp") },
                { img: require("./beginningboutique/7.webp") },
            ],
            big: require("./beginningboutique/8.webp"),
            middle: [
                { img: require("./beginningboutique/9.webp") }, 
            ],
            column: [
                { img: require("./beginningboutique/10.webp") },
                { img: require("./beginningboutique/11.webp") },
            ],
        }
    },
    {
        slug: "loungewearrepublic",
        name: "Loungewear Republic",
        title: "Deliver stylish yet comfortable, high-quality clothing to fierce, well-dressed ladies.",
        heading: "A mission of beauty and<br/> Sustainability",
        detail: "Brown and RISD educated industrial designer. Founder of Portal Project, a collective of artists and engineers creating art that ignites conversations around environmental issues, modern-day consumption, and current events ",
        year: "2022",
        client: "Laura D",
        category: "textile",
        logo: require("./loungewearrepublic/logo.webp"),
        business: "Loungewear Products",
        deliverable: "Design & Development",
        link: "https://loungewearrepublic.com.au/",
        platform: "HTML/CSS + Shopify",
        contract: "Full Time",
        banner: require("./loungewearrepublic/1.webp"),
        pcBanner: require("./loungewearrepublic/2.webp"),
        thumbnail: require("./loungewearrepublic/thumbnail.webp"),
        metaTitle: "Portfolio | Lounge Wearre Public",
        metaDescription: "Browse through our portfolio to experience Loungewear Republic's cozy fashion line and sleek online presence.",
        mobileBanner: [
            { img: require("./loungewearrepublic/3.webp") },
            { img: require("./loungewearrepublic/4.webp") },
            { img: require("./loungewearrepublic/5.webp") },
        ],
        images: {
            top: [
                { img: require("./loungewearrepublic/6.webp") }, 
            ],
            big: require("./loungewearrepublic/7.webp"),
            middle: [
                { img: require("./loungewearrepublic/8.webp") }, 
            ],
            column: [
                { img: require("./loungewearrepublic/9.webp") },
                { img: require("./loungewearrepublic/10.webp") },
            ],
        }
    },
    {
        slug: "sukiso",
        name: "Sukiso",
        title: "Obsessed with beauty, empowerment and sustainability",
        heading: "A mission of beauty and <br/> Sustainability",
        detail: "Brown and RISD educated industrial designer. Founder of Portal Project, a collective of artists and engineers creating art that ignites conversations around environmental issues, modern-day consumption, and current events ",
        year: "2023",
        client: "Suki S.",
        category: "textile",
        logo: require("./sukiso/logo.webp"),
        business: "Clothing Products",
        deliverable: "Design & Development",
        link: "https://www.sukiso.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Hourly Based",
        banner: require("./sukiso/1.webp"),
        pcBanner: require("./sukiso/2.webp"),
        thumbnail: require("./sukiso/thumbnail.webp"),
        metaTitle: "Portfolio | Sukiso",
        metaDescription: "Delve into our portfolio to see Sukiso's elegant lifestyle products and stylish e-commerce platform.",
        mobileBanner: [
            { img: require("./sukiso/3.webp") },
            { img: require("./sukiso/4.webp") },
            { img: require("./sukiso/5.webp") },
        ],
        images: {
            top: [
                { img: require("./sukiso/6.webp") },
            ],
            big: require("./sukiso/7.webp"),
            middle: [
                { img: require("./sukiso/8.webp") },
            ],
            column: [
                { img: require("./sukiso/9.webp") },
                { img: require("./sukiso/10.webp") },
            ],
        }
    },
    {
        slug: "neuro",
        name: "Neuro",
        title: "Functional gum and mints formulated to refresh your state of mind.",
        heading: "Enhancing body <br/> and mind, a piece at a time",
        detail: "Truly is the unique combination of health, sustainability & performance. We discover the top beauty trends, secrets, remedies and unfound ingredients from around the world to create the most complete hair, body & skincare products.",
        year: "2019",
        client: "Kent Ryan",
        category: "health",
        logo: require("./neuro/logo.webp"),
        business: "Healthcare Products",
        deliverable: "Design & Development",
        link: "https://getneuro.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Hourly Based",
        banner: require("./neuro/1.webp"),
        pcBanner: require("./neuro/2.webp"),
        thumbnail: require("./neuro/thumbnail.webp"),
        metaTitle: "Portfolio | Neuro",
        metaDescription: "Experience our portfolio highlighting Neuro's cutting-edge supplements and user-friendly online store.",
        mobileBanner: [
            { img: require("./neuro/3.webp") },
            { img: require("./neuro/4.webp") },
            { img: require("./neuro/5.webp") },
        ],
        images: {
            top: [
                { img: require("./neuro/6.webp") },
                { img: require("./neuro/7.webp") },
            ],
            big: require("./neuro/8.webp"),
            middle: [
                { img: require("./neuro/9.webp") },
                { img: require("./neuro/10.webp") },
            ],
            column: [
                { img: require("./neuro/11.webp") },
                { img: require("./neuro/12.webp") },
            ],
        }
    },
    {
        slug: "luna",
        name: "Luna",
        title: "Deep, restful sleep is absolutely essential to overall health and quality of life.",
        heading: "Deep Sleep Is All We Do",
        detail: "Deep, restful sleep is absolutely essential to overall health and quality of life. At Luna, that's all we do—keep our focus on how to optimize rest for a population that is generally sleep-deprived and thus feeling the symptoms.",
        year: "2018",
        client: "James A",
        category: "textile",
        logo: require("./luna/logo.webp"),
        business: "Textile Products",
        deliverable: "UI UX  & Development",
        link: "https://www.lunablanket.com/",
        platform: "HTML/PHP + Shopify",
        contract: "Fixed Width",
        banner: require("./luna/1.webp"),
        pcBanner: require("./luna/2.webp"),
        thumbnail: require("./luna/thumbnail.webp"),
        metaTitle: "Portfolio | Luna",
        metaDescription: "Be inspired by our portfolio showcasing Luna's ethereal beauty products and captivating online experience.",
        mobileBanner: [
            { img: require("./luna/3.webp") },
            { img: require("./luna/4.webp") },
            { img: require("./luna/5.webp") },
        ],
        images: {
            top: [
                { img: require("./luna/6.webp") },
                { img: require("./luna/7.webp") },
            ],
            big: require("./luna/8.webp"),
            middle: [
                { img: require("./luna/9.webp") },
            ],
            column: [
                { img: require("./luna/10.webp") },
                { img: require("./luna/11.webp") },
            ],
        }
    },
    {
        slug: "ivycityco",
        name: "ivy city co",
        title: "Empowering a community with confidence through dresses for all ages and stages",
        heading: "Ivy City Co",
        detail: "We love love itself. And we want you to find jewelry to fall in love with. Finding the right engagement ring or wedding band can be stressful and maybe even a little scary, especially online. We’re here to help.",
        year: "2021",
        client: "Marina L",
        category: "textile",
        logo: require("./ivycityco/logo.webp"),
        business: "Clothing Products",
        deliverable: "Design & Development ",
        link: "https://ivycityco.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Full Time",
        banner: require("./ivycityco/1.webp"),
        pcBanner: require("./ivycityco/2.webp"),
        thumbnail: require("./ivycityco/thumbnail.webp"),
        metaTitle: "Portfolio | Ivycityco",
        metaDescription: "Explore our portfolio unveiling Ivycityco's chic clothing line and sophisticated e-commerce platform.",
        mobileBanner: [
            { img: require("./ivycityco/3.webp") },
            { img: require("./ivycityco/4.webp") },
            { img: require("./ivycityco/5.webp") },
        ],
        images: {
            top: [
                { img: require("./ivycityco/6.webp") },
                { img: require("./ivycityco/7.webp") },
            ],
            big: require("./ivycityco/8.webp"),
            middle: [
                { img: require("./ivycityco/9.webp") },
            ],
            column: [
                { img: require("./ivycityco/10.webp") },
                { img: require("./ivycityco/11.webp") },
            ],
        }
    },
    {
        slug: "tiem",
        name: "Tiem",
        title: "Footwear for the modern athlete. Performance cycling redefined",
        heading: "Inspiring Motio",
        detail: "The idea for TIEM grew out of twenty years of experience in the shoe industry, combined with a lifetime of passion for fitness and cycling, informed by a love of style and quality.",
        year: "2022",
        client: "Thomas A",
        category: "sports",
        logo: require("./tiemathletic/logo.webp"),
        business: "Gym Related Products",
        deliverable: "Design & Development",
        link: "https://www.tiemathletic.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Fixed Width",
        banner: require("./tiemathletic/1.webp"),
        pcBanner: require("./tiemathletic/2.webp"),
        thumbnail: require("./tiemathletic/thumbnail.webp"),
        metaTitle: "Portfolio | Tiem",
        metaDescription: "Journey through our portfolio to discover Tiem's trendy footwear and seamless online shopping experience.",
        mobileBanner: [
            { img: require("./tiemathletic/3.webp") },
            { img: require("./tiemathletic/4.webp") },
            { img: require("./tiemathletic/5.webp") },
        ],
        images: {
            top: [
                { img: require("./tiemathletic/6.webp") },
                { img: require("./tiemathletic/7.webp") },
            ],
            big: require("./tiemathletic/8.webp"),
            middle: [
                { img: require("./tiemathletic/9.webp") },
            ],
            column: [
                { img: require("./tiemathletic/10.webp") },
                { img: require("./tiemathletic/11.webp") },
            ],
        }
    },
    {
        slug: "gymshark",
        name: "Gymshark",
        title: "We're Gymshark. We exist to unite the conditioning* community.",
        heading: "The leader in <br/> Women Clothing",
        detail: "We love love itself. And we want you to find jewelry to fall in love with. Finding the right engagement ring or wedding band can be stressful and maybe even a little scary, especially online. We’re here to help.",
        year: "2021",
        client: "Noel M",
        category: "sports",
        logo: require("./gymshark/logo.webp"),
        business: "Gym Related Products",
        deliverable: "Design & Development",
        link: "https://eu.shop.gymshark.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Fixed Width",
        banner: require("./gymshark/1.webp"),
        pcBanner: require("./gymshark/2.webp"),
        thumbnail: require("./gymshark/thumbnail.webp"),
        metaTitle: "Portfolio | Gym Shark",
        metaDescription: "Witness our portfolio featuring Gym Shark's bold activewear and empowering e-commerce platform.",
        mobileBanner: [
            { img: require("./gymshark/3.webp") },
            { img: require("./gymshark/4.webp") },
            { img: require("./gymshark/5.webp") },
        ],
        images: {
            top: [
                { img: require("./gymshark/6.webp") }, 
            ],
            big: require("./gymshark/7.webp"),
            middle: [
                { img: require("./gymshark/8.webp") }, 
            ],
            column: [
                { img: require("./gymshark/9.webp") },
                { img: require("./gymshark/10.webp") },
            ],
        }
    },

    {
        slug: "Peak-performance",
        name: "Peak Performance",
        title: "Peak Performance is committed to the highest standards and all products",
        heading: "The leader in <br> Organic Products ",
        detail: "Peak Performance is committed to the highest standards and this product is 3rd party tested to ensure it is 100% safe for you",
        year: "2018",
        client: "Tarek",
        category: "health",
        logo: require("./peakperformance/logo.webp"),
        business: "Healthcare Products",
        deliverable: "Design & Development",
        link: "https://buypeakperformance.com/",
        platform: "HTML/CSS + Shopify",
        contract: "Hourly Based",
        banner: require("./peakperformance/1.webp"),
        pcBanner: require("./peakperformance/2.webp"),
        thumbnail: require("./peakperformance/thumbnail.webp"),
        metaTitle: "Portfolio | Peak Performance",
        metaDescription: "Explore our portfolio showcasing Peak Performance's high-performance outdoor gear and immersive online experience.",
        mobileBanner: [
            { img: require("./peakperformance/3.webp") },
            { img: require("./peakperformance/4.webp") },
            { img: require("./peakperformance/5.webp") },
        ],
        images: {
            top: [
                { img: require("./peakperformance/6.webp") },
                { img: require("./peakperformance/7.webp") },
            ],
            big: require("./peakperformance/8.webp"),
            middle: [
                { img: require("./peakperformance/9.webp") },
            ],
            column: [
                { img: require("./peakperformance/10.webp") },
                { img: require("./peakperformance/11.webp") },
            ],
        }
    },
]