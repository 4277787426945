import React, { useState } from 'react'
import { Fade } from 'react-reveal';
export default function GetInTouch({ bgColor }) {
    const [alerts, setAlerts] = useState({
        show: false,
        type: "success",
        msg: ""
    })
    const [value, setvalue] = useState({
        name: "",
        email: "",
        phone: "",
    })
    const handleChange = (e) => {
        setvalue({ ...value, [e.target.name]: e.target.value })
    }

    const [errors, setErrors] = useState(false)
    const [btnLoader, setbtnLoader] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        setbtnLoader(true)
        if (value.name == "" || value.email == "") {
            setbtnLoader(false)
            setErrors(true)
        } else {
            window.Email.send({
                Host: "smtp.elasticemail.com",
                Port: "2525",
                Username: "bsboby@gmail.com",
                Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
                From: "bsboby@gmail.com",
                To: "support@psd2html5.co",
                Subject: "Liquid web developers | Consultation Request Received",
                Body: `<p>Name: ${value?.name}</p>
            <p>Email: ${value?.email}</p>
            <p>Skype ID or phone number: ${value?.phone || "NA"}</p>
            `,
            }).then((res) => {
                if (res == "OK") {
                    setAlerts({ show: true, type: "success", msg: "Email sent successfully" })
                    setvalue({
                        name: "",
                        email: "",
                        phone: "",
                        budget: "",
                        brief: "",
                    })
                    setErrors(false)
                    setbtnLoader(false)
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Port: "2525",
                        Username: "bsboby@gmail.com",
                        Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
                        From: "bsboby@gmail.com",
                        To: value?.email,
                        Subject: "Thanks for reaching us!",
                        Body: `<p>Thanks for reaching us!</p>
                        <p>One of our technical experts will connect with you soon.</p>`,
                    }).then();
                    setTimeout(() => {
                        setAlerts({ show: false, type: "success", msg: "" })
                    }, 3000);
                } else {
                    setAlerts({ show: true, type: "error", msg: "Email not sent." })
                }
            });
        }
    }


    return (
        <section className="horizontalform greenBg service_bnrbtm position-relative" style={{ backgroundColor: bgColor ? bgColor : "#2d6dd6" }}>
            <div className="buble_box circle white_buble "></div>
            <div className="container position-relative">
                <div className="row">
                    <div className="col-12">
                        <Fade up>
                            <h3 className="extrabold white_clr pb-2 pb-md-3 pb-lg-4"> Get In Touch</h3>
                        </Fade>
                    </div>
                </div>
                <form action="#">
                    <Fade up>
                        <div className="startShopifyStore__form row">

                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="form-group">
                                    <input type="text" className={`form-control ${errors && value.name == "" ? "error" : ""}`} placeholder="Name*" name='name' value={value.name} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="form-group">
                                    <input type="email" className={`form-control ${errors && value.email == "" ? "error" : ""}`} placeholder="Email*" name='email' value={value.email} onChange={(e) => handleChange(e)} aria-describedby="emailHelp" />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Skype ID or Phone No." name='phone' value={value.phone} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-3 offset-md-4 offset-lg-0">
                                {btnLoader ?
                                    <button className="common_btn common_btnWhite">Sending...</button>
                                    :
                                    <button className="common_btn common_btnWhite" onClick={sendEmail}>{alerts.show ? alerts.msg : "Submit"}</button>
                                }
                            </div>
                        </div>
                    </Fade>
                </form>
                <div className="buble_box circle white_buble small_buble "></div>
                <div className="buble_box circle white_buble right_buble"></div>
            </div>
        </section>
    )
}
