import React from 'react'
import Index from '../../../Assets/images/Index'
import { Fade } from 'react-reveal'
export default function Faq({ faqData }) {
    return (
        <section className="faq_main commonSection">
            <div className="container">
                <div className="row justify-content-end no-gutters">
                    <div className="col-lg-11">
                        <div className="title_wrapper mb-md-5">
                            <Fade up>    <h3 className="common_heading">Frequently Asked <br /> <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeFaq})` }}>Questions</span></h3></Fade>
                        </div>
                        <div className="faq_inner">
                            <div className="row">

                                {faqData.map((data, idx) => {
                                    return (
                                        <Fade up key={idx} delay={idx * 100}>
                                            <div className="col-md-6" >
                                                {data?.list.map((content, index) => {
                                                    return (
                                                        <Fade up key={index} delay={index * 100}>
                                                            <div className="faq_card wow fadeInUp">
                                                                <h5 className="faq_title">{content.title}</h5>
                                                                <div className="faq_body" dangerouslySetInnerHTML={{ __html: content.desc }} >
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    )
                                                })}
                                                {idx == 1 ?
                                                    <div className="faqCardImage wow fadeInRight mobileHide" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                                        <img src={Index.laptopWomen} alt="shopify development companies" />
                                                    </div> : null}
                                            </div>
                                        </Fade>
                                    )
                                })}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
