import React from 'react'
import Index from '../../../Assets/images/Index'
import { Fade } from 'react-reveal'

export default function ShopifyThemesInclude() {
    const themeInclude = [
        {
            id: 1, list: [
                { title: "Brooklyn" },
                { title: "Debut" },
                { title: "Boundless" },
                { title: "Minimalas" },
                { title: "Spark" },
                { title: "Fresh" },
                { title: "Cascade" },
            ]
        },
        {
            id: 2, list: [
                { title: "Express", },
                { title: "Narrative", },
                { title: "Beyond", },
                { title: "Foodie", },
                { title: "Boost", },
                { title: "Split", },
                { title: "Handy", },
            ]
        },
    ]
    return (
        <section className="opt_store commonSection dream_relity blueBg ">
            <div className="container ">
                <div className="row ">
                    <div className="col-md-4 col-lg-4 ">
                        <div className="new_store_content common_content ">
                            <Fade up>
                                <h3 className="common_heading white_clr ">Customized <br /> Shopify Themes Include</h3>
                            </Fade>
                        </div>
                    </div>
                    {themeInclude.map((data, index) => {
                        return (
                            <div key={index} className="col-md-4 col-lg-4 ">
                                <div className="new_store_content common_content ">
                                    <div className="cardarrow_listing pt-0 ">
                                        <Fade up>
                                            <ul>
                                                {data.list.map((cntnt, ind) => {
                                                    return (

                                                        <li key={ind}>
                                                            <span className="cardlisting__img ">
                                                                <img src={Index.whiteArrow} alt=">" />
                                                            </span>
                                                            <span className="cardlisting__cntnt ">{cntnt.title}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
