import React, { useState } from 'react'
import Index from '../../../Assets/images/Index'
import Fade from 'react-reveal/Fade';
export default function BlueBox({ title, subtitle, blueBoxRef }) {
    const [alerts, setAlerts] = useState({
        show: false,
        type: "success",
        msg: ""
    })
    const [value, setvalue] = useState({
        name: "",
        email: "",
        phone: "",
        budget: "",
        brief: "",
    })
    const handleChange = (e) => {
        setvalue({ ...value, [e.target.name]: e.target.value })
    }

    const budget = [
        { label: "$0 to $1,000", value: "$0 to $1,000" },
        { label: "$1,000 to $10,000", value: "$1,000 to $10,000" },
        { label: "$10,000 to $50,000", value: "$10,000 to $50,000" },
        { label: "No idea", value: "No idea" },
    ]
    const [errors, setErrors] = useState(false)
    const [btnLoader, setbtnLoader] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        setbtnLoader(true)
        if (value.name == "" || value.email == "") {
            setbtnLoader(false)
            setErrors(true)
        } else {
            window.Email.send({
                Host: "smtp.elasticemail.com",
                Port: "2525",
                Username: "bsboby@gmail.com",
                Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
                From: "bsboby@gmail.com",
                To: "support@psd2html5.co",
                Subject: "Liquid web developers | Consultation Request Received",
                Body: `<p>Name: ${value?.name}</p>
            <p>Email: ${value?.email}</p>
            <p>Skype ID or phone number: ${value?.phone || "NA"}</p>
            <p>Budget: ${value?.budget || "NA"}</p>
            <p>Project brief: ${value?.brief || "NA"}</p>
            `,
            }).then((res) => {
                if (res == "OK") {
                    setAlerts({ show: true, type: "success", msg: "Email sent successfully" })
                    setvalue({
                        name: "",
                        email: "",
                        phone: "",
                        budget: "",
                        brief: "",
                    })
                    setErrors(false)
                    setbtnLoader(false)
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Port: "2525",
                        Username: "bsboby@gmail.com",
                        Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
                        From: "bsboby@gmail.com",
                        To: value?.email,
                        Subject: "Thanks for reaching us!",
                        Body: `<p>Thanks for reaching us!</p>
                        <p>One of our technical experts will connect with you soon.</p>`,
                    }).then();
                    setTimeout(() => {
                        setAlerts({ show: false, type: "success", msg: "" })
                    }, 3000);
                } else {
                    setAlerts({ show: true, type: "error", msg: "Email not sent." })
                }
            });
        }
    }

    return (

        <section className="blueContactBox" id="getstarted" ref={blueBoxRef}>
            <div className="container">
                <div className="startShopifyStore">
                    <div className="row no-gutters justify-content-between">
                        <Fade up>
                            <div className="col-md-6 white_clr">
                                <div className="startShopifyStore__cntnt">
                                    <h2 className="common_heading mb-2" ><span dangerouslySetInnerHTML={{ __html: title }}></span>  </h2>
                                    {subtitle ? <h5 className="h5 regular">{subtitle}</h5> : null}
                                    <div className="mt-3 d-flex align-items-center startstoreBoy">
                                        <div className="col-auto p-0 ">
                                            <img src={Index.startstoreBoy} alt='shopify developer' />
                                        </div>
                                        <div className="col-auto p-0 ml-2">
                                            <img src={Index.curveArrowWhite} alt='arrow'  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-md-6">
                            <Fade up>
                                <div>
                                    <div className="startShopifyStore__form row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className={`form-control ${errors && value.name == "" ? "error" : ""}`} placeholder="Name*" name='name' value={value.name} onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" className={`form-control ${errors && value.email == "" ? "error" : ""}`} placeholder="Email*" name='email' value={value.email} onChange={(e) => handleChange(e)} aria-describedby="emailHelp" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Skype ID or Phone No." name='phone' value={value.phone} onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <select className="form-control " name='budget' onChange={(e) => handleChange(e)} >
                                                    <option defaultValue>Budget</option>
                                                    {budget.map((opt, i) => {
                                                        return (
                                                            <option key={i} value={opt?.value}>{opt?.label}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea id="umassage" rows="3" className="form-control" placeholder="Project Brief"
                                                    name='brief' value={value.brief} onChange={(e) => handleChange(e)} ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {btnLoader ?
                                                <button className="common_btn common_btnWhite">Sending...</button>
                                                :
                                                <button className="common_btn common_btnWhite" onClick={sendEmail}>{alerts.show ? alerts.msg : "Lets Get Started"}</button>
                                            }
                                            {/* {alerts.show &&
                                            <div className='position-relative'>
                                                <p className={`msg_alert ${alerts?.type == "error" ? "error" : "success"}`}>{alerts?.msg}</p>
                                            </div>
                                        } */}
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
