import React from 'react'
import Index from '../../../Assets/images/Index' 
export default function InnerPageBanner({ heading, subtitle, bannerImg, bannerClass, arrow, arrowCurve, leftCol, rightCol, listing, alt }) {
  return (
    <section className={`banner_main banner_spacing ${bannerClass ? bannerClass : ""}`}>
      <div className="container">
        <div className="banner_content_wrap row d-flex justify-content-between align-items-center">
          <div className={leftCol}>
            <div className="banner_content__boxLeft wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
              <div className="banner_content">
                <h1 className="banner_title" dangerouslySetInnerHTML={{ __html: heading }}></h1>
                <div className="service_bannertxt">
                  {subtitle ? <h5 className="banner_sub-title" dangerouslySetInnerHTML={{ __html: subtitle }}></h5> : null}
                  {listing ?
                    <ul className="banner_sub--list">
                      {listing.map((data, indx) => {
                        return (
                          <li key={indx}>{data.name}</li>
                        )
                      })}
                    </ul> : null}
                  {arrow ? <img src={Index.curveArrow1} className={arrowCurve ? "curve_arrow curve_arrow_float" : "curve_arrow_float"} alt="arrow" /> : null}
                </div>

              </div>
            </div>
          </div>
          <div className={`${rightCol} mobileHide`}>
            <div className="banner_content__boxRight wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1000ms">
              <img src={bannerImg} alt={"best shopify development services" }/>
            
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
