import React from 'react'
import { Fade, Slide } from 'react-reveal';
export default function TwoColList(props) {
    return (
        <section className={`commonSection pb-0  ${props.commonClass ? props.commonClass : ""}`}>
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 col-lg-6">
                        <div className="store_devService store_devServiceOutr wow fadeInUp " >
                            <Fade up>
                                <img src={props.largeImg} alt={props.alt}/>
                            </Fade>
                            <Fade >
                                <div className="store_devCartimg white_bg circle outer_glow d-flex align-items-center justify-content-center">
                                    <img src={props.smallImg} alt="" />
                                </div>
                            </Fade >
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="new_store_content common_content wow fadeInUp " >
                            <Fade up>
                                <h3 className="common_heading" dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                            </Fade>
                            <Fade up> {props.children} </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
