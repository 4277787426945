export default {
    logo: require("./main-logo.svg").default,
    close: require("./close.svg").default,

    // home page
    curveArrow1: require("./curve-arrow.webp"),
    bannerImg1: require("./banner-img-1.webp"),
    bannerImg2: require("./banner-img-2.webp"),
    bannerImg3: require("./banner-img-3.webp"),
    bannerImg4: require("./banner-img-4.webp"),
    shopifyPlusIcon: require("./shopify-plus.webp"),
    shopifyPlus: require("./shopifyPlus.webp"),
    enterpriseImg: require("./enterprise-img.webp"),
    homeOffer: require("./homeOffer.webp"),
    homePotential: require("./homePotential.webp"),
    laptopMen: require("./laptop-men.webp"),
    homeApproach: require("./homeApproach.webp"),
    homeLiquidWebDeveloper: require("./homeLiquidWebDeveloper.webp"),
    designIcon: require("./design-icon.svg").default,
    messageIcon: require("./message-icon.svg").default,
    desktopIcon: require("./desktop-icon.svg").default,
    userIcon: require("./user-icon.svg").default,
    badgeIcon: require("./badge-icon.svg").default,
    tagIcon: require("./tag-icon.svg").default,
    aminocoScreen: require("./portfolio/aminoco/1.webp"),
    sand3: require("./portfolio/sandandsky/1.webp"),
    luna3: require("./portfolio/luna/1.webp"),
    tiem3: require("./portfolio/tiemathletic/1.webp"),
    playBtn: require("./play-btn.svg").default,
    pauseBtn: require("./pause-btn.svg").default,
    thumb1: require("./thumb-1.webp"),
    thumb2: require("./thumb-2.webp"),
    thumb3: require("./thumb-3.webp"),
    thumb4: require("./thumb-4.webp"),
    thumb5: require("./thumb-5.webp"),
    videoPoster: require("./video_poster.webp"),
    optImg: require("./opt-img.webp"),
    serviceStrore1: require("./service-strore-1.webp"),
    serviceStrore2: require("./service-strore-2.webp"),
    star: require("./star.webp"),
    chicLogo: require("./chicLogo.webp"), 
    homeFaq: require("./homeFaq.webp"),
    laptopWomen: require("./laptop-women.webp"),
    startstoreBoy: require("./startstoreBoy.webp"),
    curveArrowWhite: require("./curve-arrowWhite.webp"),
    storeImg: require("./store-img.svg").default,
    migrationImg: require("./migration-img.svg").default,
    optmImg: require("./optm-img.svg").default,
    customImg: require("./custom-img.svg").default,
    marketingImg: require("./marketing-img.svg").default,
    testimonialLogo: require("./testimonial-logo.webp"),

    // about
    aboutPageBanner: require("./about-page-banner.webp"),
    certifiedBadge: require("./certified-badge.webp"),
    shopifyCertifiedPartner: require("./shopify-certified-partner.webp"),
    shopifyPlusBlack: require("./shopify-plus-black.webp"),
    shopifyPlusPartnerBlack: require("./shopify-plus-partner-black.webp"),
    serviceBg: require("./service-bg.webp"),
    userDesign: require("./user-design.svg").default,
    customerOriented: require("./customer-oriented.svg").default,
    quickLaunch: require("./quick-launch.svg").default,
    multiChannel: require("./multi-channel.svg").default,
    aboutShopify: require("./aboutShopify.webp"),
    teamBgVector: require("./team-bg-vector.webp"),
    ceoImg: require("./CEO-img.webp"),
    cooImg: require("./COO-img.webp"),
    ctoImg: require("./CTO-img.webp"),
    teamImg: require("./team-img.webp"),
    logoIcon: require("./logo-icon.svg").default,

    // servicepage
    serviceBannerImg: require("./service-banner-img.svg").default,
    serviceShopify: require("./serviceShopify.webp"),
    optimization: require("./optimization.svg").default,
    integration: require("./integration.svg").default,
    maintenance: require("./maintenance.svg").default,
    dressIcon: require("./dress-icon.svg").default,
    capIcon: require("./cap-icon.svg").default,
    logisticIcon: require("./logistic-icon.svg").default,
    retailIcon: require("./retail-icon.svg").default,
    sportsIcon: require("./sports-icon.svg").default,
    airoplaneIcon: require("./airoplane.svg").default,
    etertainmentIcon: require("./etertainment-icon.svg").default,
    foodIcon: require("./food_icon.svg").default,
    healthIcon: require("./health-icon.svg").default,

    // app development
    appDevelopmentBanner: require("./app-development-banner.svg").default,
    appDevelopmentLineBg: require("./app-development-line-bg.webp"),
    Mobile: require("./Mobile.svg").default,
    appDevelopment1: require("./app-development-1.webp"),
    appDevelopment2: require("./app-development-2.webp"),
    whiteArrow: require("./white-arrow.svg").default,
    end1: require("./end-1.svg").default,
    end5: require("./end-5.svg").default,
    customMarketing: require("./custom-marketing.svg").default,
    customerManagement: require("./customer-management.webp"),
    erp: require("./erp.svg").default,
    devCartIcn: require("./dev-cart-icn.svg").default,

    // customization    
    shopifyCustomTree: require("./shopify-custom-tree.svg").default,
    shopifyCustomizeImg1: require("./shopify-customize-img1.webp"),
    shopifyCutomizeCart: require("./shopify-cutomize-cart.svg").default,
    butyTheme: require("./buty-theme.webp"),
    one: require("./1.svg").default,
    two: require("./2.svg").default,
    three: require("./3.svg").default,
    four: require("./4.svg").default,
    five: require("./5.svg").default,
    six: require("./6.svg").default,
    seven: require("./7.svg").default,
    eight: require("./8.svg").default,
    nine: require("./9.svg").default,
    ten: require("./10.svg").default,
    eleven: require("./11.svg").default,

    //migration
    migrationImgBnr: require("./migration-img-bnr.svg").default,
    maintanceSetting: require("./maintance--setting.svg").default,
    migration2: require("./migration-2.webp"),
    shopifyCerPrtn: require("./shopify-cer-prtn.webp"),
    plusShopify: require("./plus-shopify.webp"),
    migrationServiceImg: require("./migration-service-img.webp"),
    shopifyBag: require("./shopify-bag.webp"),
    mgntSmall: require("./mgnt-small.webp"),
    cc: require("./cc.webp"),
    opencart: require("./opencart.webp"),
    presta: require("./presta.webp"),
    woo: require("./woo.webp"),
    sqaure: require("./sqaure.webp"),
    big: require("./big.webp"),
    wix: require("./wixx.webp"),

    // store
    servicesBnrImg: require("./services-bnr-img.svg").default,
    optShopify: require("./opt-shopify.webp"),
    end2: require("./end-2.svg").default,
    end3: require("./end-3.svg").default,
    end5: require("./end-5.svg").default,
    end6: require("./end-6.svg").default,
    end9: require("./end-9.svg").default,
    end10: require("./end-10.svg").default,
    end11: require("./end-11.svg").default,

    feture1: require("./feture-1.svg").default,
    feture2: require("./feture-2.svg").default,
    feture3: require("./feture-3.svg").default,
    feture4: require("./feture-4.svg").default,
    feture5: require("./feture-5.svg").default,
    feture6: require("./feture-6.svg").default,

    rita3: require("./portfolio/ritani/1.webp"),
    truly3: require("./portfolio/truly/1.webp"),
    agencyImg: require("./agency-img.webp"),
    peopleIcon: require("./people-icon.svg").default,

    // integration
    integrationImg: require("./integration-img.svg").default,
    integrationServiceImg: require("./integration-service-img.webp"),
    integrationServiceImg1: require("./integration-service-img1.webp"),
    integIcon: require("./integ-icon.svg").default,

    // maintainance
    maintanceBanner: require("./maintance-banner.svg").default,
    intmaintanceStngBnrgrationImg: require("./maintance-stng-bnr.webp"),
    maintence3: require("./maintence-3.webp"),

    // internet-marketing
    internetMarketingBanner: require("./internet-marketing-banner.svg").default,
    internetShop1: require("./internet-shop-1.webp"),
    internetMrktn2: require("./internet-mrktn-2.webp"),
    seo: require("./seo.svg").default,

    // optimization
    optimizationBnrryt: require("./optimization-bnrryt.svg").default,
    gtmtrx: require("./gtmtrx.svg").default,
    speedoMeter: require("./speedo-meter.svg").default,
    gpagespeed: require("./gpagespeed.webp"),
    optimait2: require("./optimait2.webp"),
    googleAnalatics: require("./google-analatics.webp"),
    seoMtr: require("./seo-mtr.webp"),
    speedOptimization: require("./speed-optimization.svg").default,
    keywordResearch: require("./keyword-research.svg").default,
    videoOptimization: require("./video-optimization.svg").default,
    technicalOptimization: require("./technical-optimization.svg").default,
    optimizedSliderBig: require("./optimized-slider-big.webp"),
    optimizedSliderSmall: require("./optimized-slider-small.webp"),
    optimizedPro2: require("./optimized-pro-2.webp"),
    optimizedPro3: require("./optimized-pro-3.webp"),

    // portfolio
    arrowDown: require("./portfolio/arrow-down.webp"),
    desktopFrame: require("./portfolio/desktop-frame.webp"),
    desktopstand: require("./portfolio/desktop-stand.webp"),
    mobileFrame: require("./portfolio/mibile-frame.webp"),

    // contact
    skypeSvgrepo: require("./skype-svgrepo-com.svg").default,
    whitecall1: require("./whitecall1.svg").default,
    blackBackClosedEnvelopeShape: require("./black-back-closed-envelope-shape-svgrepo-com.svg").default,

    contactBannerImg: require("./contact-banner-img.svg").default,

    //  career
    careerBanner: require("./career-banner.svg").default,
    galleryImg1: require("./gallery-img1.webp"),
    galleryImg2: require("./gallery-img2.webp"),
    galleryImg3: require("./gallery-img3.webp"),
    galleryImg4: require("./gallery-img4.webp"),
    life: require("./@life.webp"),
    teamMember: require("./team-member.webp"),
    handIcon: require("./hand-icon.svg").default,
    adaptabilityIcon: require("./adaptability-icon.svg").default,
    communicationIcon: require("./communication-icon.svg").default,
    technicalOptimization: require("./technical-optimization.svg").default,
    commnityIcon: require("./commnity-icon.svg").default,
    growthIcon: require("./growth-icon.svg").default,
    
    // blog

    shopifyVsMagento: require("./blog/shopifyVsMagento/banner.jpg")
}
