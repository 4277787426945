import React, { useEffect, useRef, useState } from 'react'
import Index from '../../../Assets/images/Index'
import { Link } from 'react-router-dom'
import BlueBox from '../../Common/BlueBox/BlueBox';
import TrustedByBrands from '../../Common/TrustedByBrands/TrustedByBrands';
import GetInTouch from '../../Common/GetInTouch/GetInTouch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import Masonry from 'react-masonry-css'
import { Fade, Slide } from 'react-reveal';
import { Helmet } from "react-helmet";

export default function About({ blueBoxRef, executeScroll, seo }) {
    const coreValue = [
        { count: "02", name: 'Enhanced Speed & Performance', content: "Our Shopify site speed optimization results in a higher conversion rate. " },
        { count: "01", name: 'High Quality & Standards', content: "We stick to the BEM methodology when designing front-end user interfaces and follow advanced optimization techniques." },
        { count: "03", name: 'Responsive Theme Designs', content: "Our Shopify developers follow the mobile-first design approach to offer faster loading, which eventually increases the conversion rate." },
        { count: "04", name: 'Smooth Browsing ', content: "Our Shopify development agency in California, USA builds stores that enhance customer engagement by providing frictionless navigation." },
    ]
    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        767: 1,
        500: 1
    };
 
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/about" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <section className="banner_main aboutBanner">
                <div className="container-fluid">
                    <div className="banner_content_wrap row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="banner_content__boxLeft aboutBanner_content wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                <div className="banner_content">
                                    <h1 className="banner_title">
                                        <span className="highlight_text">Top-rated Shopify</span> Development Agency <span className="home_icon"> 🏪</span>
                                    </h1>
                                    <h5 className="banner_sub-title">Upscale your online eCommerce business with Our Shopify development agency in California, USA. Build a high revenue-generating e-commerce store with the assistance of our Shopify Experts!</h5>
                                    <div className="customer_count d-flex align-items-center">
                                        <span className="h6">🥇 Hire Custom Shopify Development Agency Now!</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 mobileHide">
                            <div className="aboutBanner_image wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1000ms">

                                <img src={Index.aboutPageBanner} alt="build website with shopify" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* Banner End */}
            <section className="projectDelivered commonSection">
                <div className="container">
                    <div className="projectDelivered_inner">
                        <Fade up>
                            <div className="counter_inner d-flex align-items-center">
                                <div className="counter_box">
                                    <div className="counter">5K+</div>
                                    <p>Project Delivered</p>
                                </div>
                                <div className="counter_box">
                                    <div className="counter">47</div>
                                    <p>Team Members</p>
                                </div>
                                <div className="counter_box">
                                    <div className="counter">345</div>
                                    <p>Store Migration</p>
                                </div>
                                <div className="counter_box">
                                    <div className="counter">4.9</div>
                                    <p>Review Ratings</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <section className="commonSection pt-0 certifiedPartner">
                <div className="container">
                    <div className="certified_wrap d-flex align-items-end position-relative">
                        <Fade up>
                            <div className="certified_column-left wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                <div className="full_height partners_inner white_bg outer_glow position-relative">
                                    <div className="pattern_outer ">
                                        <div className="vector_layer" style={{ backgroundImage: `url(${Index.serviceBg})` }}
                                        ></div>
                                    </div>
                                    <div className=" full_height d-flex flex-column align-items-center justify-content-center">
                                        <div className="certified_logos">
                                            <div className="certified_logo">
                                                <img src={Index.shopifyCertifiedPartner} alt="" />
                                            </div>
                                            <div className="certified_logo">
                                                <img src={Index.shopifyPlusBlack} alt="" />
                                            </div>
                                            <div className="certified_logo">
                                                <img src={Index.shopifyPlusPartnerBlack} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade up>
                            <div className="certified_column-right wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                <div className="certified_column-inner white_bg outer_glow">
                                    <div className="certified_content">
                                        <div className="certified_head pb-4">
                                            <h3 className="title extrabold">Certified Shopify Store Development Company</h3>
                                            <img src={Index.certifiedBadge} alt="" />
                                        </div>
                                        <p className="font18"> LiquidWeb Developers is among the Top Shopify Development Companies in the USA and is recognized worldwide. Our Shopify developers mainly focus on these factors:  </p>
                                        <div className="card_listing">
                                            <ul>
                                                <li>
                                                    <span className="cardlisting__img">
                                                        <img src={Index.userDesign} />
                                                    </span>
                                                    <span className="cardlisting__cntnt">User-Centric Designs</span>
                                                </li>
                                                <li>
                                                    <span className="cardlisting__img">
                                                        <img src={Index.customerOriented} />
                                                    </span>
                                                    <span className="cardlisting__cntnt">Customer-oriented Approach</span>
                                                </li>
                                                <li>
                                                    <span className="cardlisting__img">
                                                        <img src={Index.quickLaunch} />
                                                    </span>
                                                    <span className="cardlisting__cntnt">Quick to launch Solution</span>
                                                </li>
                                                <li>
                                                    <span className="cardlisting__img">
                                                        <img src={Index.multiChannel} />
                                                    </span>
                                                    <span className="cardlisting__cntnt">Multi-channel Marketing Strategies</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <GetInTouch bgColor="var(--green)" />
            <section className="about_us_section commonSection">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="about_us_content wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                <Fade up>
                                    <h3 className="extrabold pb-4">Core <span className="borderBtm"
                                        style={{ backgroundImage: `url(${Index.homeOffer})` }}>
                                        Values</span> Of Our Shopify Development Agency</h3>
                                </Fade>
                                <Fade up>
                                    <p className="font18">Our skilled Shopify developers provide you with scalable, secure, easy-to-use, and highly responsive Shopify store development solutions at affordable costs.</p>
                                </Fade>
                                <Fade up>
                                    <p className="font18">To skyrocket your business's growth digitally, team up with our diligent experts.</p>
                                </Fade>
                                <Fade up>
                                    <div className="aboutlisting">
                                        <ul className="list-tyle-none">
                                            <li>
                                                <span className="aboutlisting__img">
                                                    🔮
                                                </span>
                                                <span className="aboutlisting__cntnt">
                                                    Full creative control for developing personalized stores.
                                                </span>
                                            </li>
                                            <li>
                                                <span className="aboutlisting__img">
                                                    👨‍💻
                                                </span>
                                                <span className="aboutlisting__cntnt">
                                                    Use of react-based frameworks for robust custom store development.
                                                </span>
                                            </li>
                                            <li>
                                                <span className="aboutlisting__img">
                                                    ⌛
                                                </span>
                                                <span className="aboutlisting__cntnt">
                                                    Highly automated workflow for complex functionalities.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </Fade>
                                <Fade up>
                                    <button onClick={executeScroll} className="common_btn">Lets Get Started</button>
                                </Fade>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="aboutCard_grid">
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {coreValue?.map((data, indx) => {
                                        return (
                                            <Fade up key={indx}>
                                                <div className="aboutCard_item wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                                    <div className="aboutCard_inner white_bg">
                                                        <div className="font18 blue_clr extrabold">{data?.count} </div>
                                                        <h5 className="extrabold py-3">{data.name}</h5>
                                                        <p className="font18">{data.content}</p>
                                                    </div>
                                                </div>
                                            </Fade>
                                        )
                                    })}
                                </Masonry>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ourTeam_section commonSection pt-0">
                <div className="teamLead_wrapper">
                    <div className="container">
                        <Fade up>
                            <h3 className="extrabold text-center">Our Dedicated <span className="borderBtm"
                                // style="background-image: url('./assets/images/aboutShopify.png')"
                                style={{ backgroundImage: `url(${Index.aboutShopify})` }}
                            > Shopify</span> Experts <span className="heading_icon">
                                    💙
                                </span>
                            </h3>
                        </Fade>
                        <div className="teamLead_wrap">
                            <Fade up>
                                <div className="teamLead_item  ">
                                    <div className="teamLead_cntnt">
                                        <h3 className="extrabold pb-3">The Leadership</h3>
                                        <img src={Index.logoIcon} alt="" />
                                    </div>
                                </div>
                            </Fade>
                            <Fade up>
                                <div className="teamLead_item wow fadeInDown" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <div className="teamLeader ceoFounder">
                                        <div className="teamLeader_img">
                                            <img src={Index.ceoImg} alt="" />
                                        </div>
                                        <div className="teamLeader_info">
                                            <h6>Baljit S</h6>
                                            <p>CEO/Founder</p>
                                        </div>
                                        <div className="teamLeader_overlay"></div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade up>
                                <div className="teamLead_item wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <div className="teamLeader coo">
                                        <div className="teamLeader_img">
                                            <img src={Index.cooImg} alt="" />
                                        </div>
                                        <div className="teamLeader_info">
                                            <h6>Ranjit K</h6>
                                            <p>COO</p>
                                        </div>
                                        <div className="teamLeader_overlay"></div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade up>
                                <div className="teamLead_item wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <div className="teamLeader cto">
                                        <div className="teamLeader_img">
                                            <img src={Index.ctoImg} alt="" />
                                        </div>
                                        <div className="teamLeader_info">
                                            <h6>Harman S</h6>
                                            <p>CTO</p>
                                        </div>
                                        <div className="teamLeader_overlay"></div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="pattern_outer">
                        <div className="vector_layer"
                            // style="background-image: url(./assets/images/team-bg-vector.png);"
                            style={{ backgroundImage: `url(${Index.teamBgVector})` }}
                        ></div>
                    </div>
                </div>
                <div className="full_team">
                    <Slide bottom>
                        <img src={Index.teamImg} className="full_width" alt="" />
                    </Slide >
                </div>
                <div className="message_wrapper">
                    <div className="container">
                        <div className="message_wrap">
                            <div className="ceo_message d-flex justify-content-between">
                                <Fade up>
                                    <div className="ceo_profile  wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                        <div className="ceo_img">
                                            <img src={Index.ceoImg} alt="" />
                                        </div>
                                        <h5 className="text-center">CEO Message</h5>
                                    </div>
                                </Fade>
                                <div className="ceo_quates white_bg outer_glow position-relative wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <Fade up> <div className="head pb-4 d-flex align-items-center">
                                        <h3 className="extrabold">Welcome to <br /> Liquid Web Developers </h3>
                                        <img src={Index.logoIcon} alt="" />
                                    </div>
                                    </Fade>
                                    <Fade up>
                                        <p>We aim to empower our clients' businesses through our result-oriented and innovative development approach. Our professionals thrive in unlocking the golden opportunities in the online world for all kinds of businesses;
                                            from start-ups to big enterprises. At Liquidweb Developers, we put collaborative efforts toward our company’s success. And this is the reason we are recognized as the leading Shopify development company globally. </p>
                                    </Fade>
                                    <Fade up>
                                        <h5 className="extrabold pt-4">-Baljit Singh</h5>
                                    </Fade>
                                    <Fade up>
                                        <div className="share_message d-flex align-items-center">



                                            <a target='_blank' href="https://www.linkedin.com/in/bsboby/"> <FontAwesomeIcon icon={faLinkedinIn} /> Linkedin </a>
                                            {/* <a href="#"> <FontAwesomeIcon icon={faTwitter} /> Twitter </a>
                                        <a href="#"> <FontAwesomeIcon icon={faInstagram} /> Instagram </a> */}
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <TrustedByBrands title={`Trusted by 70+ <span class="borderBtm" style="background-image:  url(${Index.homeOffer})" >Brands </span>`} />

            <BlueBox blueBoxRef={blueBoxRef} title="Ready to Level  <br /> Up Your Business?"
                subtitle="Fill in the form to get started!"
            />

        </>
    )
}
