import React, { useEffect, useState } from 'react'
import Index from '../../Assets/images/Index'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faLinkedinIn, faPinterestP, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import BreadCrums from '../Common/BreadCrums/BreadCrums'
export default function Footer() {
    const [show, setshow] = useState(false)
    const [inputVal, setinputVal] = useState("")
    const nav = [
        { name: "Home", link: "/", },
        { name: "About Us", link: "/about", },
        { name: "Services", link: "/services", },
        { name: "Work", link: "/portfolio", },
        // { name: "Careers", link: "/career", },
        { name: "Hire Shopify Experts", link: "/contact", },
    ]
    const footerPP = [
        { name: "Terms of Use", link: "/terms", },
        { name: "Privacy Policy", link: "/privacy-policy", },

    ]
    const shopifyLinks = [
        { name: "Shopify Store Development", link: "/services/shopify-store-development", },
        { name: "Shopify Theme Customization", link: "/services/shopify-theme-customization", },
        { name: "Shopify Custom App Development", link: "/services/shopify-app-development", },
        { name: "Shopify Theme Migration", link: "/services/shopify-store-migration", },
        { name: "Shopify Theme Optimization", link: "/services/shopify-store-optimization", },
    ]

    const social = [
        { name: "Twitter", icon: faTwitter, link: "https://twitter.com/LiquidWebD37010", },
        { name: "Linkedin", icon: faLinkedinIn, link: "https://www.linkedin.com/in/liquid-web-developers-560732281/", },
        { name: "Facebook", icon: faFacebookSquare, link: "https://www.facebook.com/profile.php?id=100094279053227", },
        { name: "Instagram", icon: faInstagram, link: "https://www.instagram.com/liquidwebdevelopers/", },
        // { name: "Youtube", icon: faYoutube, link: "/", },
    ]

    const [currentPath, setcurrentPath] = useState("")
    const location = useLocation()

    useEffect(() => {
        setcurrentPath(location.pathname.split("/"))

    }, [location.pathname])

    const toggleWhatsApp = () => {
        setshow(!show)
    }
    const openWhatsApp = (e) => {
        e.preventDefault()
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(`https://api.whatsapp.com/send?phone=+919988405441&text=${inputVal}`, "_blank")
        } else {
            window.open(`https://web.whatsapp.com/send?phone=+919988405441&text=${inputVal}`, "_blank")
        }
    }

    return (
        <>
            <footer className="footer">
                <div className="footerTop">
                    <div className="container">
                        <BreadCrums currentPath={currentPath} />
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-3">
                                <div className="footer_logo">
                                    <Link to="/">
                                        <img src={Index.logo} alt="" width="190" />
                                    </Link>
                                </div>
                                <h5 className="footer_title common_click pt-3 ">Follow Us</h5>
                                <div className="social_media-links pt-0">

                                    {social.map((data, indx) => {
                                        return (
                                            <Link key={indx} to={data.link} className="link" target="_blank" title={data.name}>  <FontAwesomeIcon icon={data.icon} /></Link>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="footer_content footer_common">
                                    <h5 className="footer_title common_click">Quick Links</h5>
                                    <ul className="footer_dropdown">
                                        {nav.map((list, ind) => {
                                            return (
                                                <li key={ind}>
                                                    <Link to={list.link}>{list.name}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="footer_content footer_common">
                                    <h5 className="footer_title common_click">Shopify Services</h5>
                                    <ul className="footer_dropdown">
                                        {shopifyLinks.map((list, ind) => {
                                            return (
                                                <li key={ind}>
                                                    <Link to={list.link}>{list.name}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <div className="footer_content footer_common">
                                    <h5 className="footer_title common_click">Contact</h5>
                                    <div className="footer_dropdown">
                                        <div className="contact">
                                            {/* <ul>
                                                <li><a href="tel:+17147952324">+1 714 795 2324 </a></li>
                                                <li><a href="mailto:hello@liquidwebdevelopers.com">hello@liquidwebdevelopers.com</a>
                                                </li>
                                            </ul> */}
                                            <ul className="footer_contact-info">
                                                {/* <li><a href="tel:17147952324" className="d-flex">
                                                    <div className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                                        </svg>
                                                    </div> +1 714 795 2324</a>
                                                </li> */}

                                                <li>
                                                    <a href="mailto:hello@liquidwebdevelopers.com" className="d-flex"><div className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"></path></svg></div>  hello@liquidwebdevelopers.com</a>
                                                </li>

                                                <li className="d-flex"><div className="mr-2 pt-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                                                </div>
                                                    <p> 113-03 101st avenue, Richmond Hill, NY 11419, United States</p>
                                                    </li>

                                                <li className="d-flex"><div className="mr-2 pt-1"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> </div> <p>
                                                        B-918, 9th Floor, TOWER-B, Bestech Business Tower, India
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerBtm">
                    <div className="container">
                        <div className="footerBtm__cntnt">© Copyrights {new Date().getFullYear()}&nbsp; All Rights Reserved
                            <ul>
                                {footerPP.map((list, ind) => {
                                    return (
                                        <li key={ind}>
                                            <Link to={list.link}>{list.name}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="footerBtm__logo">
                            <img src={Index.logo} alt="" width="190" />
                        </div>
                    </div>
                </div>
            </footer>

            <div className="whatsAppAll">
                {show ?
                    <div className="whatsAppBallon">
                        <span id="kapatac" className="kapat" onClick={toggleWhatsApp}>X</span>

                        <div className="whatsAppTitle">

                        </div>

                        <div className="whatsAppMessage">
                            <div className="csaas-whatsapp-message">How can I help you? :)</div>
                        </div>

                        <div className="WhatsAppInput">
                            <form onSubmit={openWhatsApp} >
                                {/* <input type="text" name="phone" value="+919988405441" hidden /> */}
                                <input type="text" name="text" placeholder="" autoComplete="off" value={inputVal} onChange={(e) => setinputVal(e.target.value)} />
                                <button className="whatsAppSendBtn" type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="#ffffff" d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path></svg>
                                </button>
                            </form>
                        </div>
                    </div> :
                    null}


                <div id="ackapa" className="whatsAppBtn-outer" onClick={toggleWhatsApp}>
                    <span className="chsaas-svg"><svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle className="color-element" cx="19.4395" cy="19.4395" r="19.4395" fill="#49E670"></circle>
                        <path d="M12.9821 10.1115C12.7029 10.7767 11.5862 11.442 10.7486 11.575C10.1902 11.7081 9.35269 11.8411 6.84003 10.7767C3.48981 9.44628 1.39593 6.25317 1.25634 6.12012C1.11674 5.85403 2.13001e-06 4.39053 2.13001e-06 2.92702C2.13001e-06 1.46351 0.83755 0.665231 1.11673 0.399139C1.39592 0.133046 1.8147 1.01506e-06 2.23348 1.01506e-06C2.37307 1.01506e-06 2.51267 1.01506e-06 2.65226 1.01506e-06C2.93144 1.01506e-06 3.21063 -2.02219e-06 3.35022 0.532183C3.62941 1.19741 4.32736 2.66092 4.32736 2.79397C4.46696 2.92702 4.46696 3.19311 4.32736 3.32616C4.18777 3.59225 4.18777 3.59224 3.90858 3.85834C3.76899 3.99138 3.6294 4.12443 3.48981 4.39052C3.35022 4.52357 3.21063 4.78966 3.35022 5.05576C3.48981 5.32185 4.18777 6.38622 5.16491 7.18449C6.42125 8.24886 7.39839 8.51496 7.81717 8.78105C8.09636 8.91409 8.37554 8.9141 8.65472 8.648C8.93391 8.38191 9.21309 7.98277 9.49228 7.58363C9.77146 7.31754 10.0507 7.1845 10.3298 7.31754C10.609 7.45059 12.2841 8.11582 12.5633 8.38191C12.8425 8.51496 13.1217 8.648 13.1217 8.78105C13.1217 8.78105 13.1217 9.44628 12.9821 10.1115Z" transform="translate(12.9597 12.9597)" fill="#FAFAFA"></path>
                        <path d="M0.196998 23.295L0.131434 23.4862L0.323216 23.4223L5.52771 21.6875C7.4273 22.8471 9.47325 23.4274 11.6637 23.4274C18.134 23.4274 23.4274 18.134 23.4274 11.6637C23.4274 5.19344 18.134 -0.1 11.6637 -0.1C5.19344 -0.1 -0.1 5.19344 -0.1 11.6637C-0.1 13.9996 0.624492 16.3352 1.93021 18.2398L0.196998 23.295ZM5.87658 19.8847L5.84025 19.8665L5.80154 19.8788L2.78138 20.8398L3.73978 17.9646L3.75932 17.906L3.71562 17.8623L3.43104 17.5777C2.27704 15.8437 1.55796 13.8245 1.55796 11.6637C1.55796 6.03288 6.03288 1.55796 11.6637 1.55796C17.2945 1.55796 21.7695 6.03288 21.7695 11.6637C21.7695 17.2945 17.2945 21.7695 11.6637 21.7695C9.64222 21.7695 7.76778 21.1921 6.18227 20.039L6.17557 20.0342L6.16817 20.0305L5.87658 19.8847Z" transform="translate(7.7758 7.77582)" fill="white" stroke="white" strokeWidth="0.2"></path></svg></span>
                </div>
            </div>
        </>
    )
}
