import React from 'react'
import productList from '../../../Assets/images/portfolio/Projects'
import _ from "underscore"
import Index from '../../../Assets/images/Index'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
export default function ThreePortfolioBox({ heading }) {
    return (
        <section className="portfolio_section commonSection pb-0">
            <div className="container">
                <div className="heading_section text-center">
                    <Fade up> <h3 className="extrabold pb-2" dangerouslySetInnerHTML={{ __html: heading }}></h3></Fade>
                </div>
            </div>
            <div className="themedevfilter_content " id="ourHolder">

                {_.shuffle(productList).slice(0, 3).map((data, index) => {
                    return (
                        <Fade up key={index}>
                            <div className="col-sm-6 col-md-4 col-lg-4 beauty item">
                                <div className="themedevfilter_box outer_glow">
                                    <Link to={`/portfolio/${data.slug}`}>
                                        <img src={data.pcBanner} alt={data.name}/>
                                    </Link>
                                </div>
                            </div>
                        </Fade>
                    )
                })}


            </div>
            <div className="text-center pt-3 pt-md-3 pt-lg-5">
                <Link to="/portfolio" className="common_btn">View Portfolio</Link>
            </div>
        </section>
    )
}
