import React from 'react'
import Index from '../../../Assets/images/Index'
import InnerPageBanner from '../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../Common/CountContentBox/CountContentBox'
import { Link } from 'react-router-dom'
import Faq from '../../Common/Faq/Faq'
import BlueBox from '../../Common/BlueBox/BlueBox'
import ThreePortfolioBox from '../../Common/ThreePortfolioBox/ThreePortfolioBox'
import { Fade } from 'react-reveal'
import { Helmet } from "react-helmet";

export default function Services({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "3D Product Visualization", content: "Your customers can view the 3D visualization of the products to better understand the product leading to a strong grip over the customers and driving high sales." },
        { title: "Secure Payment Gateways", content: "Get Customized payment processing for your online store. Our shopify agency,USA integrate transparent and easy-to-understand payment partners to ensure your customers' data security. " },
        { title: "Advanced SEO Tools", content: "We smartly and systematically use the SEO & marketing in-built tools provided by Shopify to bring maximum effectiveness with minimum effort as per your concerns." },
        { title: "Efficient Hosting", content: "Get a hassle-free PCI-compliant hosting experience to securely and effectively manage your files and data in the cloud.No need to set up onsite servers separately. Our shopify development services, USA offer you everything." },
    ]
    const weDeliver = [
        { link: "/services/shopify-store-development", color: "var(--blue)", image: Index.storeImg, title: "Shopify Store Development", content: "Our Shopify store design services let you express your business through carefully designed visuals, including font styles, color schemes, and image quality to build your brand.", },
        { link: "/services/shopify-theme-customization", color: "var(--green)", image: Index.migrationImg, title: "Shopify <br /> Store Customization", content: "Our Shopify Experts have a vast knowledge of Liquid, HTML, CSS, and JavaScript to deliver fast-loading stores for diverse business verticals.", },
        { link: "/services/shopify-store-migration", color: "var(--pink_dark)", image: Index.optmImg, title: "Shopify <br /> Store Migration", content: "Moving a fully grown store from one platform to another can be really challenging. However, we safely import your data to Shopify without any loss.", },
        { link: "/services/shopify-app-development", color: "var(--orange)", image: Index.customImg, title: "Shopify <br /> App Development", content: "Launch your highly performant Shopify app backed by our diligent Shopify app development services to satisfy your customer needs.", },
        { link: "/services/shopify-store-optimization", color: "var(--blueCyan)", image: Index.optimization, title: "Shopify Store Optimization ", content: "Our Shopify web builders enrich your online store for better online visibility and improved customer experience with advanced Shopify store optimization techniques to give it a quick boost.", },
        { link: "/services/shopify-internet-marketing", color: "var(--purple)", image: Index.marketingImg, title: "Shopify Internet Marketing", content: "Set marketing campaigns and promotions for your Shopify store with the assistance of our professionals to reach a wider audience and upsurge your sales numbers.", },
        { link: "/services/shopify-api-integration", color: "var(--dirtyblue)", image: Index.integration, title: "Shopify API Integration", content: "Enter the market with a fully integrated Shopify store including ERP, CRM, and social media channels integration to deliver an unmatchable shopping experience.", },
        { link: "/services/shopify-store-maintenance", color: "var(--greenCyan)", image: Index.maintenance, title: "Shopify Store Maintenance ", content: "Utilize our Shopify maintenance services to keep your online Shopify store operating flawlessly. We regularly monitor your store for better performance.", },
    ]
    const industryRange = [
        { name: "Fashion & Clothing", icon: Index.dressIcon },
        { name: "Education & eLearning", icon: Index.capIcon },
        { name: "Logistics", icon: Index.logisticIcon },
        { name: "Retail & eCommerce", icon: Index.retailIcon },
        { name: "Sports & Equipment", icon: Index.sportsIcon },
        { name: "Travel & Tourism", icon: Index.airoplaneIcon },
        { name: "Entertainment & Media", icon: Index.etertainmentIcon },
        { name: "Food & Beverages", icon: Index.foodIcon },
        { name: "Health & Fitnes", icon: Index.healthIcon },
    ]

    const faqData = [
        {
            id: 1, list: [
                { title: "Why are Shopify development services the best option for launching an online store? ", desc: "<p>Shopify is not only for crafting an enticing storefront but also has a set of built-in tools for optimizing titles/product descriptions, and marketing at a reasonable cost. Also, third-party system applications are possible to integrate effortlessly.</p>" },
                { title: "What sets you apart from other Shopify development companies? ", desc: "<p>Our Shopify experts follow a customer-oriented and transparent approach in the Shopify development industry. We have carefully selected skilled and experienced professionals to join us. So, don't worry about the deadlines and work quality. </p>" },
                { title: "Who will communicate with the clients about project updates?", desc: "<p>We will assign you a Shopify project manager to discuss/update the details of the project under development. Also, you can connect with the senior developers if needed. </p> " },
                { title: "Why should you hire Shopify experts?", desc: "<p>Hiring Shopify experts allows you to unleash the potential of all the Shopify development services for your store, as experts know what works best for you. And you can spare time to focus on other factors to build your brand. </p>" },


            ]
        },
        {
            id: 2, list: [
                {
                    title: "How will your Shopify developers start the project?", desc: "<p>To develop a store from scratch, tell us your idea, and we will make it technically feasible. If you want to redesign your already-built Shopify store, we look for areas for improvement and give you expert suggestions. </p><p>Our marketing professionals make efforts to drive traffic to your online store/website.</p>"
                }
            ]
        },
    ]

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <InnerPageBanner
                bannerClass="service_banner"
                heading={`<span className="highlight_text">Launch</span> Your <span className="home_icon"> 🛠️ </span> Shopify Store With Us`}
                subtitle="We at LiquidWeb Developers believe in a result-driven approach that empowers your brand to create a long-lasting impression on your customers. Our top shopify development agency is trusted by big names."
                bannerImg={Index.serviceBannerImg}
                leftCol="col-md-6"
                rightCol="col-md-6"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <CountContentBox
                heading={`<h3 class="extrabold pb-2">Perks of Choosing Our <br /> <span class="borderBtm" style="background-image: url(${Index.serviceShopify})"> Shopify Development</span> Services, USA</h3>`}
                subHeading="We Go the Extra Mile to Make Your Business a Success"
                Box4
                list={perksData}
            />
            <section className="commonSection pt-0 store_main">
                <div className="container">
                    <div className="heading_section text-center">
                        <h3 className="extrabold">Different <span className="borderBtm"
                            style={{ backgroundImage: `url(${Index.serviceShopify})` }}>Shopify Development</span> Services We Deliver </h3>
                    </div>
                    <div className="card_grid d-flex justify-content-between">
                        {weDeliver.map((data, indx) => {
                            let count = indx + 1
                            return (
                                <Fade up delay={indx * 200} key={indx}>
                                    <div className="card_grid-item" >
                                        <Link to={data.link}>
                                            <div className="card_box-innner white_bg outer_glow">
                                                <div className="card_type pb-3">
                                                    <div className="card_type-content">
                                                        <h6 className="number">{count > 9 ? count : "0" + count}</h6>
                                                        <h3 className="title blue_clr" style={{ color: data?.color }} dangerouslySetInnerHTML={{ __html: data?.title }}></h3>
                                                    </div>
                                                    <div className="card_type-image">
                                                        <img src={data?.image} alt={data?.title.split("<br>")} />
                                                    </div>
                                                </div>
                                                <p className="font18">{data?.content}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </Fade>
                            )
                        })}
                    </div>
                    <Fade up>
                        <div className=" text-center">
                            <button onClick={executeScroll} className="common_btn">Connect with Us</button>
                        </div>
                    </Fade>
                </div>
            </section >
            <section className="categories_section greenBg commonSection position-relative">
                <div className="container">
                    <div className="heading_section text-center white_clr">
                        <h3 className="extrabold pb-2">Wide Range of <span className="borderBtm"
                            style={{ backgroundImage: `url(${Index.serviceShopify})` }}>Industries We Serve In</span>
                        </h3>
                        <p>Tailor-made Shopify eCommerce Development Services to Take Your Business to Heights</p>
                    </div>
                    <div className="categories_block d-flex align-items-center justify-content-between flex-wrap">
                        {industryRange.map((data, indx) => {
                            return (
                                <div key={indx} className="category_block-item category_link d-flex align-items-center">
                                    <span className="category_icon">
                                        <img src={data.icon} alt={data.name} />
                                    </span>
                                    <h5 className="extrabold white_clr">{data.name}</h5>
                                </div>)
                        })}

                    </div>
                </div>
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="buble_box circle white_buble right_buble"></div>
            </section>
            <section className="devAgency_section commonSection pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6" data-wow-delay="0.2s" data-wow-duration="1000ms">
                            <div className="devAgency_img">
                                <img src={Index.agencyImg} className="full_width" alt="Shopify Development Agency" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6" data-wow-delay="0.2s" data-wow-duration="1000ms">
                            <div className="devAgency_cntnt pl-5">
                                <h3 className="extrabold pb-2">Why Should You Partner With Our <span className="borderBtm" style={{ backgroundImage: `url(assets/images/homeOffer.png)` }}>Shopify</span> Development Agency? </h3>
                                <p>Cutting-edge Shopify Store Development Services</p>
                                <div className="devAgency_listing">
                                    <ul className="list-tyle-none">
                                        <li>
                                            <span className="agencylisting_img">
                                                <img src={Index.userIcon} alt="Superlative Expertise " />
                                            </span>
                                            <span className="agencylisting_cntnt">
                                                <h5 className="extrabold">Superlative Expertise  </h5>
                                                <p className="font18">Get a diligently curated Shopify store for better global reach with the help of our dedicated Shopify experts. </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="agencylisting_img">
                                                <img src={Index.designIcon} alt="Next-gen Tech" />
                                            </span>
                                            <span className="agencylisting_cntnt">
                                                <h5 className="extrabold">Next-gen Tech </h5>
                                                <p className="font18">With the use of future-ready technology, we offer you rich-featured Shopify enterprise solutions under our guidance.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="agencylisting_img">
                                                <img src={Index.peopleIcon} alt="Best Professional Teams" />
                                            </span>
                                            <span className="agencylisting_cntnt">
                                                <h5 className="extrabold">Best Professional Teams</h5>
                                                <p className="font18">Our Shopify web builders are perfectly suited to cater to all sizes of businesses, irrespective of their business domains.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="agencylisting_img">
                                                <img src={Index.messageIcon} alt="Post-Delivery Support" />
                                            </span>
                                            <span className="agencylisting_cntnt">
                                                <h5 className="extrabold">Post-Delivery Support</h5>
                                                <p className="font18">We are always available to assist you, even after the store launch, to resolve any technical issues you encounter after the delivery.</p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ThreePortfolioBox heading={`<span className="borderBtm" style={{ background-image: url(${Index.homePotential}) }}>Portfolio</span> Showcase`} />
            <Faq faqData={faqData} />
            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br/> Shopify store" />
        </>
    )
}
