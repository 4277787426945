import React, { useEffect, useState } from 'react';
import Index from '../../Assets/images/Index';
import { Link, NavLink, useLocation } from 'react-router-dom';
export default function Header({ blueBoxRef, executeScroll }) {
    const [nav, setNav] = useState([
        { name: "Home", link: "/", },
        { name: "About", link: "/about", },
        {
            name: "Services", link: "/services",
            dropdown: [
                { name: "Shopify Store Development", link: "/services/shopify-store-development" },
                { name: "Shopify Store Customization ", link: "/services/shopify-theme-customization" },
                { name: "Shopify Store Migration", link: "/services/shopify-store-migration"},
                { name: "Shopify App Development ", link: "/services/shopify-app-development" },
                { name: "Shopify Store Optimization", link: "/services/shopify-store-optimization" },
                { name: "Shopify Digital Marketing", link: "/services/shopify-digital-marketing" },
                { name: "Shopify API Integration", link: "/services/shopify-api-integration" },
                { name: "Shopify Store Maintenance", link: "/services/shopify-store-maintenance" },
            ]
        },
        { name: "Portfolio", link: "/portfolio", },
        { name: "Blog", link: "/blog", click: true },
        { name: "Contact", link: "/contact", },
    ])
    const [toggleDropdown, settoggleDropdown] = useState(false)
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showMenu, setShowMenu] = useState(false)
    // const [scroll, setScroll] = useState(false)
    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         setScroll(window.scrollY > 1)
    //     })
    // }, [])

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }
    const HideMenu = (data) => {
        setShowMenu(false)
        if (data?.click) {
            window.open(data.link, "_self")
        }
    }

    const location = useLocation()

    useEffect(() => {
        let mainBody = document.querySelector(".App")
        mainBody.classList.remove("loading")
        setTimeout(() => {
            mainBody.classList.add("loading")
        }, 1000);
    }, [location.pathname])

    useEffect(() => {
        document.querySelector(':root').classList.toggle('scroll', showMenu);
    }, [showMenu])
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY && lastScrollY > 80) { // if scroll down hide the navbar
                setShow(false);
            } else { // if scroll up show the navbar
                setShow(true);
            }
            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);
    const handleDropDownShow = () => {
        settoggleDropdown(!toggleDropdown)
    }
    // ${scroll ? "header_main header__fixed" : "header_main"}
    return (
        <header className={`header_main header__fixed headershown-${show} `}>
            <div className="container">
                <div className="header_navbar d-flex justify-content-between align-items-center">
                    <div className="main_logo">
                        <Link to="/">
                            <img src={Index.logo} alt="" />
                        </Link>
                    </div>
                    <nav className={`navbar ${showMenu ? "toggle" : ""}`}>
                        <span className="navClose_wrapper" onClick={toggleMenu}>
                            <img src={Index.close} alt="" />
                        </span>
                        <ul className="nav_menu list-tyle-none">
                            {/* <li className="list_item active">
                                <a href="index.html">Home</a>
                            </li> */}
                            {/* toggleDropdown */}
                            {nav.map((data, index) => {
                                return (
                                    <li className={`list_item ${data.dropdown ? "dropdown_holder" : ""} ${data.dropdown && toggleDropdown ? "showDropdown" : ""} `} key={index}>
                                        {data.click ? <>
                                            <Link onClick={() => HideMenu(data)} to="#">{data.name}</Link>
                                        </> : <>
                                            <NavLink onClick={() => HideMenu(data)} to={data.link}>{data.name}</NavLink>
                                            {data.dropdown ? <>
                                                <span className={`menu_carrot ${data.dropdown  && toggleDropdown ? "active" : ""}`} onClick={handleDropDownShow}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="7.121"
                                                        viewBox="0 0 11.414 7.121">
                                                        <path id="arrow" d="M0,0,5,5l5-5" transform="translate(0.707 0.707)" fill="none"
                                                            strokeMiterlimit="10" strokeWidth="1.5"></path>
                                                    </svg>
                                                </span>
                                                <div className="dropdown_menu megamenu_row ">
                                                    <ul className="list-tyle-none">
                                                        {data.dropdown.map((cntnt, ind) => {
                                                            return (
                                                                <li key={ind}>
                                                                    <NavLink onClick={() => HideMenu(data)} to={cntnt.link}>{cntnt.name}</NavLink>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </div>
                                            </> : null}
                                        </>}
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <div className="header_btn">
                        <button onClick={executeScroll} className="common_btn">Lets Get Started</button>
                    </div>
                    <div className={`nav_overlay ${showMenu ? "show" : ""}`} onClick={HideMenu} ></div>
                    <div className="menu_icon" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    )
}