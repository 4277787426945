import React from 'react'
import BlueBox from '../../Common/BlueBox/BlueBox'
import { Helmet } from 'react-helmet'

export default function Terms({ blueBoxRef }) {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/terms" />
            </Helmet>
            <section className="greenBg commonSection portFolio__bnr position-relative">
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="extrabold white_clr text-center">Terms of Use </h1>
                        </div>
                    </div>

                    <div className="buble_box circle white_buble small_buble "></div>
                </div>
            </section>
            <section className="commonSection">
                <div className="container">
                    <div className="inner_container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-addon__content">
                                    <p>You must comply with and be bound by the following terms of use if you continue to navigate and use our website <a href="/">liquidwebdevelopers.com</a>.</p>
                                    <p>This document is a legally binding contract formed between you, whether personally or on behalf of an entity ("you," "your," "Users," "Site Visitors," "Client" or "Talent"), and Liquid Webdevelopers ("we," "us," or "our"),
                                        regarding your access to and use of the <a href="/">liquidwebdevelopers.com</a> and any other platform, media channel, mobile Website, or mobile application linked, or otherwise connected thereto
                                        (collectively, the "Site Services" or "Website").</p>
                                    <p>We provide you with a restricted license to use the site and its Services. This license is conditional on compliance with the site's Terms of Use and, to the extent applicable, the rest of the Terms of Service.</p>
                                    <p>We make every effort to keep our Site and Site Services safe, secure, and operational, but we are unable to guarantee continuity of service or access to our Services.</p>
                                    <p>We make every effort to keep our Site and Site Services safe, secure, and operational, but we are unable to guarantee continuity of service or access to our Services. In fact, we may discontinue providing the Site or Site
                                        Services entirely or discontinue providing specific features without notice.</p>
                                    <p>The terms of this Agreement apply to both the Client and the Talent, as well as anyone who wishes to use our Website. The respective rights and obligations shall be as defined in this Agreement, as specified.</p>

                                    <h4>Terms of Use</h4>
                                    <ul>
                                        <li>The information on the pages of this Site or Website is provided solely for your general information and use. It is subject to change at any time.</li>
                                        <li>Cookies are used on this website to track user preferences. If you allow cookies to be used, we may keep your personal information for future use by third parties.</li>
                                        <li>You use any materials or information on this Website completely at your own risk, for which we accept no responsibility. It is your obligation to ensure that any products, services, or information obtained from this
                                            Website fulfill your particular needs.</li>
                                        <li>This Website may from time to time include links to third-party or other websites. These links are provided for your convenience in order to provide additional information. The presence of any link or application does
                                            not imply that we endorse the website (s). We take no responsibility for the linked website's content (s). The third parties and/or their licensors own and operate.</li>
                                        <li>such third-party website(s) or applications. Use of the links and third-party websites is entirely at your own risk.</li>
                                        <li>Our inability to act in response to a violation by you or others does not preclude us from acting in response to successive or related infringements. We cannot guarantee that we will take action in response to all violations
                                            of these Terms of Service or the User Agreement.</li>
                                        <li>Your usage of our Website, as well as any dispute arising from such use, is governed by Indian law.</li>
                                        <li>If you become aware of any violation of these Site Terms of Use, you must notify Customer Service immediately. You agree to cooperate with any investigation we conduct and to take any corrective actions we deem necessary
                                            to correct a violation of these Site Terms of Us.</li>
                                    </ul>

                                    <h4>Changes to this Policy</h4>
                                    <p>This policy has been revised as previously stated, and we recommend that you revisit this notice from time to time to check for any updates. This policy's provisions are subject to change without notice, and it is your
                                        sole responsibility to check for updates. </p>
                                    <p>Your continued use of the website or platform following any changes or revisions to this policy shall determine your agreement with the terms of the revised policy, subject to your legal rights.</p>

                                    <h4>Contact Us</h4>
                                    <p>If there are any queries about this Privacy Statement, please contact us at:</p>
                                    <p>Email: <a href="mailto:legal@liquidwebdevelopers.com">legal@liquidwebdevelopers.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BlueBox blueBoxRef={blueBoxRef} title="Start your <br> Shopify store" />
        </>
    )
}
