import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
export default function SixBox({ listdata, icon }) {
    return (
        <div className="services_inner">
            <div className="row">
                {listdata.map((data, indx) => {
                    let count = indx + 1
                    return (
                        <Fade up key={indx} delay={indx * 100}>
                            <div className="col-6 col-lg-4" >
                                <Link className="services_wrapper text-center wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1000ms">
                                    <span to="#" className="services_wrapper-overlay"></span>
                                    <div className="services_icon">
                                        {icon ? <img src={data.img} alt={`${data.title.split("<br>")} icon`} /> :
                                            <span className="circle feature_circle" style={{ backgroundColor: data.color }}>{count > 9 ? count : "0" + count}</span>}
                                    </div>
                                    <div className="services_content">
                                       
                                        <h4 className="servicesContent__title" dangerouslySetInnerHTML={{ __html: data.title }}></h4>
                                        <p>{data.desc}</p>
                                    </div>
                                </Link>
                            </div>
                        </Fade>
                    )

                })}
            </div>
        </div>
    )
}
